<mat-card>
  <mat-card-title> {{ title }}</mat-card-title>

  <div>
    <iot-user-role-assignment
      [tenantId]="tenantId"
      [scope]="scope"
    ></iot-user-role-assignment>
  </div>
</mat-card>

<p></p>
<mat-divider></mat-divider>
<mat-card>
  <mat-card-header>
    <mat-card-title>{{
      "iot.dashboard.shared.IAM.UsersWithRoles" | translate
    }}</mat-card-title>
    <mat-card-subtitle>{{
      "iot.dashboard.shared.IAM.RoleAssignments" | translate
    }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div *ngIf="resourceRoleAssignments$ | async as items; else loading">
      <mat-card
        class="mb-2"
        *ngFor="let item of items"
        fxLayout="row"
        fxLayoutAlign="space-between"
      >
        <iot-user-rolles-manager [userRole]="item"> </iot-user-rolles-manager>
        <button mat-flat-button (click)="deleteRole(item)">
          <mat-icon color="warn">delete</mat-icon>
        </button>
      </mat-card>

      <div *ngIf="items >= 0">
        No roles to show
      </div>
    </div>
  </mat-card-content>

  <ng-template #loading>
    <mat-spinner [diameter]="30" class="m-3"> </mat-spinner>
  </ng-template>
</mat-card>
