import { AppInsightService } from "./../core/ErrorService/app-insight.service";

import browser from "browser-detect";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable, Subject, throwError } from "rxjs";
import {
  environment as env,
  environment
} from "../../environments/environment";
import {
  routeAnimations,
  LocalStorageService,
  selectIsAuthenticated,
  selectSettingsStickyHeader,
  selectSettingsLanguage,
  selectEffectiveTheme
} from "../core/core.module";

import {
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeLanguage
} from "../core/settings/settings.actions";
import { RouterOutlet } from "@angular/router";
import { catchError, filter, takeUntil } from "rxjs/operators";

import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration
} from "@azure/msal-angular";
import {
  InteractionType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  AuthenticationResult,
  SilentRequest
} from "@azure/msal-browser";
import { AccessTokenService } from "./dashboard/services/access-token.service";
import { UserPermissionsAndRolesService } from "../shared/user-permissions-and-roles.service";
@Component({
  selector: "iot-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthenticated$: Observable<boolean>;
  isAuthenticated = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  showOnlyInDevMode = true;
  isIframe = false;

  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = environment.smalogo;
  // languages = ['en', 'de', 'sk', 'fr', 'es', 'pt-br', 'zh-cn', 'he'];
  languages = ["en", "de"];

  navigation = [
    { link: "plantmanager", label: "iot.menu.plantManager" },
    { link: "dashboard", label: "iot.menu.dashboard" }
    // { link: 'plant', label: 'iot.menu.plant' },
    // { link: 'admin', label: 'iot.menu.admin' },
    // { link: 'examples', label: 'iot.menu.examples' }
  ];

  navigationSideMenu = [
    ...this.navigation,
    { link: "settings", label: "iot.menu.settings" }
  ];

  stickyHeader$: Observable<boolean>;
  language$: Observable<string>;
  theme$: Observable<string>;

  constructor(
    private store: Store,
    private storageService: LocalStorageService,
    private appInsightService: AppInsightService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private accessTokenService: AccessTokenService,
    private userPermissionsAndRolesService: UserPermissionsAndRolesService
  ) {}

  private static isIEorEdgeOrSafari() {
    return ["ie", "edge", "safari"].includes(browser().name);
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.toggleButtonshowOnlyInDevMode();

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.storageService.testLocalStorage();
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }

    this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));

    //show user Id in App-Insight-Portal
    if (this.isAuthenticated) {
      this.userPermissionsAndRolesService.setAccountInstance(
        this.msalService.instance.getAllAccounts()[0]
      );
      let subId = this.msalService.instance.getAllAccounts()[0].idTokenClaims[
        "sub"
      ];

      this.appInsightService.setUser(subId);
      if (subId !== null) {
        this.appInsightService.logEvent("Login", {
          userId: subId
        });
      }
    }
  }

  toggleButtonshowOnlyInDevMode() {
    if (environment.production) {
      this.showOnlyInDevMode = false;
      this.navigation = [
        // { link: 'plantmanager', label: 'iot.menu.plantManager' },
        { link: "dashboard", label: "iot.menu.dashboard" }
        // { link: 'plant', label: 'iot.menu.plant' },
        // { link: 'admin', label: 'iot.menu.admin' },
        // { link: 'examples', label: 'iot.menu.examples' }
      ];

      this.navigationSideMenu = [...this.navigation];
    }
  }

  getClaims(claims: any) {}

  saveAccessToken() {
    const silentRequest: SilentRequest = {
      scopes: environment.Azure_AD_B2C.scopes
    };
    this.msalService
      .acquireTokenSilent(silentRequest)
      .pipe(catchError(res => throwError("error")))
      .subscribe(res => {
        this.accessTokenService.setAccessToken(res.accessToken);
      });
  }

  setLoginDisplay() {
    this.isAuthenticated =
      this.msalService.instance.getAllAccounts().length > 0;
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout() {
    this.msalService.logout();
  }

  getAnimationData(outlet: RouterOutlet) {
    return (
      outlet.isActivated &&
      (outlet.activatedRoute?.routeConfig?.data ?? false) &&
      (outlet.activatedRoute?.routeConfig?.data?.title ?? false)
    );
  }

  editProfile() {
    let editProfileFlowRequest = {
      scopes: ["openid"],
      authority:
        environment.Azure_AD_B2C.b2cPolicies.authorities.editProfile.authority
    };

    this.login(editProfileFlowRequest);
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }));
  }
  ngOnDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
