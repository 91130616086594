import { PlantType } from './../../models/plant-type';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { PlantTypeService } from './../../services/plant-type.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'anms-plant-type-info',
  templateUrl: './plant-type-info.component.html',
  styleUrls: ['./plant-type-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlantTypeInfoComponent implements OnInit {
  @Input() plantTypeId = '';
  plantTypeInfos: PlantType = {};
  plantTypeInfo$: Observable<PlantType>;

  constructor(private plantTypeService: PlantTypeService) {}

  ngOnInit(): void {
    this.plantTypeInfo$ = this.plantTypeService.getPlantTypeById(
      this.plantTypeId
    );
    // .pipe(map((items) => ({ key: items.key, iconURI: items.iconURI })))
  }
}
