
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { retry } from 'rxjs/operators';
import { DeviceService } from '../../services/device.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'iot-device-attributes-add',
  templateUrl: './device-attributes-add.component.html',
  styleUrls: ['./device-attributes-add.component.scss'],

})
export class DeviceAttributesAddComponent implements OnInit {
  myForm = this.formBuilder.group({
    key: ['', Validators.required],
    value: ['', Validators.required],
  })
  constructor(
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialogRef: MatDialogRef<DeviceAttributesAddComponent>,
    private deviceServie: DeviceService,
  ) { }

  ngOnInit(): void {
  }
  onSubmit() {
    if (this.myForm.valid) {
      const deviceId = this.data.deviceId;
      this.deviceServie.addAttribute(deviceId, this.myForm.value.key, this.myForm.value.value)
        .pipe(
          retry(3)
        )
        .subscribe(result => {
          this.toastrService.success("attribute has been successfully sent!")
        }
        )
      this.myForm.reset()
    }
    else {
      this.toastrService.warning("Please fill out the fields!")
    }

  }

  close() {
    this.matDialogRef.close("true")
  }

}
