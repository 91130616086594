import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UserRoleAssignments } from '../model/user-role-assignments';
@Injectable({
  providedIn: 'root'
})
export class UserRoleAssignmentService {
  private roleIdDataSource = new BehaviorSubject<string>('');
  private scopeDataSource = new BehaviorSubject<string>('');

  roleId = this.roleIdDataSource.asObservable();
  scope = this.scopeDataSource.asObservable();

  constructor(private httpClient: HttpClient) {}

  updatedroleId(RoleId: string) {
    this.roleIdDataSource.next(RoleId);
  }
  updateScope(Scope: string) {
    this.scopeDataSource.next(Scope);
  }

  postUserRoleAssignment(roleAssignment): Observable<any> {
    return this.httpClient.post<UserRoleAssignments>(
      environment.endpoints.userRoleAssignment,
      roleAssignment
    );
  }

  deleteUserRole(userId, roleId): Observable<any> {
    return this.httpClient.delete<UserRoleAssignments>(
      environment.endpoints.userRoleAssignment + '/' + userId + '/' + roleId
    );
  }
}
