<div>
    <div class="title">
        <h1 mat-dialog-title> Units Manager </h1>
        <div class="butons">
            <button mat-button color="primary" (click)="addUnit()">
                <mat-icon>add</mat-icon>
            </button>
            <button mat-button color="warn" mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>


    <div *ngIf="units$ | async as units" mat-dialog-content>
        <mat-card class="m-2 mat-card" *ngFor="let item of units" class="border">
            <mat-card-header>
                <mat-card-title> Name: {{item.key}}</mat-card-title>
                <mat-card-subtitle> Identifier: {{item.identifier}}</mat-card-subtitle>
                <mat-card-subtitle> created at: {{item.created | date : 'short'}}</mat-card-subtitle>
            </mat-card-header>
            <div>
                <button color="accent" mat-button>
                    <mat-icon>mode</mat-icon>
                </button>
                <button color="warn" mat-button>
                    <mat-icon>delete</mat-icon>
                </button>
            </div>

        </mat-card>

    </div>
</div>