<div class="title">
    <h1 mat-dialog-title> {{message}} </h1>
    <div class="butons">
        <button mat-button color="warn" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<form [formGroup]="signalForm" (ngSubmit)="ngSubmit()" class="bigForm">
    <div class="mat-form-fields">
        <mat-form-field appearance="outline" *ngIf="device$ | async as device">
            <mat-label>Device: </mat-label>
            <mat-label>{{device.friendlyName}}</mat-label>
            <input matInput [readonly]="true" type="text">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input formControlName="friendlyName" matInput type="text">
            <mat-error>
                Name is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>index</mat-label>
            <input formControlName="index" matInput min="0" type="number">
            <mat-error>
                Index is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Key</mat-label>
            <input formControlName="key" matInput type="text">
            <mat-error>
                key is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="types$ |async as types" appearance="outline">
            <mat-label>type</mat-label>
            <mat-select formControlName="typeId" matNativeControl>
                <mat-option *ngFor="let type of types" value={{type.id}}>
                    {{type.key}}
                </mat-option>
            </mat-select>
            <mat-error>
                type is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="units$ |async as units" appearance="outline">
            <mat-label>Units</mat-label>
            <mat-select formControlName="unitId" matNativeControl>
                <mat-option *ngFor="let unit of units" value={{unit.id}}>
                    {{unit.key}}
                </mat-option>
            </mat-select>
            <mat-error>
                Unit is <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div formArrayName="attributes">
        <div *ngFor="let attribute of getterattributes.controls; let i=index" [formGroupName]="i">
            <mat-form-field appearance="outline" style="width: 42%;">
                <mat-label>Key of attribute</mat-label>
                <div *ngIf="readonly; else key">
                    <input matInput readonly formControlName="key">
                </div>
                <ng-template #key>
                    <input matInput formControlName="key">
                </ng-template>
                <mat-error>
                    Key is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="ml-1" appearance="outline" style="width: 42%;">
                <mat-label>value</mat-label>
                <input matInput matInput formControlName="value">
                <mat-error>
                    Value is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <button class="ml-1" mat-mini-fab color="warn" type="button" matTooltip="Delete attribute"
                (click)="deleteAttributes(i,attribute.value.key)">
                <mat-icon aria-hidden="false" aria-label="Example home icon">delete_forever</mat-icon>
            </button>
        </div>
    </div>

</form>
<div class="doneButton">
    <button mat-flat-button color="primary" (click)="openaddAttributes()">
        <mat-icon>add</mat-icon> add Attributes
    </button>
    <button mat-flat-button color="primary" (click)="ngSubmit()">
        <mat-icon>done</mat-icon> Send
    </button>
</div>