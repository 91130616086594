<div class="header">
    <h1 mat-dialog-title> {{message}} </h1>
    <button mat-button color="warn" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <form action="" [formGroup]="unitsForm" (ngSubmit)="onSubmit()">
        <div class="content">
            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input formControlName="key" matInput>
                <mat-error> This field is <strong>required</strong>.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Identifier</mat-label>
                <input formControlName="identifier" matInput>
                <mat-error> This field is <strong>required</strong>.</mat-error>
            </mat-form-field>
        </div>



        <button class="saveButton" type="submit" mat-button color="primary">
            <mat-icon>done</mat-icon>
        </button>
    </form>

</div>