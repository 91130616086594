<div *ngIf="tenantRoles$ | async as tenantRole; else loadingRoles">
  <form>
    <mat-form-field style="width: 50%">
      <mat-label>Roles</mat-label>
      <mat-select [(ngModel)]="roleId" name="tenats">
        <mat-option
          *ngFor="let role of tenantRole"
          (click)="selectedRoles(role)"
          [value]="role.id"
        >
          {{ role.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
    <div *ngIf="!scope">
      <mat-form-field style="width: 50%">
        <mat-label>Select scope</mat-label>
        <mat-select
          [(ngModel)]="selectedAllowedScope"
          name="tenats"
          (selectionChange)="allowedScopesChange($event)"
        >
          <mat-option
            *ngFor="let allowedScope of allowedScopes"
            [value]="allowedScope"
          >
            {{ allowedScope }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
<ng-template #loadingRoles>
  <mat-spinner class="m-3" [diameter]="30" *ngIf="showSpinner"> </mat-spinner>
</ng-template>
