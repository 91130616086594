
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
@Component({
  selector: 'iot-show-signals-in-device',
  templateUrl: './show-signals-in-device.component.html',
  styleUrls: ['./show-signals-in-device.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowSignalsInDeviceComponent implements OnInit {
  @Input() signal
  ArrayOfAttributes: { key: string; value: any; }[];
  signlalState: boolean = false;
  constructor() { }

  ngOnInit(): void {
    const attributes = this.signal.attributes
    //key und value in object hinzugefügt
    this.ArrayOfAttributes = Object.keys(attributes).map(key => ({ key: key, value: attributes[key] }))
  }

}
