<ng-template #loading>
  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</ng-template>

<div *ngIf="templates$ | async as templates; else loading" cdkDropList id="templateList" [cdkDropListData]="templates"
  [cdkDropListConnectedTo]="['deviceList']" class="example-list" (cdkDropListDropped)="drop($event)">

  <mat-list-item class="example-box" *ngFor="let template of templates" cdkDrag [cdkDragData]="template">
    <h5>{{template.name}}</h5>
    <a mat-flat-button  (click)="openInfoModal(template)" matTooltip="edit template" [matTooltipPosition]="'right'">
      <mat-icon color="accent" >edit</mat-icon>
    </a>

  </mat-list-item>
</div>
