import { switchMap, tap, retry } from 'rxjs/operators';
import { SignalService } from './../../services/signal.service';
import { Signal } from './../../../../../shared-signals/models/signal';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig
} from '@angular/material/dialog';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DeviceEditComponent } from '../device-edit/device-edit.component';
import { DeviceService } from '../../services/device.service';
import { DialogService } from '../../../../../core/dialogService/dialog.service';
import { DeviceSignalsAddEditComponent } from '../device-signals-add-edit/device-signals-add-edit.component';
import { DeviceSignalsEditComponent } from '../device-signals-edit/device-signals-edit.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'iot-device-manager',
  templateUrl: './device-manager.component.html',
  styleUrls: ['./device-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DeviceManagerComponent implements OnInit, OnDestroy {
  constructor(
    private toastrService: ToastrService,
    private dialogService: DialogService,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private signalService: SignalService,
    private deviceService: DeviceService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  ArrayOfAttributes;
  childstate = '';
  attributeState: boolean = false;
  deviceId = this.data.id;
  plantId = this.data.plantId;
  tenantId = this.data.tenantId;
  signals$: Observable<Signal[]>;
  device$: Observable<any>;
  signalAttributes;
  subscription: Subscription = new Subscription();

  ngOnInit(): void {
    const attributes = this.data.attributes;
    //key und value in object hinzugefügt
    this.ArrayOfAttributes = Object.keys(attributes).map(key => ({
      key: key,
      value: attributes[key]
    }));
    if (this.data.childDevices.length <= 1) {
      this.childstate = 'child.';
    } else {
      this.childstate = 'children.';
    }
    if (Object.keys(attributes).length > 0) {
      this.attributeState = true;
    }
    this.device$ = this.deviceService.getDevice(this.deviceId);
    this.signals$ = this.signalService.getSignals(
      this.tenantId,
      this.deviceId,
      this.plantId
    );
  }

  getDevices() {
    this.device$ = this.deviceService.getDevice(this.deviceId);
  }
  getSignals() {
    this.signals$ = this.signalService.getSignals(
      this.tenantId,
      this.deviceId,
      this.plantId
    );
  }

  openEditDevice(deviceId, typeId, parentDeviceId, friendlyName) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.height = 'auto';
    matDialogConfig.width = '70%';
    matDialogConfig.data = {
      deviceId: deviceId,
      typeId: typeId,
      parentDeviceId: parentDeviceId,
      friendlyName: friendlyName,
      plantId: this.plantId,
      tenantId: this.data.tenantId
    };
    this.subscription.add(
      this.matDialog
        .open(DeviceEditComponent, matDialogConfig)
        .afterClosed()
        .subscribe(result => {
          if (result) {
            this.getDevices();
            this.changeDetectorRef.detectChanges();
          }
        })
    );
  }

  addNewSignal() {
    const config = new MatDialogConfig();
    config.height = '90%';
    config.width = '70%';
    config.data = {
      deviceId: this.deviceId,
      mode: 'newSignal',
      message: 'Add new signal in device'
    };
    this.subscription.add(
      this.matDialog
        .open(DeviceSignalsAddEditComponent, config)
        .afterClosed()
        .subscribe(result => {
          this.getSignals();
          this.changeDetectorRef.detectChanges();
        })
    );
  }
  editSignal(signal) {
    const config = new MatDialogConfig();
    config.height = '90%';
    config.width = '70%';
    config.data = {
      signal: signal,
      mode: 'editSignal',
      message: 'Edit signal in device'
    };
    this.subscription.add(
      this.matDialog
        .open(DeviceSignalsEditComponent, config)
        .afterClosed()
        .subscribe(result => {
          this.getSignals();
          this.changeDetectorRef.detectChanges();
        })
    );
  }

  deleteSignal(signalId) {
    this.dialogService
      .openConfirmDialog()
      .afterClosed()
      .toPromise()
      .then(result => {
        if (result) {
          this.signals$ = this.signalService.deleteSignal(signalId).pipe(
            retry(3),
            switchMap(signal =>
              this.signalService.getSignals(
                this.tenantId,
                this.deviceId,
                this.plantId
              )
            ),
            tap(() =>
              this.toastrService.warning(
                'Signal has been successfully deleted!'
              )
            )
          );
        }
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
