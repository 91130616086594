<div class="bigContainer" *ngIf="template$ | async as templateinit">
    <div>
        <mat-list>
            <div>
                <div class="editContainer">
                    <h5> Name: {{templateinit.name}} </h5>
                    <button mat-button matTooltip="Edit" color="accent"
                        (click)="editTemplate(templateinit.name, templateinit.id, templateinit.typeId, templateinit.attributes)">
                        <mat-icon>mode</mat-icon>
                    </button>
                </div>
                <p>
                    <iot-show-template-type [templateTypeId]="templateinit.typeId"></iot-show-template-type>
                </p>
            </div>


            <div class="border">
                <h6>Atrributes:</h6>
                <mat-list-item class="p-1 border" *ngFor="let attr of templateinit.attributes | keyvalue">
                    {{attr.key}} <span>:</span>
                    {{attr.value}}
                </mat-list-item>
            </div>


            <div *ngIf="templateinit.parameters" class="border">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h6>Parameters</h6>
                        </mat-panel-title>
                        <mat-panel-description>
                            click hier to see Parameters
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-list-item class="mat-list-item-content p-1 border"
                        *ngFor="let param of templateinit.parameters">
                        <div class="listContainer">
                            <h6>
                                {{param.key}}:
                                {{param.default}} </h6>
                            <button mat-button color="accent"
                                (click)="editParameter(templateinit.id, param.id,param.key,param.default)">
                                <mat-icon>mode</mat-icon>
                            </button>
                            <button mat-button color="warn" (click)="deleteParameter(templateinit.id, param.id)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </mat-list-item>
                </mat-expansion-panel>
            </div>




            <div>
                <h6>Signals:</h6>
                <mat-list-item class="style" *ngFor="let signal of templateinit.signals">
                    <mat-expansion-panel class="panel border">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{signal.friendlyName}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class=" m-2 p-2 border">
                            <mat-list-item>
                                <iot-show-type-and-unit [typeId]="signal.typeId" [unitId]="signal.unitId">
                                </iot-show-type-and-unit>
                            </mat-list-item>
                        </div>
                        <div class=" m-2 p-2 border">
                            <mat-panel-description>
                                Attributes
                            </mat-panel-description>

                            <mat-list-item *ngFor="let attr of signal.attributes | keyvalue">
                                {{attr.key}}:
                                {{attr.value}}
                            </mat-list-item>
                        </div>
                        <div class="divButtons">
                            <button mat-flat-button color="warn" matTooltip="Delete Signal"
                                (click)="removeSignal(signal)">
                                Delete
                            </button>
                            <button color="accent" mat-flat-button matTooltip="Edit Signal"
                                (click)="editSignal(signal)">
                                Edit
                            </button>
                        </div>
                    </mat-expansion-panel>
                </mat-list-item>
            </div>
            <!-- <mat-list-item *ngFor="let child of template.childs">
                <anms-device-template-info *ngIf="child" [template]="child"></anms-device-template-info>
                 </mat-list-item> -->
        </mat-list>
    </div>

    <div class="buttonContainer p-2">
        <button mat-flat-button color="primary" matTooltip="Add signals" cdkFocusInitial (click)="addSignal()">
            Add Signal
        </button>

        <button mat-flat-button color="primary" matTooltip="Add Paramaters" cdkFocusInitial (click)="addParameters()">
            Add parameters
        </button>

        <!-- <button mat-fab color="accent" matTooltip="Save template" (click)="onSubmit()">
            <mat-icon>save</mat-icon>
        </button> -->
    </div>
</div>