<div>
   <div class="title">
      <h1 mat-dialog-title> Add template </h1>
      <div class="butons">
         <button mat-button color="warn" mat-dialog-close>
            <mat-icon>close</mat-icon>
         </button>
      </div>
   </div>

   <div>
      <form class="bigContainer" [formGroup]="newTemplateForm" (ngSubmit)="onSubmit()">
         <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Template Name</mat-label>
            <input matInput placeholder="Name" formControlName="name">
            <mat-error>
               Name is <strong>required</strong>
            </mat-error>
         </mat-form-field>



         <mat-form-field appearance="outline">
            <mat-label>DeviceTypes</mat-label>
            <mat-select formControlName="typeId">
               <ng-container *ngIf="deviceTypes$ | async as deviceTypes">
                  <mat-option *ngFor="let deviceType of deviceTypes" [value]="deviceType.id">
                     <img [src]="deviceType.iconURL" alt="Icon" class="image">
                     {{deviceType.key}}
                  </mat-option>
               </ng-container>
            </mat-select>
            <mat-error>
               Type is <strong>required</strong>
            </mat-error>
         </mat-form-field>



         <div formArrayName="attributes">
            <div class="attrPosition" *ngFor="let attribute of attributes.controls; let i=index" [formGroupName]="i">
               <mat-form-field appearance="outline" style="width: 45%;" class=" pr-1 ">
                  <mat-label>Key of attribute</mat-label>
                  <input matInput formControlName="key">
               </mat-form-field>

               <mat-form-field appearance="outline" style="width:45%;">
                  <mat-label>value</mat-label>
                  <input matInput matInput formControlName="value">
               </mat-form-field>


               <a button mat-mini-fab color="warn" matTooltip="Delete attribute" (click)="deleteAttributes(i)">
                  <mat-icon aria-hidden="false" aria-label="Example home icon">delete_forever</mat-icon>
               </a>

            </div>
         </div>


         <div class="divButtons">
            <mat-form-field appearance="fill">
               <mat-label>Add Attributtes</mat-label>
               <mat-select (selectionChange)="addAttributes($event)">
                   <mat-option *ngFor="let module of modules" [value]="module" >
                   {{module}}
                   </mat-option>
               </mat-select>
           </mat-form-field>
            <button color="primary" mat-flat-button type="submit">Submit</button>
         </div>
      </form>

   </div>

















   <!-- <div class="bigContainer">
   <div class="firstDiv">
      <mat-form-field>
         <mat-label>Template name</mat-label>
         <input matInput [(ngModel)]="newtemplate.name">
      </mat-form-field>
      <br>
      <mat-form-field>
         <mat-label>Type Select</mat-label>
         <mat-select [ngModel]="newtemplate.type?.key" (selectionChange)="typeSelected($event)">
            <mat-option *ngFor="let type of deviceTypes$ | async" [value]="type">
               {{ type.key }}
            </mat-option>
         </mat-select>
      </mat-form-field>
   </div>


   <div class="secoundDiv">
      <div class="border m-3 p-2" *ngFor="let param of newtemplate.parameters">
         <mat-form-field>
            <mat-label>Parameter key</mat-label>
            <input matInput [(ngModel)]=param.key>
         </mat-form-field>
         :
         <mat-form-field class="example-full-width">
            <mat-label>Parameter value</mat-label>
            <input matInput [(ngModel)]=param.default>
         </mat-form-field>
      </div>

      <div class="border m-3 p-2" *ngFor="let attr of newtemplate.attributes | keyvalue">
         <mat-form-field>
            <mat-label>Attribute key</mat-label>
            <input matInput (change)="changeKey(attr.key, $event.target.value, newtemplate.attributes)"
               [ngModel]="attr.key">
         </mat-form-field>
         :
         <mat-form-field>
            <mat-label>Attribute value</mat-label>
            <input matInput [ngModel]="newtemplate.attributes.get(attr.key)"
               (ngModelChange)="newtemplate.attributes.set(attr.key, $event)">
         </mat-form-field>
      </div>

      <div>
         <div *ngFor="let signal of newtemplate.signals">
            <mat-expansion-panel >
               <mat-expansion-panel-header>
                  <mat-panel-title>
                     {{signal.friendlyName}}
                  </mat-panel-title>
               </mat-expansion-panel-header>
               <mat-list>
                  <mat-list-item *ngFor="let attr of signal.attributes | keyvalue"> {{attr.key}}: {{attr.value}}
                  </mat-list-item>
               </mat-list>
               <button mat-flat-button color="warn" matTooltip="Delete Signal" (click)="removeSignal(signal)">
                  Delete
               </button>
            </mat-expansion-panel>
         </div>
      </div>


      <div>
         <div *ngFor="let child of newtemplate.childs">
            <mat-expansion-panel class="padding">

               <mat-expansion-panel-header>
                  <mat-panel-title>
                     Child
                  </mat-panel-title>
               </mat-expansion-panel-header>

               <mat-form-field class="example-full-width">
                  <mat-label>Count</mat-label>
                  <input matInput [(ngModel)]="child.count">
               </mat-form-field>
               <div *ngFor="let attr of child.attributes | keyvalue">
                  <mat-form-field>
                     <mat-label>Key of attribute</mat-label>
                     <textarea matInput (change)="changeKey(attr.key, $event.target.value, child.attributes)"
                        [ngModel]="attr.key"></textarea>
                  </mat-form-field>
                  <mat-form-field style="width: 45%;">
                     <mat-label>Name of the tenant</mat-label>
                     <textarea matInput [ngModel]="child.attributes.get(attr.key)"
                        (ngModelChange)="child.attributes.set(attr.key, $event)"></textarea>
                  </mat-form-field>
               </div>
               <mat-panel-title>
                  {{child.name}}
                  <button mat-flat-button color="warn" matTooltip="Delete Signal" (click)="deleteChild(child)">
                     Delete
                  </button>
               </mat-panel-title>

               <button mat-fab color="primary" matTooltip="Add attribute" (click)="addAttribute(child.attributes)">
                  <mat-icon>add</mat-icon>
               </button>
            </mat-expansion-panel>
         </div>
      </div>
   </div>


   <div class="thirdDiv">
      <button mat-flat-button color="primary" matTooltip="Add signal" (click)="addSignal()">
         Add signal
         <mat-icon>add</mat-icon>
      </button>
      <button mat-fab color="primary" matTooltip="Add child" (click)="addChild()">
         <mat-icon>add</mat-icon>
      </button>
      <button mat-flat-button color="primary" matTooltip="Add new parameters" (click)="addParameters()">
         Add parameters
         <mat-icon>add</mat-icon>
      </button>
      <button mat-flat-button color="primary" matTooltip="Add attribute" (click)="addAttribute(newtemplate.attributes)">
         Add attribute
         <mat-icon>add</mat-icon>
      </button>
      <button mat-fab color="accent" matTooltip="Save template" (click)="onSubmit()">
         <mat-icon>save</mat-icon>
      </button>
   </div>

</div> -->