import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { DeviceTemplateFE } from '../../models/device-template-fe';
import { DeviceTemplateService } from '../../services/device-template.service';
import { DeviceService } from '../../services/device.service';
import { SignalService } from '../../services/signal.service';
import { AddParameterInTemplateComponent } from '../add-parameter-in-template/add-parameter-in-template.component';
import { DeviceTemplateEditComponent } from '../device-template-edit/device-template-edit.component';
import { SignalAddComponent } from '../signal-add/signal-add.component';
import { SignalEditComponent } from '../signal-edit/signal-edit.component';

@Component({
  selector: 'anms-device-template-info',
  templateUrl: './device-template-info.component.html',
  styleUrls: ['./device-template-info.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceTemplateInfoComponent implements OnInit {
  @Input('template')
  public tpl: DeviceTemplateFE;
  public template$: Observable<any>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private signalService: SignalService,
    private deviceService: DeviceService,
    private devtempService: DeviceTemplateService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.template$ = this.devtempService.getDeviceTemplate(this.tpl.id);
  }

  getDeviceTemplate() {
    this.template$ = this.devtempService.getDeviceTemplate(this.tpl.id);
  }

  // getDeviceFunction() {
  //   this.devtempService
  //     .getDeviceTemplate(this.template.id)
  //     .subscribe(val => { (this.template = val) });

  //   console.log('templete', this.template);

  // }

  // clickEvent(){
  //   this.signalservice.createTemplateSignal(this.template.id, {
  //     typeId: "7be8ace0-ed47-4696-a417-4f2d038a14cd",
  //     unitId: "0cc5648f-5f5a-4cda-8805-1d7f5bc0ce87",
  //     friendlyName: "Temperatur"
  //   }).subscribe();
  // }
  addSignal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80%';
    dialogConfig.width = '80%';
    //dialogConfig.data = this.template.id
    // dialogConfig.data = signal;
    dialogConfig.data = this.tpl.id;
    const dialogRef = this.dialog.open(SignalAddComponent, dialogConfig);
    const sub: Subscription = dialogRef.afterClosed()
      .pipe(
        tap(() => sub.unsubscribe))
      .subscribe((result) => {
        // console.log('result', result);
        //this.template.signals.push(result.signal);
        // this.template$ = this.devtempService.getDeviceTemplate(this.tpl.id);
        if (result) {
          this.template$ = this.signalService.createTemplateSignal(result.data, result.myFormValue).pipe(
            retry(3),
            tap(() =>
              this.toastrService.success(
                'Object has been successfully sent'
              )
            )
          )
          this.template$.subscribe(() => {
            this.getDeviceTemplate()
            this.changeDetectorRef.detectChanges()
          })
        }

      });
  }

  editSignal(signal) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80%';
    dialogConfig.width = '80%';
    //dialogConfig.data = this.template.id
    // dialogConfig.data = signal;
    dialogConfig.data = { templateId: this.tpl.id, signal: signal };
    const dialogRef = this.dialog.open(SignalEditComponent, dialogConfig);
    const sub: Subscription = dialogRef.afterClosed().pipe(tap(() => sub.unsubscribe)).subscribe(result => {
      if (result) {
        this.signalService
          .editTemplateSignal(
            result.templateId,
            result.dataSignalId,
            result.myFormvalue
          )
          .pipe(
            retry(3),
            tap(() =>
              this.toastrService.success(
                'Change has been successfully saved'
              )
            )
          ).subscribe(res => {
            this.getDeviceTemplate()
            this.changeDetectorRef.detectChanges();
          }
          )
      }

    });
  }


  removeSignal(signal: any) {
    this.template$ = this.signalService
      .deleteTemplateSignal(this.tpl.id, signal.id)
      .pipe(
        retry(3),
        tap(() =>
          this.toastrService.warning('object has been successfully deleted')
        )
      );

    // const subscription: Subscription = this.signalService
    //   .deleteTemplateSignal(this.template.id, signal.id)
    //   .pipe(
    //     retry(3),
    //     tap(() => {
    //       this.toastrService.warn('object has been successfully deleted');
    //     }),
    //     tap(res => console.log("res", res)),
    //     tap(() => subscription.unsubscribe())
    //   )
    //   .subscribe(val => {
    //     this.getDeviceFunction()
    //    });
  }

  addParameters() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'auto';
    dialogConfig.width = '60%';
    //dialogConfig.data = this.template.id
    // dialogConfig.data = signal;
    dialogConfig.data = { templateId: this.tpl.id };
    const dialogRef = this.dialog.open(AddParameterInTemplateComponent, dialogConfig);
    const sub: Subscription = dialogRef.afterClosed()
      .pipe(
        tap(() => sub.unsubscribe))
      .subscribe((result) => {
        console.log("result", result);
        if (result) {
          this.template$ = result
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  editParameter(TemplateId, ParameterId, Key, Default) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'auto';
    dialogConfig.width = '60%';
    //dialogConfig.data = this.template.id
    // dialogConfig.data = signal;
    dialogConfig.data = { templateId: TemplateId, parameterId: ParameterId, key: Key, default: Default, status: "edit", message: "Edit Paramter" }
    const dialogRef = this.dialog.open(AddParameterInTemplateComponent, dialogConfig);
    const sub: Subscription = dialogRef.afterClosed()

      .pipe(
        tap(() => sub.unsubscribe))
      .subscribe((result) => {
        console.log("result", result);
        if (result) {
          this.template$ = result
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  deleteParameter(TemplateId, ParameterId) {
    this.template$ = this.devtempService.deleteTemplateParameter(TemplateId, ParameterId)
    // this.template$.subscribe(val => console.log("val", val))
  }

  editTemplate(TemplateName, TemplateId, TemplateType, TemplateAttributes) {
    // console.log("TemplateName", TemplateName, "Templateid", TemplateId, "TemplateType", TemplateType);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'auto';
    dialogConfig.width = '60%';
    //dialogConfig.data = this.template.id
    // dialogConfig.data = signal;
    dialogConfig.data = { templateName: TemplateName, templateId: TemplateId, templateType: TemplateType, templateAttributes:TemplateAttributes };
    const dialogRef = this.dialog.open(DeviceTemplateEditComponent, dialogConfig);
    const sub: Subscription = dialogRef.afterClosed()
      .pipe(
        tap(() => sub.unsubscribe))
      .subscribe((result) => {
        if (result) {
          this.template$ = this.devtempService.updateTemplate(this.tpl.id, result).pipe(
            retry(3),
            tap(() =>
              this.toastrService.success(
                'Change has been successfully saved'
              )
            )
          )
        }
        this.changeDetectorRef.detectChanges();
      });

  }



  // onSubmit() {
  //   this.devtempService
  //     .updateTemplate(this.tpl)
  //     .subscribe(val => {
  //       this.tpl.childs.filter(c => !val.childs.map(ch => ch.id).includes(c.id))
  //         .forEach(element => {
  //           this.devtempService.addChildTemplate(
  //             {
  //               childTemplateId: val.id,
  //               attributes: element.attributes,
  //               count: element.count
  //             },
  //             val.id
  //           ).subscribe();
  //         });
  //       this.tpl.signals.filter(s => !val.signals.map(ss => s.id).includes(s.id))
  //         .forEach(element => {
  //           this.signalService.createTemplateSignal(val.id, {
  //             typeId: element.typeId,
  //             friendlyName: element.friendlyName,
  //             unitId: element.unitId,
  //             attributes: element.attributes
  //           }).subscribe();
  //         });
  //     });
  // }
}


