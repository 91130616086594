import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { Observable } from 'rxjs';
import { Tenant } from '../../models/tenant';
import { TenantService } from '../../services/tenant.service';

@Component({
  selector: 'anms-tenant-info',
  templateUrl: './tenant-info.component.html',
  styleUrls: ['./tenant-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantInfoComponent implements OnInit {
  @Input() public tenantId: string;

  public tenant$: Observable<Tenant>;

  constructor(private tenantService: TenantService) {}

  ngOnInit(): void {
    this.tenant$ = this.tenantService.getTenant(this.tenantId);
  }
}
