import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { UserRoleAssignmentService } from '../../services/user-role-assignment.service';
import { UserRoleAssignments } from './../../model/user-role-assignments';

@Component({
  selector: 'iot-user-role-assignment',
  templateUrl: './user-role-assignment.component.html',
  styleUrls: ['./user-role-assignment.component.scss']
})
export class UserRoleAssignmentComponent implements OnInit, OnDestroy {
  @Input() scope = '';
  @Input() tenantId = '';
  destroy$: Subject<boolean> = new Subject();
  userId = '';
  roleId = '';
  showSpinnerAfterSend = false;
  constructor(
    private userRoleAssignmentService: UserRoleAssignmentService,
    private toastrService: ToastrService,
    public translateService: TranslateService,
    private matDialogRef: MatDialogRef<UserRoleAssignmentComponent>
  ) {}

  ngOnInit(): void {
    this.userRoleAssignmentService.roleId
      .pipe(takeUntil(this.destroy$))
      .subscribe(roleId => {
        this.roleId = roleId;
      });

    // this.userRoleAssignmentService.scope
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(scope => {
    //     this.scope = scope;
    //   });
  }

  userSearchResult(event) {
    this.userId = event.id;
  }

  sendRoleAssignment() {
    if (
      this.userId.length > 0 ||
      this.roleId.length > 0 ||
      this.scope.length > 0
    ) {
      this.showSpinnerAfterSend = true;
      const roleAssignment: UserRoleAssignments = {
        roleId: this.roleId,
        scope: this.scope,
        userId: this.userId
      };
      this.userRoleAssignmentService
        .postUserRoleAssignment(roleAssignment)
        .pipe(
          takeUntil(this.destroy$),
          tap(result => {
            this.toastrService.success(
              this.translateService.instant('iot.dashboard.shared.change')
            );
          })
        )
        .subscribe(result => {
          this.showSpinnerAfterSend = false;
          this.roleId = '';
          this.matDialogRef.close();
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.roleId = '';
    this.userRoleAssignmentService.updatedroleId(this.roleId);
  }
}
