import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AccessTokenService {
  public data: BehaviorSubject<string> = new BehaviorSubject<string>("");
  accessToken = this.data.asObservable();
  accessTokenC;

  setAccessToken(accessToken: string) {
    this.data.next(accessToken);
  }
  getAccessToken() {
    this.accessToken.subscribe(accessToken => {
      this.accessTokenC = accessToken;
    });

    return this.accessTokenC;
  }
  constructor() {}
}
