<ng-container *ngIf="tenants$ | async as tenants">
    <div class="titleContainer">
        <h1 mat-dialog-title>Tenant Manager</h1>
        <div>
            <button mat-button color="accent" (click)="getTenantsfFunc()">
                <mat-icon>refresh</mat-icon>Refresh
            </button>
            <button color="primary" mat-button (click)="creatTenant()">
                <mat-icon>add</mat-icon> Create New Tenant
            </button>
            <button color="warn" mat-button cdkFocusInitial mat-dialog-close>
                <mat-icon>close</mat-icon>Close
            </button>
        </div>
    </div>

    <div mat-dialog-content>
        <table class="styled-table">
            <thead>
                <th>Name</th>
                <th>Created at</th>
                <th>Action</th>
                <tr *ngFor="let tenant of tenants">
                    <td>{{ tenant.name }}</td>
                    <td>{{ tenant.created | date : 'short' }}</td>
                    <td>
                        <button mat-button matTooltip="Edit" color="accent" class="m-1"
                            (click)="editTenant(tenant.id, tenant.name, tenant.ownerId)">
                            <mat-icon>mode</mat-icon>
                        </button>

                        <button mat-button color="warn" matTooltip="Delete" (click)="deleteTenant(tenant.id)">
                            <mat-icon>delete_forever</mat-icon>
                        </button>
                    </td>
                </tr>
            </thead>
        </table>
    </div>
</ng-container>