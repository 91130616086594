<div>
    <div class="title">
        <div mat-dialog-title>Add Attributes</div>
        <button mat-button (click)="close()" color="warn">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="content">
        <form [formGroup]="myForm" class="form" (ngSubmit)="onSubmit()">
            <mat-form-field class="m-2" appearance="outline">
                <mat-label>Key</mat-label>
                <input formControlName="key" matInput type="text">
                <mat-error>
                    Key is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="m-2" appearance="outline">
                <mat-label>value</mat-label>
                <input formControlName="value" matInput type="text">
                <mat-error>
                    value is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </form>

        <div class="doneButton">
            <button mat-button color="primary" (click)="onSubmit()">
                <mat-icon>done</mat-icon> Send
            </button>
        </div>
    </div>

</div>