import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignalService } from '../../services/signal.service';
import { retry, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'iot-signal-edit',
  templateUrl: './signal-edit.component.html',
  styleUrls: ['./signal-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignalEditComponent implements OnInit {
  public newSignal: any = {};
  public signalTypes$;
  public units$;
  myForm: FormGroup;
  arrayAttributes = [];
  fromAddAttributeFunction;
  inputAttributes;
  public edit: boolean = true;

  modules = ['Modbus', 'ModbusServer', 'Mqtt', 'Snmp', 'Other'];
  modbusattributes = [
    'Modbus.Function',
    'Modbus.Offset',
    'Modbus.OutputType',
    'Modbus.RegisterAddress',
    'Modbus.RegisterType',
    'Modbus.Scale'
  ];
  modbusserverattributes = [
    'ModbusServer.TargetRegister',
    'ModbusServer.Offset',
    'ModbusServer.OutputType',
    'ModbusServer.RegisterType',
    'ModbusServer.Gain'
  ];
  mqttattributes = ['Mqtt.TagName'];
  snmpattributes = [''];

  constructor(
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private signalService: SignalService,
    public dialogRef: MatDialogRef<SignalEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.signalTypes$ = signalService.getSignalTypes();
    const subSignal: Subscription = this.signalTypes$
      .pipe(tap(() => subSignal.unsubscribe()))
      .subscribe();
    this.units$ = signalService.getUnits();
    const subUnit: Subscription = this.units$
      .pipe(tap(() => subUnit.unsubscribe()))
      .subscribe();
  }

  ngOnInit(): void {
    this.myForm = this.formBuilder.group({
      friendlyName: ['', [Validators.required, Validators.minLength(3)]],
      typeId: ['', [Validators.required]],
      unitId: ['', [Validators.required]],
      attributes: this.formBuilder.array([])
    });

    this.inputAttributes = this.data.signal.attributes;

    let inputAttributesAfterchange = Array.from(
      this.inputAttributes,
      ([key, value]) => ({ key, value })
    );

    this.arrayAttributes = inputAttributesAfterchange;

    this.myForm.patchValue({
      friendlyName: this.data.signal.friendlyName,
      typeId: this.data.signal.typeId,
      unitId: this.data.signal.unitId
    });
  }

  get attributesForms() {
    return this.myForm.get('attributes') as FormArray;
  }

  addAttribute() {
    this.arrayAttributes.push({ key: '', value: '' });
  }

  addAttributes($event: any) {
    let array = [];
    switch ($event.value) {
      case 'Modbus':
        array = this.modbusattributes;
        this.edit = false;
        break;
      case 'ModbusServer':
        array = this.modbusserverattributes;
        this.edit = false;
        break;
      case 'Mqtt':
        array = this.mqttattributes;
        this.edit = false;
        break;
      case 'Snmp':
        array = this.snmpattributes;
        this.edit = false;
        break;
      default:
        this.addAttribute();
        this.edit = true;
        break;
    }

    array.forEach(item => {
      this.arrayAttributes.push({ key: item, value: '' });
    });
  }

  deleteAttributesfromarray(i) {
    this.attributesForms.removeAt(i);
  }

  deleteAttributes(i) {
    const subscribe: Subscription = this.signalService
      .removeTemplateSignalattributes(
        this.data.templateId,
        this.data.signal.id,
        this.arrayAttributes[i].key
      )
      .pipe(
        retry(3),
        tap(() =>
          this.toastrService.warning('Object has been successfully deleted!')
        ),
        tap(() => subscribe.unsubscribe())
      )
      .subscribe();
    this.arrayAttributes.splice(i, 1);
  }

  typeSelected(type: any) {
    this.newSignal.typeId = type.value.id;
    this.newSignal.friendlyName = type.value.key;
  }

  newattr(map: any) {
    console.log('map:', map);
    map.set('', '');
  }

  onSubmit() {
    if (this.myForm.valid) {
      if (
        this.data.signal !== undefined &&
        this.data.templateId !== undefined
      ) {
        this.dialogRef.close({
          templateId: this.data.templateId,
          dataSignalId: this.data.signal.id,
          myFormvalue: this.myForm.value
        });
      } else if (this.data.tenantId === undefined) {
        (this.newSignal.friendlyName = this.myForm.value.friendlyName),
          (this.newSignal.typeId = this.myForm.value.typeId),
          (this.newSignal.unitId = this.myForm.value.unitId);
        this.dialogRef.close({ signal: this.newSignal });
      }
    } else {
      this.toastrService.warning('Please fill the fields');
    }
  }

  changeKey(prevKey, newKey) {
    const value = this.newSignal.attributes[prevKey];
    this.newSignal.attributes.delete(prevKey);
    this.newSignal.attributes.set(newKey, value);
  }

  closeSignal() {
    this.dialogRef.close();
  }
}
