import { Component, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faInstagram,
  faYoutube,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';

import { environment } from './../../../environments/environment';
@Component({
  selector: 'iot-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(faInstagram, faYoutube, faLinkedin);
  }
  version: string;
  ngOnInit(): void {
    this.version = environment.versions.app;
  }
  goTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
}
