import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceTemplateInfoModalComponent } from '../device-template-info-modal/device-template-info-modal.component';
import { DeviceTemplateService } from '../../services/device-template.service';
import { Observable } from 'rxjs';
import { DeviceTemplateFE } from '../../models/device-template-fe';
import { DeviceTemplateCreationModalComponent } from '../device-template-creation-modal/device-template-creation-modal.component';

@Component({
  selector: 'anms-device-template-list-view',
  templateUrl: './device-template-list-view.component.html',
  styleUrls: ['./device-template-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceTemplateListViewComponent implements OnInit {
  @Input('temps')
  public templates$: Observable<DeviceTemplateFE[]>;
  constructor(
    public dialog: MatDialog,
    private devicetempService: DeviceTemplateService
  ) {}

  ngOnInit(): void {}

  drop(event: CdkDragDrop<string[]>) {
    this.devicetempService.drop(event);
  }

  openInfoModal(input: DeviceTemplateFE) {
    const dialogRef = this.dialog.open(DeviceTemplateInfoModalComponent, {
      width: '70%',
      data: [input],
      height: '90%'
    });
  }
}
