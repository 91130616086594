import { Unit } from './../../models/unit';
import { SignalType } from './../../models/signal-type';
import { Observable } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SignalService } from '../../services/signal.service';

@Component({
  selector: 'iot-show-type-and-unit',
  templateUrl: './show-type-and-unit.component.html',
  styleUrls: ['./show-type-and-unit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ShowTypeAndUnitComponent implements OnInit {
  @Input() typeId: string
  @Input() unitId: string

  signalType$: Observable<SignalType>
  units$: Observable<Unit>
  constructor(private signalService: SignalService) { }

  ngOnInit(): void {
    this.signalType$ = this.signalService.getSignalType(this.typeId);
    this.units$ = this.signalService.getUnit(this.unitId);
  }

}
