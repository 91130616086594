<div class="m-1 p-2 border">
    <h2 mat-dialog-title>Edit Template</h2>
    <mat-dialog-content>
        <form class="bigContainer " [formGroup]="templateForm" (ngSubmit)="OnSubmit()">
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Template Name</mat-label>
                <input matInput placeholder="Name" formControlName="name">
                <mat-error>
                    Name is <strong>required</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>DeviceTypes</mat-label>
                <mat-select formControlName="typeId">
                    <ng-container *ngIf="deviceTypes$ | async as deviceTypes">
                        <mat-option *ngFor="let deviceType of deviceTypes" [value]="deviceType.id">
                            <img [src]="deviceType.iconURL" alt="Icon" class="image">
                            {{deviceType.key}}
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-error>
                    Type is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <div formArrayName="attributes">
                <div *ngFor="let attribute of attributes.controls; let i=index" [formGroupName]="i">
                    <mat-form-field appearance="outline" style="width: 45%;" class=" pr-1 ">
                        <mat-label>Key of attribute</mat-label>
                        <input matInput formControlName="key">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="width:45%;">
                        <mat-label>value</mat-label>
                        <input matInput matInput formControlName="value">
                    </mat-form-field>


                    <a button mat-mini-fab color="warn" matTooltip="Delete attribute" (click)="deleteAttributes(i)">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">delete_forever</mat-icon>
                    </a>

                </div>
            </div>

            <div class="buttonContainer">
                <mat-form-field appearance="fill">
                    <mat-label>Add Attributtes</mat-label>
                    <mat-select (selectionChange)="addAttributes($event)">
                        <mat-option *ngFor="let module of modules" [value]="module" >
                        {{module}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-flat-button color="primary" type="submit">Save</button>
                <button mat-flat-button color="warn" type="button" (click)="closeDialog()">Close</button>
            </div>

        </form>
    </mat-dialog-content>
</div>