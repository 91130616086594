import { SignalType } from "./../../models/signal-type";
import { Observable, Subject } from "rxjs";
import { FormBuilder, Validators, FormArray } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from "@angular/core";
import { DeviceService } from "../../services/device.service";
import { DeviceFE } from "../../models/device-fe";
import { SignalService } from "../../services/signal.service";
import { retry, tap, switchMap, takeUntil } from "rxjs/operators";
import { Unit } from "../../models/unit";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "iot-device-signals-add-edit",
  templateUrl: "./device-signals-add-edit.component.html",
  styleUrls: ["./device-signals-add-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceSignalsAddEditComponent implements OnInit {
  message = this.data.message;
  device$: Observable<DeviceFE>;
  types$: Observable<SignalType[]>;
  units$: Observable<Unit[]>;
  readonly: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  modules = ["Modbus", "LocalStorage", "ModbusServer", "Mqtt", "Snmp", "Other"];
  modbusattributes = [
    "Modbus.Function",
    "Modbus.Offset",
    "Modbus.OutputType",
    "Modbus.RegisterAddress",
    "Modbus.RegisterType",
    "Modbus.Scale"
  ];
  modbusserverattributes = [
    "ModbusServer.TargetRegister",
    "ModbusServer.Offset",
    "ModbusServer.OutputType",
    "ModbusServer.RegisterType",
    "ModbusServer.Gain"
  ];
  mqttattributes = ["Mqtt.TagName"];

  constructor(
    private toastrService: ToastrService,
    private signalService: SignalService,
    private deviceService: DeviceService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialogRef: MatDialogRef<DeviceSignalsAddEditComponent>
  ) {}

  signalForm = this.fb.group({
    deviceId: [this.data?.deviceId],
    friendlyName: ["", Validators.required],
    index: ["", Validators.required],
    typeId: ["", Validators.required],
    key: ["", Validators.required],
    unitId: ["", Validators.required],
    attributes: this.fb.array([])
  });

  get getterattributes() {
    return this.signalForm.get("attributes") as FormArray;
  }

  addAttribute() {
    const attribute = this.fb.group({
      key: ["", Validators.required],
      value: ["", Validators.required]
    });
    this.getterattributes.push(attribute);
  }

  addAttributes($event: any) {
    let array = [];
    switch ($event.value) {
      case "Modbus":
        array = this.modbusattributes;
        break;
      case "ModbusServer":
        array = this.modbusserverattributes;
        break;
      case "Mqtt":
        array = this.mqttattributes;
        break;
      default:
        this.addAttribute();
        break;
    }

    array.forEach(item => {
      const attribute = this.fb.group({
        key: [[item], [Validators.required]],
        value: [[], [Validators.required]]
      });

      this.getterattributes.push(attribute);
    });
  }

  deleteAttributes(i) {
    this.getterattributes.removeAt(i);
  }

  ngOnInit(): void {
    this.device$ = this.deviceService.getDevice(this.data.deviceId);
    this.types$ = this.signalService.getSignalTypes();
    this.units$ = this.signalService.getUnits();
  }

  ngSubmit() {
    if (this.signalForm.valid) {
      if (this.signalForm.value.attributes) {
        var attributes: any = this.signalForm.value.attributes;
        let jsonObject = {};
        attributes.forEach(
          element => (jsonObject[element.key] = element.value)
        );
        attributes = jsonObject;
        delete this.signalForm.value.attributes;
      }
      this.signalService
        .createNewSignal(this.signalForm.value)
        .pipe(
          retry(3),
          switchMap(result =>
            this.signalService
              .createSignalAttributes(result.id, attributes)
              .pipe(
                retry(3),
                tap(() =>
                  this.toastrService.success(
                    "Signal has been successfully sent"
                  )
                ),
                tap(() => this.matDialogRef.close())
              )
          )
        )
        .subscribe();
    } else {
      this.toastrService.warning("Please fill out the fields");
    }

    //   if (this.data.mode === "newSignal") {
    //     this.signalService.createNewSignal(this.signalForm.value)
    //       .pipe(
    //         retry(3)
    //       )
    //       .subscribe(result => {
    //         this.signalService.createSignalAttributes(result.id, attributes)
    //           .pipe(
    //             retry(3)
    //           ).subscribe(res => {
    //             this.toastrService.success("Signal has been successfully sent")
    //             this.matDialogRef.close()
    //           })
    //       })
    //   }
    //   else {
    //     this.signalService.updateSignal(this.data.signal.id, this.signalForm.value)
    //       .pipe(
    //         retry(3),
    //         tap(res => this.toastrService.success("Signal has been successfully updated")),
    //         tap(res => this.matDialogRef.close()),
    //       ).subscribe()
    //   }
    // } else {
    //   this.toastrService.warn("Please fill out the fields")
    // }
  }
}
