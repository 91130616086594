import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HyperMediaResponse } from '../../models/hyperMediaResponse';
import { Unit } from '../models/unit';


@Injectable({
  providedIn: 'root'
})
export class UnitsService {

  constructor(private http: HttpClient) { }

  getUnits(): Observable<Unit[]> {
    let searchParams: { [key: string]: string } = {
      start: '0',
      count: '50'
    };
    return this.http
      .get<HyperMediaResponse<Unit>>(environment.endpoints.units, {
        params: searchParams
      })
      .pipe(map(d => d.resources));
  }


  getUnit(id: string): Observable<Unit> {
    return this.http
      .get<Unit>(environment.apiUrl + '/api/Units/' + id)
  }

  creatUnits(data): Observable<Unit> {
    return this.http.post<Unit>(environment.endpoints.units, data)
  }
}
