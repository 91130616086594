
<div *ngIf="device$ | async as device; else loading">
    <div class="title">
        <h1 mat-dialog-title>Device </h1>
        <div class="butons">
            <button mat-button color="accent" (click)=" getDevices()">
                <mat-icon>refresh</mat-icon>
            </button>
            <button mat-button matTooltip="Edit Device" color="accent"
                (click)="openEditDevice(data.id,data.typeId,data.parentDeviceId,data.friendlyName)">
                <mat-icon>mode</mat-icon>
            </button>
            <button class="button" mat-button color="primary" matTooltip="Add new signal" (click)="addNewSignal()">
                <mat-icon>add</mat-icon>add signal
            </button>
            <button mat-button color="warn" mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="container border">
        <h5>Name: <span style=" font-weight: bold;"> {{device.friendlyName}}</span></h5>
        <h6 class="showTempType">
            Type: <span style=" margin-left: 2%; font-weight: bold; font-size: large;">
                <iot-show-template-type [templateTypeId]="device.typeId"></iot-show-template-type>
            </span>
        </h6>
        <mat-accordion class="m-1">
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h6>Attributes:</h6>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <iot-device-attributes-show [deviceId]="device.id"></iot-device-attributes-show>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion class="m-1">
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h6>Signals:</h6>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="signals$ | async as signals">
                    <div class="m-2 border" *ngFor="let signal of signals">
                        <div class="showSignal">
                            <div style=" width: 90%;">
                                <iot-show-signals-in-device [signal]="signal"></iot-show-signals-in-device>
                            </div>
                            <div class="buttons">
                                <button class="button" mat-button color="accent" matTooltip="Edit signal"
                                    (click)="editSignal(signal)">
                                    <mat-icon>mode</mat-icon>
                                </button>
                                <button class="button" mat-button color="warn" matTooltip="Delete signal"
                                    (click)="deleteSignal(signal.id)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <h6>Device has <span style="font-size: large;"> {{device.childDevices.length}} </span> {{childstate}}</h6>
        <h6>created at: {{device.created | date :'short'}}</h6>

    </div>
</div>

<ng-template #loading>
    <div class="spinner">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>