<mat-dialog-content>
    <form class="form-field" [formGroup]="Form" (ngSubmit)="onSubmit()">

        <div class="title">
            <h2 mat-dialog-title>
                Anlage
            </h2>
            <button color="warn" mat-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <div>

            <mat-form-field class="form-field" appearance="fill">
                <mat-label>Name</mat-label>
                <input formControlName="name" matInput>
                <mat-error> Please enter a name. </mat-error>
            </mat-form-field>
            <br>

            <mat-form-field class="mat-form-field" class="form-field" appearance="fill">
                <mat-label>PlantType</mat-label>
                <mat-select formControlName="typeId">
                    <ng-container *ngIf="plantType$ | async as plantTypes">
                        <mat-option *ngFor="let plantType of plantTypes" [value]="plantType.id">
                            <img [src]="plantType.iconURI" alt="Icon" class="image"> {{plantType.key}}
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-error> Please select a plant type </mat-error>
            </mat-form-field>
            <br>

            <div formArrayName="attributes">
                <div *ngFor="let attribute of attributes.controls; let i=index" [formGroupName]="i">
                    <mat-form-field appearance="outline" style="width: 45%;" class=" pr-1 ">
                        <mat-label>Key of attribute</mat-label>
                        <input matInput formControlName="key">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="width:45%;">
                        <mat-label>value</mat-label>
                        <input matInput matInput formControlName="value">
                    </mat-form-field>


                    <button type="button" mat-mini-fab color="warn" matTooltip="Delete attribute" (click)="deleteAttributes(i)">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">delete_forever</mat-icon>
                    </button>

                </div>
            </div>
        </div>

        <div class="position">
            <mat-form-field appearance="fill">
                <mat-label>Add Attributtes</mat-label>
                <mat-select (selectionChange)="addAttributes($event)">
                    <mat-option *ngFor="let module of modules" [value]="module" >
                    {{module}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-flat-button color="primary" type="submit" value="send">Save</button>
        </div>
    </form>
    <br>

</mat-dialog-content>