import { UnitsService } from './../../services/units.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { retry, subscribeOn, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'iot-units-add',
  templateUrl: './units-add.component.html',
  styleUrls: ['./units-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitsAddComponent implements OnInit {
  message: string = " Add new Unit"
  unitsForm = this.formBuilder.group({
    key: ['', Validators.required],
    identifier: ['', Validators.required]
  })
  constructor(
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<UnitsAddComponent>,
    private toastrService: ToastrService,
    private unitsService: UnitsService
  ) { }

  ngOnInit(): void {
  }
  close() {
    this.matDialogRef.close()
  }
  onSubmit() {
    if (this.unitsForm.valid) {
      const subscribe: Subscription = this.unitsService.creatUnits(this.unitsForm.value)
        .pipe(
          retry(3),
          tap(() => subscribe.unsubscribe()),
          tap(val => this.toastrService.success('unit has been successfully saved!'))
        )
        .subscribe(res => {
          this.matDialogRef.close()
        }
        )

    } else {
      this.toastrService.warning("Please fill out all required fields")
    }

  }
}
