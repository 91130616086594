import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject } from "rxjs";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { DialogService } from "../../../../core/dialogService/dialog.service";
import { SpinnerOverlayService } from "../../../../shared/spinner-overlay.service";
import { ResourceRoleAssignment } from "../../model/resource-role-assignment";
import { ResourceRoleAssignmentsService } from "../../services/resource-role-assignments.service";
import { UserRoleAssignmentService } from "../../services/user-role-assignment.service";
@Component({
  selector: "iot-iam",
  templateUrl: "./iam.component.html",
  styleUrls: ["./iam.component.scss"]
})
export class IAMComponent implements OnInit {
  scope = "";
  tenantId = "";
  title = "";
  destroy$: Subject<boolean> = new Subject<boolean>();
  resourceRoleAssignments$: Observable<ResourceRoleAssignment[]>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private resourceRoleAssignmentsService: ResourceRoleAssignmentsService,
    private userRoleAssignmentService: UserRoleAssignmentService,
    private dialogService: DialogService,
    private toastrService: ToastrService,
    private spinnerOverlayService: SpinnerOverlayService,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getResourceRoleAssignments();

    if (this.data) {
      this.scope = this.data.scope;
      this.tenantId = this.data.tenantId;
      this.title = this.data.title;
    }
  }
  getResourceRoleAssignments() {
    return (this.resourceRoleAssignments$ = this.resourceRoleAssignmentsService.getResourceRoleAssignment(
      this.data.scope
    ));
  }

  deleteRole(item: ResourceRoleAssignment) {
    this.dialogService
      .openConfirmDialog()
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          // this.spinnerOverlayService.show();
          this.userRoleAssignmentService
            .deleteUserRole(item.userId, item.id)
            .pipe(
              tap(() => {
                this.toastrService.warning(
                  this.translateService.instant(
                    "iot.dashboard.shared.deleteSignal"
                  )
                );
                // this.spinnerOverlayService.hide();
              }),
              switchMap(result => this.getResourceRoleAssignments()),
              takeUntil(this.destroy$)
            )
            .subscribe();
        }
      });
  }
}
