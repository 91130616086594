import { UnitsAddComponent } from './../units-add/units-add.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Unit } from '../../models/unit';
import { UnitsService } from '../../services/units.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
ChangeDetectorRef
@Component({
  selector: 'iot-units-manager',
  templateUrl: './units-manager.component.html',
  styleUrls: ['./units-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitsManagerComponent implements OnInit {
  units$: Observable<Unit[]>
  start = "0"
  count = "50"
  constructor(
    private matDialog: MatDialog,
    private unitsService: UnitsService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.units$ = this.unitsService.getUnits()
  }
  getUnits(){
    this.units$ = this.unitsService.getUnits()
  }

  addUnit() {
    const matDialogConfig = new MatDialogConfig()
    matDialogConfig.height = "40%"
    matDialogConfig.width = "50%"
    matDialogConfig.autoFocus = false

    const dialogRef = this.matDialog.open(UnitsAddComponent, matDialogConfig)
    const subscribe: Subscription = dialogRef.afterClosed().pipe(tap(() => subscribe.unsubscribe)).subscribe(result => {
      this.getUnits()
      this.changeDetectorRef.detectChanges()
    }
    )
  }

}
