import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { CoreModule } from "./core/core.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app/app.component";
import { GridsterModule } from "angular-gridster2";
import { CommonModule } from "@angular/common";
import { SharedModule } from "./shared/shared.module";
import { DynamicModule } from "ng-dynamic-component";
import { SidenavService } from "./app/serendipity-components/services/sidenav/sidenav.service";
import { PlantModule } from "./app/plantManager/plant/plant.module";
import { SpinnerComponent } from "./shared/spinner/spinner.component";

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType
} from "@azure/msal-browser";
import {
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MsalRedirectComponent
} from "@azure/msal-angular";
import { msalConfig } from "./core/auth/auth-config/auth.config";
import { HomeComponent } from "./app/home/home.component";
import { environment } from "../environments/environment";

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const silentRequest = {
    scopes: environment.Azure_AD_B2C.scopes
  };
  return {
    interactionType: InteractionType.Redirect,
    authRequest: silentRequest
  };
}

@NgModule({
  imports: [
    PlantModule,
    // angular
    BrowserAnimationsModule,
    BrowserModule,
    GridsterModule,
    CommonModule,
    DynamicModule,
    MsalModule,
    // shared
    SharedModule,
    // core
    CoreModule,
    // app
    AppRoutingModule
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent, MsalRedirectComponent],
  providers: [
    SidenavService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ]
})
export class AppModule {}
