import { MatDialog } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { TenantService } from '../../services/tenant.service';
import { TenantCreateModalComponent } from '../tenant-create-modal/tenant-create-modal.component';
import { tap, retry, switchMap, map, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TenantRolesService } from '../../services/tenant-roles.service';
import { TenantRoles } from '../../models/tenantRoles';
import { UserRoleAssignmentsService } from '../../services/user-role-assignments.service';

@Component({
  selector: 'iot-tenant-manager',
  templateUrl: './tenant-manager.component.html',
  styleUrls: ['./tenant-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantManagerComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject();
  tenants$: Observable<any>;
  tenantroles: TenantRoles[] = [];
  tenantrole: TenantRoles;
  roleId: '';
  scope: '';
  userId: '';

  constructor(
    private dialog: MatDialog,
    private tenantService: TenantService,
    private toastrService: ToastrService,
    private changeDetector: ChangeDetectorRef,
    private tenantRolesService: TenantRolesService,
    private userRoleAssignmentsService: UserRoleAssignmentsService
  ) {}

  ngOnInit(): void {
    this.tenants$ = this.tenantService.getTenants();
  }
  getTenantsfFunc() {
    this.tenants$ = this.tenantService.getTenants();
  }

  creatTenant() {
    const dialogRef = this.dialog
      .open(TenantCreateModalComponent, {
        width: '75%',
        height: '85%',
        data: { message: 'Create a new tenant' }
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.tenantService
            .createTenant(result)
            .pipe(
              switchMap(tenant => this.getTenantRoles(tenant)),
              takeUntil(this.destroy$)
            )
            .subscribe(result => {
              console.log(' resultFromSub', result);
            });
          // this.tenants$.subscribe(res => console.log('newTenantres', res));
        }
      });
  }

  getTenantRoles(tenant) {
    console.log('tenant', tenant);
    this.userId = tenant.ownerId;
    this.scope = tenant.resourceId;
    return this.tenantRolesService
      .getTenantRoles(tenant.id)
      .pipe(switchMap(tenantRoles => this.userRoleAssignments(tenantRoles)));
  }

  userRoleAssignments(tenantRoles) {
    const resTenantRole = tenantRoles.find(item => item.name === 'Owner');
    console.log('TenantRoles', resTenantRole);
    this.roleId = resTenantRole.id;
    const roleAssignment = {
      roleId: this.roleId,
      scope: this.scope,
      userId: this.userId
    };

    return this.userRoleAssignmentsService
      .postUserRoleAssignment(roleAssignment)
      .pipe(
        tap(res => {
          console.log('res', res);
        })
      );
  }

  editTenant(tenantId: string, tenantName: string, ownerId: string) {
    const dialogRef: Subscription = this.dialog
      .open(TenantCreateModalComponent, {
        width: '50%',
        data: {
          tenantId: tenantId,
          tenantName: tenantName,
          ownerId: ownerId,
          message: 'Edit tenant',
          state: 'edit'
        }
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          console.log('editeTenant', result);
          this.tenants$ = this.tenantService
            .updateTenant(tenantId, result)
            .pipe(
              retry(3),
              tap(() =>
                this.toastrService.success(
                  'Change has been saved seccessfully! \n  please refresh!'
                )
              ),
              tap(() => this.getTenantsfFunc()),
              tap(() => this.changeDetector.detectChanges())
            );
          this.tenants$.subscribe();
        }
        this.getTenantsfFunc();
        this.changeDetector.detectChanges();
      });
  }

  deleteTenant(tenantId: string) {
    this.tenants$ = this.tenantService
      .deleteTenant(tenantId)
      .pipe(
        tap(val =>
          this.toastrService.warning('Object has been successfully deleted!')
        )
      );
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
