import { DeviceTemplateParameter } from './../models/device-template-parameter';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HyperMediaResponse } from '../../models/hyperMediaResponse';


import { DeviceTemplate } from '../models/device-template';
import { DeviceTemplateFE } from '../models/device-template-fe';

@Injectable({
  providedIn: 'root'
})
export class DeviceTemplateService {
  constructor(private http: HttpClient) { }

  getDeviceTemplates(name?: string, start?: string, count?: string): Observable<DeviceTemplateFE[]> {
    let searchParams: { [key: string]: string } = {
      // name: 'Moxa-Real (do not play)',
      name: name,
      start: start,
      count: count
    };
    return this.http
      .get<HyperMediaResponse<DeviceTemplate>>(
        environment.apiUrl + '/api/DeviceTemplates',
        { params: searchParams }
      )
      .pipe(
        map(d =>
          d.resources.map(dev => {
            var attributes = new Map<string, string>();
            Object.keys(dev.attributes).map(function (key) {
              attributes.set(key, dev.attributes[key]);
            });
            var b = {
              id: dev.id,
              name: dev.name,
              typeId: dev.typeId,
              created: dev.created,
              childs: Array<DeviceTemplateFE>(),
              parameters: dev.parameters,
              attributes: attributes,
              signals: dev.signals,
              count: 1
            };
            dev.childs.forEach(cd =>
              this.getDeviceTemplate(cd.id).subscribe(val => b.childs.push(val))
            );
            return b;
          })
        )
      );
  }

  // getDeviceTemplates(): Observable<DeviceTemplate[]> {
  //   let searchParams: {[key: string]: string } = {
  //     name: 'Moxa',
  //     start: '0',
  //     count:'10'
  //   };
  //   return this.http
  //     .get<HyperMediaResponse<DeviceTemplate>>(environment.apiUrl + '/api/DeviceTemplates', {params: searchParams})
  //     .pipe(
  //       map(d => d.resources));
  // }

  getDeviceTemplate(id: string): Observable<DeviceTemplateFE> {
    return this.http
      .get<DeviceTemplate>(environment.apiUrl + '/api/DeviceTemplates/' + id)
      .pipe(
        map(dev => {
          var b = {
            id: dev.id,
            created: dev.created,
            name: dev.name,
            typeId: dev.typeId,
            parameters: dev.parameters,
            attributes: dev.attributes,
            signals: dev.signals.map(s => {
              var arr = new Map<string, string>();
              Object.keys(s.attributes).map(function (key) {
                arr.set(key, s.attributes[key]);
                return arr;
              });
              return {
                id: s.id,
                typeId: s.typeId,
                friendlyName: s.friendlyName,
                unitId: s.unitId,
                attributes: arr
              };
            }),
            childs: Array<DeviceTemplateFE>(),
            count: 1
          };
          dev.childs.forEach(cd =>
            this.getDeviceTemplate(cd.id).subscribe(val => b.childs.push(val))
          );
          return b;
        })
      );
  }

  createTemplate(devTemp: any): Observable<DeviceTemplate> {

    let jsonObject1 = {};
    devTemp.attributes.forEach((attr) => {
      jsonObject1[attr.key] = attr.value;
    });
    devTemp.attributes = jsonObject1;

    // let jsonObject2 = {};
    // devTemp.parameters.forEach((value, key) => {
    //   jsonObject2[key] = value;
    // });
    // devTemp.parameters = jsonObject1;
    return this.http.post<DeviceTemplate>(
      environment.apiUrl + '/api/DeviceTemplates',
      devTemp
    );
  }

  updateTemplate(templateId: string, data): Observable<DeviceTemplate> {
    return this.http.patch<DeviceTemplate>(
      environment.apiUrl + '/api/DeviceTemplates/' + templateId, data
    );
  }

  createTemplateParameter(id: any, parameters: any): Observable<DeviceTemplateParameter> {
    return this.http.post<DeviceTemplateParameter>(
      environment.apiUrl + '/api/DeviceTemplateParameters/' + id.templateId, parameters
    )
  }
  updateTemplateParameter(templateId: string, paramterId: string, data): Observable<DeviceTemplateParameter> {
    console.log("dataParameter", data);
    return this.http.patch<DeviceTemplateParameter>(environment.endpoints.deviceTemplateParameters + "/" + templateId + "/" + paramterId, data)
  }
  deleteTemplateParameter(templateId: string, parameterId: string): Observable<DeviceTemplate> {
    console.log("templaId", templateId);
    console.log("parameterId", parameterId);
    return this.http.delete<DeviceTemplate>(environment.endpoints.deviceTemplateParameters + "/" + templateId + "/" + parameterId)
  }

  addChildTemplate(child: any, parentId: string): Observable<DeviceTemplate> {
    let jsonObject = {};
    child.attributes.forEach((value, key) => {
      jsonObject[key] = value;
    });
    child.attributes = jsonObject;
    return this.http.post<DeviceTemplate>(
      environment.apiUrl + '/api/DeviceTemplateChilds/' + parentId,
      child
    );
  }

  // public getDeviceTemplates(): Observable<DeviceFE[]> {
  //   return of([
  //     {
  //       id: '1',
  //       friendlyName: 'Battery Container',
  //       type: 'BatCon',
  //       childDevices: [
  //         {
  //           id: '11',
  //           friendlyName: 'BMS',
  //           type: 'Bat',
  //           childDevices: [
  //             {
  //               id: '111',
  //               friendlyName: 'Rack1',
  //               type: 'BatRack',
  //               childDevices: [],
  //               signals: [],
  //               attributes: new Map<string, string>([
  //                 ['type', 'Modbus'],
  //                 ['Modbus.Add', '1'],
  //                 ['Modbus.Cnt', '1']
  //               ]),
  //               parameters: new Map<string, string>([
  //                 ['Modbus.IP', ''],
  //                 ['Modbus.Port', '']
  //               ])
  //             },
  //             {
  //               id: '112',
  //               friendlyName: 'Rack2',
  //               type: 'BatRack',
  //               childDevices: [],
  //               signals: [],
  //               attributes: new Map<string, string>([
  //                 ['type', 'Modbus'],
  //                 ['Modbus.Add', '1'],
  //                 ['Modbus.Cnt', '1']
  //               ]),
  //               parameters: new Map<string, string>([
  //                 ['Modbus.IP', ''],
  //                 ['Modbus.Port', '']
  //               ])
  //             }
  //           ],
  //           signals: [],
  //           attributes: new Map<string, string>([
  //             ['type', 'Modbus'],
  //             ['Modbus.Add', '1'],
  //             ['Modbus.Cnt', '1']
  //           ]),
  //           parameters: new Map<string, string>([
  //             ['Modbus.IP', ''],
  //             ['Modbus.Port', '']
  //           ])
  //         },
  //         {
  //           id: '12',
  //           friendlyName: 'MoxaSensor',
  //           type: 'Moxa',
  //           childDevices: [],
  //           signals: [],
  //           attributes: new Map<string, string>([
  //             ['type', 'Modbus'],
  //             ['Modbus.Add', '1'],
  //             ['Modbus.Cnt', '1']
  //           ]),
  //           parameters: new Map<string, string>([
  //             ['Modbus.IP', ''],
  //             ['Modbus.Port', '']
  //           ])
  //         }
  //       ],
  //       signals: [{ name: 'naber' }],
  //       attributes: new Map<string, string>([
  //         ['type', 'Modbus'],
  //         ['Modbus.Add', '1'],
  //         ['Modbus.Cnt', '1']
  //       ]),
  //       parameters: new Map<string, string>([
  //         ['Modbus.IP', ''],
  //         ['Modbus.Port', '']
  //       ])
  //     },
  //     {
  //       id: '12',
  //       friendlyName: 'MoxaSensor',
  //       type: 'Moxa',
  //       childDevices: [],
  //       signals: [],
  //       attributes: new Map<string, string>([
  //         ['type', 'Modbus'],
  //         ['Modbus.Add', '1'],
  //         ['Modbus.Cnt', '1']
  //       ]),
  //       parameters: new Map<string, string>([
  //         ['Modbus.IP', ''],
  //         ['Modbus.Port', '']
  //       ])
  //     },
  //     {
  //       id: '11',
  //       friendlyName: 'BMS',
  //       type: 'Bat',
  //       childDevices: [
  //         {
  //           id: '111',
  //           friendlyName: 'Rack1',
  //           type: 'BatRack',
  //           childDevices: [],
  //           signals: [],
  //           attributes: new Map<string, string>([
  //             ['type', 'Modbus'],
  //             ['Modbus.Add', '1'],
  //             ['Modbus.Cnt', '1']
  //           ]),
  //           parameters: new Map<string, string>([
  //             ['Modbus.IP', ''],
  //             ['Modbus.Port', '']
  //           ])
  //         },
  //         {
  //           id: '112',
  //           friendlyName: 'Rack2',
  //           type: 'BatRack',
  //           childDevices: [],
  //           signals: [],
  //           attributes: new Map<string, string>([
  //             ['type', 'Modbus'],
  //             ['Modbus.Add', '1'],
  //             ['Modbus.Cnt', '1']
  //           ]),
  //           parameters: new Map<string, string>([
  //             ['Modbus.IP', ''],
  //             ['Modbus.Port', '']
  //           ])
  //         }
  //       ],
  //       signals: [],
  //       attributes: new Map<string, string>([
  //         ['type', 'Modbus'],
  //         ['Modbus.Add', '1'],
  //         ['Modbus.Cnt', '1']
  //       ]),
  //       parameters: new Map<string, string>([
  //         ['Modbus.IP', ''],
  //         ['Modbus.Port', '']
  //       ])
  //     }
  //   ]).pipe(delay(5000));
  // }

  public drop(event: CdkDragDrop<string[]>) {
    console.log('event:', event);
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
}
