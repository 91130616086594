<form>
    <mat-form-field>
        <mat-label>Parent Device</mat-label>
        <mat-select [(ngModel)]="parentdevice" (ngModelChange)="selectionChanged($event)"
            [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let device of alldevices" [value]="device">
                {{device.friendlyName}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <p>{{data.template.friendlyName}}</p>
    <mat-form-field *ngFor="let attr of data.template.parameters">
        <mat-label>{{attr.key}}</mat-label>
        <input matInput placeholder="Ex. Pizza" [(ngModel)]="attr.default" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
    <div *ngFor="let child of data.template.childDevices">
        <p>{{child.friendlyName}}</p>
        <mat-form-field *ngFor="let attr of child.attributes | keyvalue">
            <mat-label>{{attr.key}}</mat-label>
            <input matInput placeholder="Ex. Pizza" [(ngModel)]="attr.value" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>
</form>
<div class="buttonContainer">
<button mat-fab color="primary" matTooltip="Add Template to the Device" (click)="clickEvent()">
    <mat-icon>add</mat-icon>
</button>
<button mat-fab  matTooltip="Close"  color="warn" mat-dialog-close>
    <mat-icon>close</mat-icon>
</button>
</div>