import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { UserRoleAssignments } from '../models/userRoleAssignments';

@Injectable({
  providedIn: 'root'
})
export class UserRoleAssignmentsService {
  constructor(private httpClient: HttpClient) {}

  postUserRoleAssignment(roleAssignment): Observable<any> {
    return this.httpClient.post<UserRoleAssignments>(
      environment.endpoints.userRoleAssignment,
      roleAssignment
    );
  }
}
