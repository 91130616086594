import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { TenantRoles } from '../models/tenantRoles';
import { HyperMediaResponse } from './../../../../shared/model/HyperMediaResponse';
@Injectable({
  providedIn: 'root'
})
export class TenantRolesService {
  constructor(private httpClient: HttpClient) {}

  getTenantRoles(userId: string) {
    return this.httpClient
      .get<HyperMediaResponse<TenantRoles>>(
        environment.endpoints.tenantRoles + userId
      )
      .pipe(map(tenantRoles => tenantRoles.resources));
  }
}
