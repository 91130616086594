import { PlantType } from "./../../models/plant-type";

import { PlantTypeService } from "./../../services/plant-type.service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, Subscription } from "rxjs";
import { tap, retry } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "iot-add-plant-type",
  templateUrl: "./add-plant-type.component.html",
  styleUrls: ["./add-plant-type.component.scss"]
})
export class AddPlantTypeComponent implements OnInit {
  message = "Add new Plant Type";
  plantTypes$: Observable<PlantType>;
  plantTypeForm = this.formBuilder.group({
    key: ["", Validators.required],
    iconURI: ["", Validators.required]
  });

  constructor(
    private plantTypeService: PlantTypeService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<AddPlantTypeComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  ngOnInit(): void {
    if (this.data) {
      this.message = this.data.message;
      const subscribe: Subscription = this.plantTypeService
        .getPlantTypeById(this.data.id)
        .subscribe(result => {
          this.plantTypeForm.patchValue({
            key: result.key,
            iconURI: result.iconURI
          });
        });
    }
  }

  OnSubmit() {
    if (this.plantTypeForm.valid) {
      if (this.data === undefined) {
        const subscribe: Subscription = this.plantTypeService
          .createPlantType(this.plantTypeForm.value)
          .pipe(
            retry(3),
            tap(() => subscribe.unsubscribe)
          )
          .subscribe(res => {
            this.toastrService.success("PlantType has been successfully sent.");
            this.dialogRef.close();
          });
      } else {
        let neueValue: any = this.plantTypeForm.value;
        neueValue.icon = neueValue.iconURI;
        delete neueValue.iconURI;
        const subscribe: Subscription = this.plantTypeService
          .updatePlantType(this.data.id, neueValue)
          .pipe(
            retry(3),
            tap(() => subscribe.unsubscribe)
          )
          .subscribe(res => {
            this.toastrService.success(
              "PlantType has been successfully Updated."
            );
            this.dialogRef.close();
          });
      }
    } else {
      this.toastrService.warning("Please fill the fields.");
    }
  }
  close() {
    this.dialogRef.close();
  }
}
