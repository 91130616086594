import { SignalService } from './../../services/signal.service';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DeviceTemplateSignalAttributes } from '../../models/device-template-signal-attributes';

import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'anms-signal-attributes',
  templateUrl: './signal-attributes.component.html',
  styleUrls: ['./signal-attributes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignalAttributesComponent implements OnInit {
  myFormAttributes: FormGroup;
  attributes: FormArray;
  arrayAttributes;
  readonly: boolean = false;

  attributesArray: Array<DeviceTemplateSignalAttributes>;
  @Input() signalAttributes;
  @Input() data;
  @Input() edit;

  constructor(
    private SignalService: SignalService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.readonly = this.signalAttributes.key !== '';
    // console.log("signalAttributesboolean", this.signalAttributes.key);
    // console.log("mySignalAttr", this.signalAttributes);
    this.myFormAttributes = this.formBuilder.group({
      // key: new FormControl({ value: this.signalAttributes.key, disabled: true }, [Validators.required]),
      key: [this.signalAttributes.key, [Validators.required]],
      value: [this.signalAttributes.value, [Validators.required]]
    });
    // console.log('signalAttributes', this.signalAttributes);
    //  console.log('dataAddAttributes', this.data);
    const subscribe: Subscription = this.myFormAttributes.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        tap(val => {
          if (val.value === '') {
            this.toastrService.warning('The value must not be empty');
            val.value = 'not initialized';
          }
        }),
        tap(val => subscribe.unsubscribe)
      )
      .subscribe(val => {
        if (this.edit) {
          const subscribe: Subscription = this.SignalService.editTemplateSignalattributes(
            this.data.templateId,
            this.data.signal.id,
            this.signalAttributes.key,
            val.value
          )
            .pipe(
              tap(val =>
                this.toastrService.success('The changes have been saved')
              ),
              tap(val => subscribe.unsubscribe)
            )
            .subscribe();
        } else {
          this.signalAttributes.key = val.key;
          this.signalAttributes.value = val.value;
          const subscribe: Subscription = this.SignalService.createTemplateSignalAttribute(
            this.data.templateId,
            this.data.signal.id,
            val.key,
            val.value
          )
            .pipe(tap(val => subscribe.unsubscribe))
            .subscribe();
        }
      });

    // this.myFormAttributes
    //   .get('value')
    //   .valueChanges.pipe(
    //     distinctUntilChanged(),
    //     debounceTime(1000))
    //   .subscribe(va => {
    //     this.map.set(this.keyy, va);
    //   });

    // this.myFormAttributes
    //   .get('key')
    //   .valueChanges.pipe(
    //     distinctUntilChanged(),
    //     debounceTime(1000))
    //   .subscribe(va => {
    //     this.map.set(va, this.map.get(this.keyy));
    //     this.map.delete(this.keyy);
    //     this.keyy = va;
    //   });
  }

  // removeObject() {
  //   this.SignalService.removeTemplateSignalattributes(
  //     this.data.templateId,
  //     this.data.signal.id,
  //     this.signalAttributes.key,
  //     // this.signalAttributes
  //   ).pipe(tap(res => console.log('resultafterDelete', res)))
  //     .subscribe(res => {
  //       this.data.signal.attributes.delete(this.signalAttributes.key);
  //       console.log('dataAddAttributes', this.data);
  //     });
  // }

  // this.myForm.patchValue({
  //   friendlyName: this.data.signal.friendlyName,
  //   typeId: this.data.signal.typeId,
  //   unitId: this.data.signal.unitId
  //   // attributes: this.data.signal.attributes
  //   //attributes: inputAttributesAfterchange
  //   //attributes: Object.keys(this.data.signal.attributes)
  //   //    attributes: this.formBuilder.array(Object.keys(this.data.signal.attributes).map(key => this.formBuilder.group({
  //   //     key: this.formBuilder.control(key),
  //   //     value: this.formBuilder.control(this.data.signal.attributes[key])
  //   //  })))
  //   // attributes: this.formBuilder.array
  //   // attributes: this.formBuilder.array(Object.keys(this.data.signal.attributes).map(key => this.formBuilder.group({
  //   //   key: this.formBuilder.control(key),
  //   //   value: this.formBuilder.control(this.data.signal.attributes[key])
  //   // })))
  // });
}
