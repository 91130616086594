import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { retry, tap } from 'rxjs/operators';
import { PlantType } from '../../models/plant-type';
import { PlantTypeService } from '../../services/plant-type.service';
import { PlantService } from '../../services/plant.service';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'anms-plant-add',
  templateUrl: './plant-add.component.html',
  styleUrls: ['./plant-add.component.scss']
})
export class PlantAddComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  isCreating: boolean = false;
  plantType$: Observable<PlantType[]>;
  Form: FormGroup;

  modules = ['LocalStorage', 'Other'];
  localstorageattributes = [
    'LocalStorage.DataSink.RollingOption',
    'LocalStorage.DataSink.ConnectionString',
    'LocalStorage.DataSink.FingerPrint',
    'LocalStorage.DataSink.NoSSLValidation',
    'LocalStorage.DataSink',
    'LocalStorage.EventSink.RollingOption',
    'LocalStorage.EventSink.ConnectionString',
    'LocalStorage.EventSink.FingerPrint',
    'LocalStorage.EventSink.NoSSLValidation',
    'LocalStorage.EventSink'
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public plantService: PlantService,
    public plantTypeservice: PlantTypeService,
    private matdialogdef: MatDialogRef<PlantAddComponent>,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.plantType$ = this.plantTypeservice.getPlantType();
    this.Form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      typeId: ['', [Validators.required]],
      attributes: this.formBuilder.array([])
    });

    if (this.data.plant !== undefined) {
      this.Form.patchValue({
        name: this.data.plant.name,
        typeId: this.data.plant.typeId
      });

      var ArryOfAttributes = Object.keys(
        this.data.plant.attributes
      ).map(key => ({ key: key, value: this.data.plant.attributes[key] }));

      ArryOfAttributes.forEach(key => {
        this.addAttribute(key.key, key.value);
      });
    }
  }

  get attributes() {
    return this.Form.get('attributes') as FormArray;
  }

  addAttribute(key?, value?) {
    const attribute = this.formBuilder.group({
      key: [key, [Validators.required]],
      value: [value, [Validators.required]]
    });
    this.attributes.push(attribute);
  }
  addAttributes($event: any) {
    let array = [];
    switch ($event.value) {
      case 'LocalStorage':
        array = this.localstorageattributes;
        break;
      default:
        this.addAttribute();
        break;
    }

    array.forEach(item => {
      this.addAttribute(item);
    });
  }
  deleteAttributes(i) {
    this.attributes.removeAt(i);
  }

  onSubmit() {
    if (this.Form.valid) {
      if (this.data.plant !== undefined) {
        this.plantService
          .editePlant(this.data.plant.id, this.Form.value)
          .pipe(
            retry(3),
            tap(() =>
              this.toastrService.success('Change has been successfully sent')
            ),
            tap(val => {
              var removed = [];
              var added = [];
              this.Form.value.attributes.forEach(element => {
                if (!Object.keys(val.attributes).includes(element.key)) {
                  added.push({ key: element.key, value: element.value });
                }
              });

              Object.keys(val.attributes).forEach(a => {
                if (!this.Form.value.attributes.map(at => at.key).includes(a)) {
                  removed.push(a);
                }
              });

              this.plantService
                .editPlantAttributes(val.id, {
                  removed: removed,
                  added: this.Form.value.attributes
                })
                .subscribe();
            }),
            takeUntil(this.destroy$)
          )
          .subscribe(val => this.matdialogdef.close());
      } else {
        this.Form.value.tenantId = this.data.tenantId;
        this.Form.value.flags = new Map<string, boolean>();

        const subscription: Subscription = this.plantService
          .createPlant(this.Form.value)
          .pipe(
            retry(3),
            tap(() => this.toastrService.success('submitted successfully')),
            tap(val =>
              this.plantService
                .addPlantAttributes(val.id, this.Form.value.attributes)
                .subscribe()
            ),
            tap(() => subscription.unsubscribe())
          )
          .subscribe(val => this.matdialogdef.close());
      }
    } else {
      this.toastrService.warning('Please enter a name and select a plant type');
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.subscribe();
  }
}
