import { SignalService } from './../../services/signal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { retry, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'iot-device-signal-attributes-add',
  templateUrl: './device-signal-attributes-add.component.html',
  styleUrls: ['./device-signal-attributes-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceSignalAttributesAddComponent implements OnInit {

  myForm = this.formBuilder.group({
    key: ['', Validators.required],
    value: ['', Validators.required],
  })

  constructor(
    private signalService: SignalService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialogRef: MatDialogRef<DeviceSignalAttributesAddComponent>,
  ) { }

  ngOnInit(): void {

  }

  onSubmit() {
    if (this.myForm.valid) {
      const signalId = this.data.signalId;
      const key = this.myForm.value.key;
      const value = this.myForm.value.value;
      this.signalService.createSignalAttribute(signalId, key, value)
        .pipe(
          retry(3)
        )
        .subscribe(() => {
          this.toastrService.success("attribute has been successfully sent!")
        }
        )

      this.myForm.reset()

    }
    else {
      this.toastrService.warning("Please fill out the fields!")
    }
  }

  close() {
    this.matDialogRef.close("true")
  }
}
