<form [formGroup]="myFormAttributes">
    <div class="container">
        <mat-form-field style="width: 45%;">
            <mat-label>Key of attribute</mat-label>
            <input [readonly]="readonly" formControlName="key" matInput>
        </mat-form-field>
        <mat-form-field style="width: 45%;">
            <mat-label>value</mat-label>
            <input formControlName="value" matInput>
            <mat-error>
                Value is <strong>required</strong>
            </mat-error>
        </mat-form-field>
        <!-- <a mat-flat-button color="" (click)="removeObject()">Delete</a> -->
    </div>
</form>