import { retry } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { Observable } from 'rxjs';
import { Plants } from '../../models/plant';
import { PlantService } from '../../services/plant.service';

@Component({
  selector: 'anms-plant-info',
  templateUrl: './plant-info.component.html',
  styleUrls: ['./plant-info.component.scss']
})
export class PlantInfoComponent implements OnInit {
  @Input() public plantId: string;

  public plant$: Observable<Plants>;

  constructor(private plantSvc: PlantService) {}

  ngOnInit(): void {
    this.plant$ = this.plantSvc.getPlant(this.plantId).pipe(retry(3));
  }
}
