import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TenantSelectorComponent } from "./components/tenant-selector/tenant-selector.component";
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { TenantCreateModalComponent } from "./components/tenant-create-modal/tenant-create-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { SharedModule } from "../../../shared/shared.module";
import { TenantInfoComponent } from "./components/tenant-info/tenant-info.component";
import { TenantManagerComponent } from "./components/tenant-manager/tenant-manager.component";

@NgModule({
  declarations: [
    TenantSelectorComponent,
    TenantCreateModalComponent,
    TenantInfoComponent,
    TenantManagerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    //BrowserAnimationsModule,
    ReactiveFormsModule,
    SharedModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatIconModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule
  ],
  exports: [
    TenantCreateModalComponent,
    TenantSelectorComponent,
    TenantInfoComponent
  ]
})
export class SharedTenantModule {}
