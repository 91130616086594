import { DeviceAttributesEditComponent } from './../device-attributes-edit/device-attributes-edit.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DeviceAttributs } from '../../models/deviceAttribute';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'iot-device-attributes-show',
  templateUrl: './device-attributes-show.component.html',
  styleUrls: ['./device-attributes-show.component.scss'],
})
export class DeviceAttributesShowComponent implements OnInit, OnDestroy {
  @Input() public deviceId
  subscription: Subscription = new Subscription()
  deviceAttributes$: Observable<DeviceAttributs>


  constructor(
    private deviceService: DeviceService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.deviceAttributes$ = this.deviceService.getDeviceAttributes(this.deviceId)
  }
  getdeviceAttributes() {
    this.deviceAttributes$ = this.deviceService.getDeviceAttributes(this.deviceId)
  }
  edit(deviceAttributes) {
    const matDialogConfig = new MatDialogConfig()
    matDialogConfig.width = "60%";
    matDialogConfig.height = "90%";
    matDialogConfig.disableClose = true
    matDialogConfig.data = { deviceAttributes: deviceAttributes, deviceId: this.deviceId }
    const dialogRef = this.matDialog.open(DeviceAttributesEditComponent, matDialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      this.getdeviceAttributes()
      if (result === 'true') {
      }

    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

}