import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { userSearchResult } from '../../models/tenant';
import { UsersService } from '../../services/users.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'iot-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  showUser = false;
  showSpinner = false;
  showNotfinde = false;
  @Output() userSearchResult = new EventEmitter<userSearchResult>();
  userEmailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);
  public searchResults$: Observable<userSearchResult>;
  matcher = new MyErrorStateMatcher();
  constructor(private usersService: UsersService) {}

  ngOnInit(): void {}

  searchUser() {
    if (this.userEmailFormControl.valid) {
      this.showSpinner = true;
      this.searchResults$ = this.usersService
        .getUserByEmail(this.userEmailFormControl.value)
        .pipe(
          tap(resultFromTap => {
            this.userSearchResult.emit(resultFromTap);
            this.showSpinner = false;
            this.showNotfinde = false;
          }),
          catchError(error => {
            this.showNotfinde = true;
            this.userSearchResult.emit(null);
            return throwError('error');
          })
        );
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
