

<form [formGroup]="searchFrom" (ngSubmit)="onSubmit()">

    <mat-card class="example-result-card">
        <mat-card-content>
            <div class="searchButton">
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="name" formControlName="name">
                </mat-form-field>
                <button mat-button class="p-2 m-1" type="submit" color="primary">
                    <mat-icon>search</mat-icon>search
                </button>
            </div>
            <div>
                <mat-label>start value</mat-label>
                <mat-slider thumbLabel max="100" min="0" formControlName="start"></mat-slider>
            </div>
            <div>
                <mat-label>count</mat-label>
                <mat-slider thumbLabel max="50" min="1" formControlName="count"></mat-slider>
            </div>
        </mat-card-content>

    </mat-card>
</form>


<div class="listandButton">
    <div class="temp-list">
        <anms-device-template-list-view [temps]="templates$"></anms-device-template-list-view>
    </div>

    <div class="buttons m-1">
        <button class="mb-1" mat-stroked-button color="primary" (click)="addTemplate()">
            <mat-icon>add</mat-icon>add Template
        </button>
        <button class="mb-1" mat-stroked-button color="accent" (click)="openUnitsManager()">
            <mat-icon>build</mat-icon>Unit Manager
        </button>
        <button class="mb-1" mat-stroked-button color="accent" (click)="openSignalTypeManager()">
            <mat-icon>build</mat-icon>Signal Type Manager
        </button>
    </div>

</div>