import { Signal } from "./../../../../../shared-signals/models/signal";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Inject
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DeviceTemplateSignal } from "../../models/device-template-signal";
import { SignalType } from "../../models/signal-type";
import { Unit } from "../../models/unit";
import { SignalService } from "../../services/signal.service";
import {
  retry,
  tap,
  distinctUntilChanged,
  debounceTime,
  map,
  pairwise
} from "rxjs/operators";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "anms-signal-add",
  templateUrl: "./signal-add.component.html",
  styleUrls: ["./signal-add.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignalAddComponent implements OnInit {
  public newSignal: any = {};
  public signalTypes$;
  public units$;
  myForm: FormGroup;
  arrayAttributes;
  arrayKeyAttributes = [];

  modules = ["Modbus", "LocalStorage", "ModbusServer", "Mqtt", "Snmp", "Other"];
  modbusattributes = [
    "Modbus.Function",
    "Modbus.Offset",
    "Modbus.OutputType",
    "Modbus.RegisterAddress",
    "Modbus.RegisterType",
    "Modbus.Scale"
  ];
  modbusserverattributes = [
    "ModbusServer.TargetRegister",
    "ModbusServer.Offset",
    "ModbusServer.OutputType",
    "ModbusServer.RegisterType",
    "ModbusServer.Gain"
  ];
  mqttattributes = ["Mqtt.TagName"];
  snmpattributes = [""];

  constructor(
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private signalService: SignalService,
    public dialogRef: MatDialogRef<SignalAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.signalTypes$ = signalService.getSignalTypes();
    this.signalTypes$.subscribe();
    this.units$ = signalService.getUnits();
    this.units$.subscribe();
  }

  ngOnInit(): void {
    this.newSignal.attributes = new Map<string, string>();
    this.myForm = this.formBuilder.group({
      friendlyName: ["", [Validators.required, Validators.minLength(3)]],
      typeId: ["", [Validators.required]],
      unitId: ["", [Validators.required]],
      attributes: this.formBuilder.array([])
    });
  }
  get attributesForms() {
    return this.myForm.get("attributes") as FormArray;
  }

  addAttribute() {
    const attribute = this.formBuilder.group({
      key: [[], [Validators.required]],
      value: [[], [Validators.required]]
    });

    this.attributesForms.push(attribute);
    // this.myForm.get('attributes').patchValue(this.arrayAttributes);
  }

  addAttributes($event: any) {
    let array = [];
    switch ($event.value) {
      case "Modbus":
        array = this.modbusattributes;
        break;
      case "ModbusServer":
        array = this.modbusserverattributes;
        break;
      case "Mqtt":
        array = this.mqttattributes;
        break;
      case "Snmp":
        array = this.snmpattributes;
        break;
      default:
        this.addAttribute();
        break;
    }

    array.forEach(item => {
      const attribute = this.formBuilder.group({
        key: [[item], [Validators.required]],
        value: [[], [Validators.required]]
      });

      this.attributesForms.push(attribute);
    });
  }

  deleteAttributes(i) {
    this.attributesForms.removeAt(i);
  }

  onSubmit() {
    this.myForm.get("attributes").value;
    if (this.myForm.valid) {
      this.dialogRef.close({ data: this.data, myFormValue: this.myForm.value });
      // this.newSignal.typeId = this.myForm.value.typeId;
      // this.newSignal.friendlyName = this.myForm.value.friendlyName;
      // this.newSignal.unitId = this.myForm.value.unitId;
      // this.myForm
      //   .get('attributes')
      //   .value.forEach(val => this.newSignal.attributes.set(val.key, val.value));

      // // console.log(this.newSignal);

      // // if (this.data !== undefined && this.data !== null) {
      // this.signalService
      //   .createTemplateSignal(this.data, this.newSignal).pipe(
      //     tap(() => this.toastrService.success('object has been successfully sent'))
      //   )
      //   .subscribe(signal =>
      //     this.signalService
      //       .createTemplateSignalAttributes(
      //         this.data,
      //         signal.id,
      //         this.newSignal.attributes
      //       )
      //       .subscribe()
      //   );
      // // }

      // this.dialogRef.close(this.myForm.value)
      // // this.dialogRef.close({ signal: this.newSignal });
    } else {
      this.toastrService.warning("Please fill the fields");
    }
  }

  changeKey(prevKey, newKey) {
    const value = this.newSignal.attributes[prevKey];
    this.newSignal.attributes.delete(prevKey);
    this.newSignal.attributes.set(newKey, value);
  }

  closeSignal() {
    this.dialogRef.close();
  }
}
