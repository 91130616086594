<h1 mat-dialog-title>{{message}}</h1>

<mat-stepper orientation="vertical" [linear]="true" #stepper>
    <mat-step>
        <form [formGroup]="tenantNameForm">
            <ng-template matStepLabel>Fill out Tenant name</ng-template>
            <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>Name of the tenant</mat-label>
                <input matInput formControlName="tenantName" placeholder="Name" required>
                <mat-error>
                    This field is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <div>
                <button [disabled]="!tenantNameForm.valid" mat-button matStepperNext>Next</button>
            </div>
        </form>
    </mat-step>

    <mat-step>
        <ng-template matStepLabel>Find User</ng-template>
        <ng-template matStepContent>
            <div style="height: 300px;">
                <iot-search-user (userSearchResult)="userSearchResult($event)"></iot-search-user>
            </div>
            <div>
                <button mat-button matStepperPrevious>Back</button>
                <button color="accent" *ngIf="event" mat-button (click)="createTenant()" matStepperNext>Next</button>
            </div>
        </ng-template>
    </mat-step>


    <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <ng-template matStepContent>
            <p *ngIf="!tenantObj">You are not done now.</p>
            <div *ngIf="tenantObj && tenantNameForm.valid">
                <p>You are now done.</p>
                <h6>
                    tenant Name: {{tenantObj.name}}
                </h6>
                <h6>
                    tenant Id: {{tenantObj.ownerId}}
                </h6>
            </div>
            <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button (click)="stepper.reset()">Reset</button>
                <button mat-button color="primary" *ngIf="event && tenantNameForm.valid" (click)="postTenant()">Create Tenant</button>
            </div>
        </ng-template>
    </mat-step>
</mat-stepper>


<!-- 
<div class="container">

    <iot-search-user style="height: 300px;" (userSearchResult)="userSearchResult($event)"></iot-search-user>

    <mat-form-field appearance="outline">
        <mat-label>Owner Id</mat-label>
        <input matInput formControlName="ownerId" placeholder="Owner Id">
        <mat-error>
            This field is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <div class="buttonContainer">
        <button mat-flat-button color="primary" type="submit">Save</button>
        <button mat-flat-button color="warn" type="button" mat-dialog-close>Close</button>
    </div>
</div> -->



<!-- <p>Tenant Create</p>
<mat-form-field class="example-full-width">
    <mat-label>Name of the tenant</mat-label>
    <textarea matInput [(ngModel)]="newTenant.name"></textarea>
</mat-form-field>

<mat-form-field>
  <input type="text"
          placeholder="Select an owner"
          aria-label="Number"
          matInput
          [formControl]="myControl"
          [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)=selectionChanged($event.option.value) [displayWith]="displayFn">
    <mat-option *ngFor="let owner of filteredowners$ | async" [value]="owner">
      {{owner.namespace}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>


<button mat-fab color="primary" matTooltip="Save the new tenant" (click)="clickEvent()">
  Save
</button> -->