import {
  Injectable,
  ErrorHandler,
  NgZone,
  Injector,
  Inject
} from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../../environments/environment";

// import { NotificationService } from '../notifications/notification.service';
import { AppInsightService } from "../ErrorService/app-insight.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { MsalService } from "@azure/msal-angular";

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(
    // private notificationsService: NotificationService,
    private appInsightService: AppInsightService,
    private zone: NgZone,
    @Inject(Injector) private injector: Injector,
    public translateService: TranslateService,
    private authService: MsalService
  ) {
    super();
  }

  private get toasterService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  handleError(error: Error | HttpErrorResponse) {
    this.zone.run(() => {
      this.appInsightService.logException(error); // Manually log exception
      if (error.message) {
        // let displayMessage = 'An error occurred.' + `${error.message}`;
        // displayMessage += `${error.message} \n`
        let displayMessage0 = this.translateService.instant(
          "iot.dashboard.tooltip.anErroOccured"
        );
        let displayMessage1 = this.translateService.instant(
          "iot.dashboard.tooltip.seeConsole"
        );

        let displayMessage = (displayMessage0 += displayMessage1);

        if (!environment.production) {
          this.toasterService.error(displayMessage, "", {
            closeButton: true,
            onActivateTick: true,
            disableTimeOut: true
          });

          // this.notificationsService.error(displayMessage);
        }
      }
      super.handleError(error);
    });
  }
}
