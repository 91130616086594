import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { IAMComponent } from '../../../../IAM/components/iam/iam.component';
import { Plants } from '../../models/plant';
import { PlantService } from '../../services/plant.service';

@Component({
  selector: 'anms-plant',
  templateUrl: './plant.component.html',
  styleUrls: ['./plant.component.scss']
})
export class PlantComponent implements OnInit, OnChanges {
  @Input() public tenantId: string;
  @Input() public tenant;
  @Output() public plantSelected = new EventEmitter<Plants>();
  Plants$: Observable<Plants[]>;
  TypeId = '';
  Search = '';

  constructor(public plantservice: PlantService, public matDialog: MatDialog) {}

  ngOnInit(): void {
    this.refresh();
  }

  tenantRoleAssignment() {
    const config = new MatDialogConfig();
    config.height = '80%';
    config.width = '90%';
    config.data = {
      tenantId: this.tenant.id,
      scope: this.tenant.resourceId,
      title: this.tenant.name
    };

    const matDialog = this.matDialog.open(IAMComponent, config);
    matDialog.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  refresh(): void {
    this.Plants$ = this.plantservice
      .getplants(this.tenantId, this.TypeId, this.Search)
      .pipe(retry(3));
  }
  ngOnChanges() {
    this.Plants$ = this.plantservice
      .getplants(this.tenantId, this.TypeId, this.Search)
      .pipe(retry(3));
  }
}
