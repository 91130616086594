<div class=" p-2 border">
    <h2 mat-dialog-title>{{message}}</h2>
    <form class="formContainer" [formGroup]="parametersForm" (ngSubmit)="onSubmit()">

        <div>
            <mat-form-field appearance="outline" style="width: 45%;" class=" pr-1 ">
                <mat-label>Key of paramter</mat-label>
                <input matInput formControlName="key">
                <mat-error>
                    Key is <strong>required</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" style="width:45%;">
                <mat-label>Value of paramter</mat-label>
                <input matInput matInput formControlName="default">
                <mat-error>
                    Value is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class=" mt-5 buttonContainer">
            <button mat-flat-button color="primary" type="submit">Save</button>
            <button mat-flat-button color="warn" type="button" (click)="closeDialog()">Close</button>
        </div>
    </form>
</div>

<!-- 
<h2 mat-dialog-title>Add Parameter</h2>
<form class="formContainer" action="" [formGroup]="parametersForm" (ngSubmit)="onSubmit()">

    <div formArrayName="paramters">
        <div class="attrPosition" *ngFor="let paramter of paramters.controls; let i=index" [formGroupName]="i">
            <mat-form-field appearance="outline" style="width: 45%;" class=" pr-1 ">
                <mat-label>Key of paramter</mat-label>
                <input matInput formControlName="key">
                <mat-error>
                    Key is <strong>required</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" style="width:45%;">
                <mat-label>Value of paramter</mat-label>
                <input matInput matInput formControlName="default">
                <mat-error>
                    Value is <strong>required</strong>
                </mat-error>
            </mat-form-field>

            <a button mat-mini-fab color="warn" matTooltip="Delete attribute" (click)="deleteParamters(i)">
                <mat-icon aria-hidden="false" aria-label="Example home icon">delete_forever</mat-icon>
            </a>

        </div>
    </div>
    <div class="buttonContainer">
        <button mat-flat-button color="accent" type="button" (click)="addAttributes()">Add Parameter</button>
        <button mat-flat-button color="primary" type="submit">Submit</button>
        <button mat-flat-button color="warn" type="button" mat-dialog-close> Close</button>
    </div>
</form> -->

<!-- 
<div>
    <pre>{{value | json}}</pre>
</div> -->