import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "iot-alarm-info",
  templateUrl: "./alarm-info.component.html",
  styleUrls: ["./alarm-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlarmInfoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
