<div class="div" fxLayout="row" fxLayoutAlign="space-around start">
   <div fxFlex="80" cdkDropList id="deviceList"  [cdkDropListData]="dataSource.data"
      [cdkDropListConnectedTo]="['templateList']" class="example-list" (cdkDropListDropped)="drop($event)">
      <h5 class="m-2">Devices in the plant</h5>

      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
         <!-- This is the tree node template for leaf nodes -->
         <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <li class="mat-tree-node m-1">
               <h6 style="margin-left: 5%;">
                  {{node.friendlyName}}
               </h6>

               <p style="margin-top: 12px; margin-left: 2%;">
                  <iot-show-template-type [templateTypeId]="node.typeId"></iot-show-template-type>
               </p>

               <button mat-button color="accent" (click)="openDeviceManager(node)">
                  <mat-icon>info</mat-icon> see details
               </button>
               <button mat-button color="accent" (click)="copyRack(node)">
                  <mat-icon>content_copy</mat-icon> Copy
               </button>
               <button mat-button color="accent" (click)="removeDevice(node)">
                  <mat-icon>delete</mat-icon> Remove
               </button>
            </li>
         </mat-tree-node>

         <!-- This is the tree node template for expandable nodes -->
         <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <li>
               <div class="mat-tree-node">
                  <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                     <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                     </mat-icon>
                  </button>

                  <h6 style="margin-left: 2%;">
                     {{node.friendlyName}}
                  </h6>
                  <p style="margin-top: 12px; margin-left: 2%;">
                     <iot-show-template-type [templateTypeId]="node.typeId"></iot-show-template-type>
                  </p>

                  <button mat-button color="accent" (click)="openDeviceManager(node)">
                     <mat-icon>info</mat-icon> see details
                  </button>

                  <button mat-button color="accent" (click)="copyRack(node)">
                     <mat-icon>content_copy</mat-icon> Copy
                  </button>

                  <button mat-button color="accent" (click)="removeDevice(node)">
                     <mat-icon>delete</mat-icon> Remove
                  </button>
               </div>
               <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                  <ng-container matTreeNodeOutlet></ng-container>
               </ul>
            </li>
         </mat-nested-tree-node>
      </mat-tree>

   </div>
   <mat-card class="style-mat-card">
      <anms-device-template-list></anms-device-template-list>
   </mat-card>

</div>