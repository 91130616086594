import { SignalType } from "./../../models/signal-type";
import { Observable } from "rxjs";
import { FormBuilder, Validators, FormArray } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogConfig,
  MatDialog
} from "@angular/material/dialog";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef
} from "@angular/core";
import { DeviceService } from "../../services/device.service";
import { DeviceFE } from "../../models/device-fe";
import { SignalService } from "../../services/signal.service";
import { retry, tap, switchMap } from "rxjs/operators";
import { Unit } from "../../models/unit";
import { DialogService } from "../../../../../core/dialogService/dialog.service";
import { DeviceSignalAttributesAddComponent } from "../device-signal-attributes-add/device-signal-attributes-add.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "iot-device-signals-edit",
  templateUrl: "./device-signals-edit.component.html",
  styleUrls: ["./device-signals-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceSignalsEditComponent implements OnInit {
  message = this.data.message;
  device$: Observable<DeviceFE>;
  types$: Observable<SignalType[]>;
  units$: Observable<Unit[]>;
  readonly: boolean = false;

  signalForm = this.fb.group({
    deviceId: [this.data?.deviceId],
    friendlyName: ["", Validators.required],
    index: ["", Validators.required],
    typeId: ["", Validators.required],
    key: ["", Validators.required],
    unitId: ["", Validators.required],
    attributes: this.fb.array([])
  });

  constructor(
    private toastrService: ToastrService,
    private signalService: SignalService,
    private deviceService: DeviceService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialogRef: MatDialogRef<DeviceSignalsEditComponent>,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  get getterattributes() {
    return this.signalForm.get("attributes") as FormArray;
  }

  addAttributes(key?, value?) {
    const attribute = this.fb.group({
      key: [key, Validators.required],
      value: [value, Validators.required]
    });
    this.getterattributes.push(attribute);
  }

  openaddAttributes() {
    const config = new MatDialogConfig();
    let signalId = this.data.signal.id;
    config.data = { signalId: signalId };
    config.disableClose = true;
    config.height = "40%";
    config.width = "50%";
    const dialogref = this.dialog.open(
      DeviceSignalAttributesAddComponent,
      config
    );
    dialogref.afterClosed().subscribe(res => {
      if (res === "true") {
        this.matDialogRef.close();
      }
    });
  }

  deleteAttributes(i, key) {
    let signalId = this.data.signal.id;

    this.dialogService
      .openConfirmDialog()
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.signalService
            .deleteSignalAttributes(signalId, key)
            .pipe(
              retry(3),
              tap(() =>
                this.toastrService.warning(
                  "Attributes has been successfully deleted"
                )
              )
            )
            .subscribe(() => this.changeDetectorRef.detectChanges());
          this.getterattributes.removeAt(i);
        }
      });
  }

  ngOnInit(): void {
    console.log("data", this.data);
    if (this.data.mode === "newSignal") {
      this.device$ = this.deviceService.getDevice(this.data.deviceId);
    }
    this.types$ = this.signalService.getSignalTypes();
    this.units$ = this.signalService.getUnits();

    if (this.data.signal) {
      var myObject = this.data.signal.attributes;
      var ArryOfAttributes = Object.keys(myObject).map(key => ({
        key: key,
        value: myObject[key]
      }));
    }

    if (this.data.mode === "editSignal") {
      this.readonly = true;
      this.signalForm.patchValue({
        friendlyName: this.data.signal.friendlyName,
        index: this.data.signal.index,
        typeId: this.data.signal.typeId,
        key: this.data.signal.key,
        unitId: this.data.signal.unitId
      });
      this.signalForm.get("deviceId").disable();

      ArryOfAttributes.forEach(key => {
        this.addAttributes(key.key, key.value);
      });
    }
  }

  ngSubmit() {
    if (this.signalForm.valid) {
      if (this.signalForm.value.attributes) {
        var attributes: any = this.signalForm.value.attributes;
        let jsonObject = {};
        attributes.forEach(
          element => (jsonObject[element.key] = element.value)
        );
        attributes = jsonObject;
        delete this.signalForm.value.attributes;
      }

      if (this.data.mode === "newSignal") {
        this.signalService
          .createNewSignal(this.signalForm.value)
          .pipe(
            retry(3),
            switchMap(result =>
              this.signalService
                .createSignalAttributes(result.id, attributes)
                .pipe(
                  retry(3),
                  tap(() => this.matDialogRef.close()),
                  tap(() =>
                    this.toastrService.success(
                      "Signal has been successfully sent"
                    )
                  )
                )
            )
          )
          .subscribe();
      } else {
        this.signalService
          .updateSignal(this.data.signal.id, this.signalForm.value)
          .pipe(
            retry(3),
            switchMap(result =>
              this.signalService
                .updateSignalAttributes(result.id, attributes)
                .pipe(
                  retry(3),
                  tap(() => this.matDialogRef.close()),
                  tap(() =>
                    this.toastrService.success(
                      "Signal has been successfully updated"
                    )
                  )
                )
            )
          )
          .subscribe();
      }
    } else {
      this.toastrService.warning("Please fill out the fields");
    }
  }
}
