import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "iot-critical-alarm",
  templateUrl: "./critical-alarm.component.html",
  styleUrls: ["./critical-alarm.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CriticalAlarmComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
