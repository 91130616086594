import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from "@angular/core";
import { Observable } from "rxjs";
import { User } from "../../model/user";

import { GetUserInfoByIDService } from "../../services/get-user-info-by-id.service";
@Component({
  selector: "iot-show-user-inofs-in-user-role-manager",
  templateUrl: "./show-user-inofs-in-user-role-manager.component.html",
  styleUrls: ["./show-user-inofs-in-user-role-manager.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowUserInofsInUserRoleManagerComponent implements OnInit {
  @Input() userId: string;
  user$: Observable<User>;
  constructor(private GetUserInfoByIDService: GetUserInfoByIDService) {}

  ngOnInit(): void {
    this.user$ = this.GetUserInfoByIDService.getUserById(this.userId);
  }
}
