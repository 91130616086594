<div class="container" *ngIf="deviceAttributes$ | async as deviceAttributes; else loading">
    <div>
        <mat-list *ngFor="let item of deviceAttributes">
            <mat-card class="example-card">
                <div class="ItemsList">
                    <div> key: {{item.key}} </div>
                    <div class="ml-5"> value: {{item.value}} </div>
                </div>
            </mat-card>
        </mat-list>
    </div>

    <div>
        <button style="font-size: large;" mat-button color="accent" (click)="edit(deviceAttributes)">
            <mat-icon>mode</mat-icon>
        </button>
    </div>
</div>


<ng-template #loading>
    <div class="spinner">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>