import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
interface Tenant {
  name: string;
  ownerId: string;
}
@Component({
  selector: 'anms-tenant-create-modal',
  templateUrl: './tenant-create-modal.component.html',
  styleUrls: ['./tenant-create-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantCreateModalComponent implements OnInit, OnDestroy {
  tenantNameForm: FormGroup;
  getUserForm: FormGroup;
  tenantName: string;
  tenantOwnerId: string;
  event = null;
  tenantObj: Tenant;
  destroy$: Subject<boolean> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<TenantCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  message: string = this.data.message;
  ngOnInit(): void {
    this.tenantNameForm = this.formBuilder.group({
      tenantName: ['', Validators.required]
    });

    if (this.data.state === 'edit') {
      this.tenantNameForm.patchValue({
        tenantName: this.data.tenantName,
        ownerId: this.data.ownerId
      });
    }
    //this.owners = this.tenantService.getOwners();
    // this.filteredowners$ = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => (typeof value === 'string' ? value : value.name)),
    //   map(name => (name ? this._filter(name) : this.owners.slice()))
    // );
  }

  createTenant() {
    this.tenantObj = {
      name: this.tenantNameForm.controls.tenantName.value,
      ownerId: this.tenantOwnerId
    };
  }

  userSearchResult(event) {
    console.log('evnt', event);
    if (event) {
      this.event = event;
      this.tenantOwnerId = event.id;
    } else {
      this.event = null;
    }
  }

  postTenant() {
    if (this.tenantNameForm.valid && this.event) {
      this.dialogRef.close(this.tenantObj);
    } else {
      this.toastrService.warning('Please fill out all the fields');
    }
  }

  // private _filter(value: string): Tenant[] {
  //   const filterValue = value.toLowerCase();

  //   return this.owners.filter(option =>
  //     option.namespace.toLowerCase().includes(filterValue)
  //   );
  // }

  // selectionChanged(selected) {
  //   this.newTenant.ownerId = selected.id;
  // }

  // clickEvent(): void {
  //   if (this.newTenant.name == null) {
  //     this.newTenant.name = '';
  //   }
  //   this.tenantService.createTenant(this.newTenant).subscribe();
  //   this.dialogRef.close({
  //     tenant: this.newTenant
  //   });
  // }

  // displayFn(tnnt: any): string {
  //   return tnnt ? tnnt.namespace : '';
  // }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
