import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  AfterViewInit
} from '@angular/core';
import { DeviceTemplateService } from '../../services/device-template.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { DeviceTemplateFE } from '../../models/device-template-fe';

@Component({
  selector: 'anms-device-template-info-modal',
  templateUrl: './device-template-info-modal.component.html',
  styleUrls: ['./device-template-info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceTemplateInfoModalComponent implements AfterViewInit {
  public treeControl = new NestedTreeControl<DeviceTemplateFE>((node) => node.childs);
  public dataSource = new MatTreeNestedDataSource<DeviceTemplateFE>();

  constructor(
    private devtempService: DeviceTemplateService,
    public dialogRef: MatDialogRef<DeviceTemplateInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeviceTemplateFE[]
  ) {
    this.dataSource.data = data;
  }

  hasChild = (_: number, node: DeviceTemplateFE) =>
    !!node.childs && node.childs.length > 0;

  ngAfterViewInit(): void {
    //this.treeControl.expandAll();
  }
}
