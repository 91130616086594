import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlantComponent } from './components/plant/plant.component';
import { PlantListViewComponent } from './components/plant-list-view/plant-list-view.component';
import { PlantAddComponent } from './components/plant-add/plant-add.component';
import { SharedModule } from '../../../shared/shared.module';
import { PlantInfoComponent } from './components/plant-info/plant-info.component';
import { PlantTypeInfoComponent } from './components/plant-type-info/plant-type-info.component';
import { PlantTypeManagerComponent } from './components/plant-type-manager/plant-type-manager.component';
import { AddPlantTypeComponent } from './components/add-plant-type/add-plant-type.component';
@NgModule({
  declarations: [
    PlantComponent,
    PlantListViewComponent,
    PlantAddComponent,
    PlantInfoComponent,
    PlantTypeInfoComponent,
    PlantTypeManagerComponent,
    AddPlantTypeComponent
  ],
  imports: [CommonModule, SharedModule],
  exports: [PlantComponent, PlantAddComponent, PlantInfoComponent]
})
export class SharedPlantModule {}
