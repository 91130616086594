import { DeviceAttributs } from "./../models/deviceAttribute";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { concat, Observable, of, queueScheduler, scheduled } from "rxjs";
import { concatAll, delay, map, retry, tap } from "rxjs/operators";
import { StringDecoder } from "string_decoder";
import { environment } from "../../../../../environments/environment";
import { HyperMediaResponse } from "../../models/hyperMediaResponse";
import { Device } from "../models/device";
import { DeviceFE } from "../models/device-fe";
import { DeviceType } from "../models/device-type";
@Injectable({
  providedIn: "root"
})
export class DeviceService {
  constructor(private http: HttpClient) {}

  getDevices(plantId: string, tenantId: string): Observable<DeviceFE[]> {
    let searchParams: { [key: string]: string } = {
      plantId: plantId,
      tenantId: tenantId,
      start: "0",
      count: "50"
    };
    return this.http
      .get<HyperMediaResponse<Device>>(environment.apiUrl + "/api/Devices", {
        params: searchParams
      })
      .pipe(
        map(d => d.resources.map(dev => this.apitofrontend(dev, d.resources)))
      );
  }

  getDevice(id: string): Observable<DeviceFE> {
    return this.http
      .get<Device>(environment.apiUrl + "/api/Devices/" + id)
      .pipe(
        map(dev => {
          var b = {
            id: dev.id,
            version: dev.version,
            friendlyName: dev.friendlyName,
            typeId: dev.typeId,
            created: dev.created,
            tenantId: dev.tenantId,
            plantId: dev.plantId,
            isRootDevice: dev.isRootDevice,
            hasChildDevices: dev.hasChildDevices,
            parentDeviceId: dev.parentDeviceId,
            childDevices: Array<DeviceFE>(),
            flags: dev.flags,
            attributes: dev.attributes
          };
          dev.childDevices.forEach(cd =>
            this.getDevice(cd).subscribe(val => b.childDevices.push(val))
          );
          return b;
        })
      );
  }

  createDevice(device: any): Observable<Device> {
    return this.http.post<Device>(environment.apiUrl + "/api/Devices", device);
  }

  createDevices(devices: any): Observable<Device> {
    return scheduled(
      devices.map(device => this.createDevice(device)),
      queueScheduler
    ).pipe(concatAll());
  }

  updateDevice(deviceId: string, deviceData: any): Observable<Device> {
    return this.http.patch<Device>(
      environment.endpoints.devices + "/" + deviceId,
      deviceData
    );
  }

  apitofrontend(api: Device, devicelist: Device[]): DeviceFE {
    var a = {
      id: api.id,
      version: api.version,
      friendlyName: api.friendlyName,
      typeId: api.typeId,
      created: api.created,
      tenantId: api.tenantId,
      plantId: api.plantId,
      isRootDevice: api.isRootDevice,
      hasChildDevices: api.hasChildDevices,
      parentDeviceId: api.parentDeviceId,
      childDevices: api.childDevices.map(cd =>
        this.apitofrontend(
          devicelist.find(d => d.id == cd),
          devicelist
        )
      ),
      flags: api.flags,
      attributes: api.attributes
    };
    return a;
  }

  getDeviceTypes(): Observable<DeviceType[]> {
    let searchParams: { [key: string]: string } = {
      start: "0",
      count: "50"
    };
    return this.http
      .get<HyperMediaResponse<DeviceType>>(
        environment.apiUrl + "/api/DeviceTypes",
        { params: searchParams }
      )
      .pipe(map(d => d.resources));
  }

  getDeviceType(id: string): Observable<DeviceType> {
    return this.http.get<DeviceType>(
      environment.apiUrl + "/api/DeviceTypes/" + id
    );
  }

  addAttribute(deviceId: string, key: string, value: string) {
    return this.http
      .post<HyperMediaResponse<any>>(
        environment.apiUrl + "/api/DeviceAttributes/" + deviceId,
        { key: key, value: value }
      )
      .pipe(map(d => d.resources));
  }

  getDeviceAttributes(deviceId: string): Observable<DeviceAttributs> {
    return this.http
      .get<DeviceAttributs>(
        environment.endpoints.deviceAttributes + "/" + deviceId
      )
      .pipe(map(res => res.resources));
  }

  deleteDeviceAttributes(deviceId, attribute): Observable<any> {
    return this.http
      .delete<any>(
        environment.endpoints.deviceAttributes +
          "/" +
          deviceId +
          "/" +
          attribute
      )
      .pipe(map(value => value.resources));
  }

  addAttributes(deviceId: string, attributes: Map<string, string>) {
    return scheduled(
      Array.from(attributes.keys()).map(key =>
        this.addAttribute(deviceId, key, attributes.get(key))
      ),
      queueScheduler
    ).pipe(concatAll());
  }

  editDeviceAttributes(deviceId: string, attributes: any) {
    console.log("attributes in edit.attr.", attributes);
    return this.http
      .patch<any>(
        environment.endpoints.deviceAttributes + "/" + deviceId,
        attributes
      )
      .pipe(map(d => d.resources));
  }
}
