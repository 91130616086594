<div class="footer-dark">
  <footer>
    <div class="container">
      <!-- <button mat-flat-button (click)="goTop()">go up</button> -->
      <div class="row">
        <div class=" col-sm-3 col-md-4 item">
          <h3>{{ "iot.dashboard.shared.services" | translate }}</h3>
          <ul>
            <li>
              <a href="#">{{ "iot.menu.plantManager" | translate }}</a>
            </li>
            <li>
              <a href="#">{{ "iot.menu.dashboard" | translate }}</a>
            </li>
            <li>
              <a href="#">{{ "iot.menu.settings" | translate }}</a>
            </li>
          </ul>
        </div>

        <div class="  col-sm-3 col-md-4 item">
          <h3>{{ "iot.menu.about" | translate }}</h3>
          <ul>
            <li>
              <a href="https://www.sma-sunbelt.com/about-us.html">{{
                "iot.dashboard.shared.Company" | translate
              }}</a>
            </li>
            <li>
              <a href="https://www.sma-sunbelt.com/contact/career.html"
                >Career</a
              >
            </li>
            <li>
              <a
                href="https://www.sma-sunbelt.com/data-protection/data-protection-declaration.html"
                >{{ "Data Protection Declaration" | translate }}</a
              >
            </li>
            <li>
              <a href="https://www.sma-sunbelt.com/legal-notice.html">{{
                "iot.dashboard.shared.Legalnotice" | translate
              }}</a>
            </li>
          </ul>
        </div>

        <div class=" col-md-4 text">
          <h3>{{ "iot.dashboard.shared.titleDesciption" | translate }}</h3>
          <p>{{ "iot.dashboard.shared.description" | translate }}</p>
        </div>

        <div class="col item social">
          <a href="https://de.linkedin.com/company/sma-sunbelt-energy-gmbh">
            <fa-icon [icon]="['fab', 'linkedin']" size="lg"></fa-icon>
          </a>
          <a href="https://www.youtube.com/user/smasolartechnology/">
            <fa-icon [icon]="['fab', 'youtube']" size="lg"></fa-icon>
          </a>
          <a href="https://www.instagram.com/sma.solar/">
            <fa-icon [icon]="['fab', 'instagram']" size="lg"></fa-icon>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="  col-sm-8 col-md-6 item">
          <p class="copyright">© SMA Sunbelt Energy GmbH 2021</p>
        </div>
        <div class=" col-sm-8 col-md-6 item">
          <p class="version">
            {{ "iot.dashboard.shared.version" | translate }} {{ version }}
          </p>
        </div>
      </div>
    </div>
  </footer>
</div>
