<div class="title">
    <div>
        <mat-icon class="color" style="font-size: 70px;">info</mat-icon>
    </div>
    <h3 style="padding-bottom: 10%;" mat-dialog-title>{{'iot.dashboard.confirmComponent.areYouSure'|translate}}</h3>
</div>
<div mat-dialog-actions align="center" class="buttons">
    <button class="button" mat-button (click)="no()">{{'iot.dashboard.confirmComponent.cancel'|translate}}</button>
    <button class="button" mat-flat-button color="primary" (click)="yes()">
            <mat-icon>done</mat-icon>{{'iot.dashboard.confirmComponent.yes'|translate}}
        </button>
</div>