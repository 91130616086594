import { DeviceTemplate } from './../../models/device-template';
import { Observable } from 'rxjs';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { Validators, FormBuilder, FormArray } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DeviceTemplateService } from '../../services/device-template.service';
import { DeviceService } from '../../services/device.service';
import { SignalService } from '../../services/signal.service';
import {  retry, tap } from 'rxjs/operators';
import { DeviceType } from '../../models/device-type';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'anms-device-template-creation-modal',
  templateUrl: './device-template-creation-modal.component.html',
  styleUrls: ['./device-template-creation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceTemplateCreationModalComponent implements OnInit {
  value = {};

  public templateService$: Observable<DeviceTemplate>;
  public deviceTypes$: Observable<DeviceType[]>;
  public newtemplate: any = {};

  modules = ['Modbus', 'Other'];
  modbusattributes = [
    'Modbus.UnitId',
    'Modbus.Port',
    'Modbus.DelayTime',
    'Modbus.MaxParallelRequests'
  ];

  constructor(
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private templateService: DeviceTemplateService,
    private signalService: SignalService,
    private deviceService: DeviceService,
    private dialogref: MatDialogRef<DeviceTemplateCreationModalComponent>,
    public dialog: MatDialog // @Inject(MAT_DIALOG_DATA) public data: DeviceTemplateFE[] // @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  newTemplateForm = this.formBuilder.group({
    name: ['', Validators.required],
    typeId: ['', Validators.required],
    attributes: this.formBuilder.array([])
  });

  ngOnInit(): void {
    this.deviceTypes$ = this.deviceService.getDeviceTypes();
  }

  get attributes() {
    return this.newTemplateForm.get('attributes') as FormArray;
  }

  addAttribute(key?: string) {
    const attribute = this.formBuilder.group({
      key: [[key], [Validators.required]],
      value: [[], [Validators.required]]
    });

    this.attributes.push(attribute);
  }

  addAttributes($event: any) {
    let array = [];
    switch ($event.value) {
      case 'Modbus':
        array = this.modbusattributes;
        break;
      default:
        this.addAttribute();
        break;
    }

    array.forEach(item => {
      this.addAttribute(item);
    });
  }

  deleteAttributes(i) {
    this.attributes.removeAt(i);
  }

  onSubmit() {
    if (this.newTemplateForm.valid) {
      this.templateService$ = this.templateService.createTemplate(
        this.newTemplateForm.value
      );
      this.templateService$
        .pipe(
          retry(3),
          tap(() =>
            this.toastrService.success(
              'Template has been successfully sent'
            )
          )
        )
        .subscribe();
      this.dialogref.close();
    } else {
      this.toastrService.warning('Please fill the fields');
    }
  }
}
