import { SignalService } from './../../services/signal.service';
import { SignalType } from './../../models/signal-type';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
@Component({
  selector: 'iot-signal-type-manager',
  templateUrl: './signal-type-manager.component.html',
  styleUrls: ['./signal-type-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignalTypeManagerComponent implements OnInit {
  signalType$: Observable<SignalType[]>
  constructor(
    private signalService: SignalService
  ) { }

  ngOnInit(): void {
    this.signalType$ = this.signalService.getSignalTypes()
  }

  addSignalType() { }

}
