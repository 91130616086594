<div class="border container mt-5">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="m-3">
        <h2>Plant Manager</h2>
        <button mat-flat-button matTooltip="Tenant Role Assignment" (click)="tenantRoleAssignment()"><mat-icon>manage_accounts</mat-icon></button>
    </div>

    <ng-template #loading>
        <mat-progress-spinner color="primary" class="spinner-position" mode="indeterminate"></mat-progress-spinner>
    </ng-template>

    <div *ngIf="Plants$ | async as Plants; else loading">
        <anms-plant-list-view [tenantId]="tenantId" [Plants$]="Plants" (refreshRequested)="refresh()" (plantSelected)="plantSelected.emit($event)"></anms-plant-list-view>
    </div>
</div>