import { Injectable } from '@angular/core';
import { Tenant } from './../models/tenant';
// import tenants from './../models/mock-tenants.json';
// import owners from './../models/mock-owners.json';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { HyperMediaResponse } from '../../models/hyperMediaResponse';
import { map } from 'rxjs/operators';
import { PlantType } from '../../shared-plant/models/plant-type';
@Injectable({
  providedIn: 'root'
})
export class TenantService {
  a: PlantType = { iconURI: '', key: '' };
  constructor(private http: HttpClient) { }

  getTenants(
    search?: string,
    ownerId?: string,
    start?: number,
    count?: number
  ): Observable<Tenant[]> {
    let searchParams: { [key: string]: string } = {
      search: search,
      ownerId: ownerId,
      start: (start || 0).toString(),
      count: (count || 50).toString()
    };

    return this.http
      .get<HyperMediaResponse<Tenant>>(environment.apiUrl + '/api/Tenants')
      .pipe(map(tenants => tenants.resources));
  }

  // getOwners() {
  //   var x = <any[]>owners;
  //   return x;
  // }

  getTenant(id: string): Observable<Tenant> {
    // return of((<Tenant[]>tenants).find((t) => t.id === id));
    return this.http.get<Tenant>(environment.endpoints.tenants + '/' + id);
  }

  createTenant(newtenant: Tenant): Observable<Tenant> {
    return this.http.post<Tenant>(
      environment.endpoints.tenants,
      // environment.apiUrl + '/api/Tenants',
      newtenant
    );
  }

  updateTenant(tenantsId: string, tenantsData: Tenant): Observable<Tenant> {

    return this.http.patch<Tenant>(
      environment.endpoints.tenants + '/' + tenantsId,
      // environment.apiUrl + '/api/Tenants/' +tenantsId ,
      tenantsData
    );
  }

  deleteTenant(tenantsId: string): Observable<Tenant> {
    console.log("deleteTenantId", tenantsId);
    return this.http.delete<Tenant>(environment.endpoints.tenants + '/' + tenantsId)
  }
}
