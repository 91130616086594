import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { ResourceRoleAssignment } from "../model/resource-role-assignment";

@Injectable({
  providedIn: "root"
})
export class ResourceRoleAssignmentsService {
  api = environment.endpoints.resourceRoleAssignments;
  constructor(private httpClient: HttpClient) {}

  getResourceRoleAssignment(scope): Observable<ResourceRoleAssignment[]> {
    const queryScope = {
      scope: scope
    };
    return this.httpClient.get<ResourceRoleAssignment[]>(this.api, {
      params: queryScope
    });
  }
}
