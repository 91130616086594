import { UnitsManagerComponent } from './../units-manager/units-manager.component';
import { debounceTime, distinctUntilChanged, tap, startWith } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { DeviceTemplate } from '../../models/device-template';
import { DeviceTemplateFE } from '../../models/device-template-fe';
import { DeviceTemplateService } from '../../services/device-template.service';
import { SignalService } from '../../services/signal.service';
import { DeviceTemplateCreationModalComponent } from '../device-template-creation-modal/device-template-creation-modal.component';
import { SignalTypeManagerComponent } from '../signal-type-manager/signal-type-manager.component';

@Component({
  selector: 'anms-device-template-list',
  templateUrl: './device-template-list.component.html',
  styleUrls: ['./device-template-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DeviceTemplateListComponent implements OnInit {
  public templates$: Observable<DeviceTemplateFE[]>;
  constructor(
    private devicetempService: DeviceTemplateService,
    private signalService: SignalService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) { }
  name: string = ''
  start: string = '0'
  count: string = '20'

  searchFrom = this.formBuilder.group({
    name: ['', Validators.required],
    start: ['0', Validators.required],
    count: ['20', Validators.required],
  })


  ngOnInit(): void {
    const subscribe: Subscription = this.searchFrom.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      tap(val => {
        this.name = val.name
        this.start = val.start
        this.count = val.count
      })
    ).subscribe(val => {
      this.templates$ = this.devicetempService.getDeviceTemplates(this.name, this.start, this.count);
    })

    this.templates$ = this.devicetempService.getDeviceTemplates(this.name, this.start, this.count);
    // this.templates$.subscribe();
  }

  addTemplate() {
    const dialogRef = this.dialog.open(DeviceTemplateCreationModalComponent, {
      width: '70%',
      height: '70%',
      autoFocus : false
    });
  }

  onSubmit() {
    this.templates$ = this.devicetempService.getDeviceTemplates(this.name, this.start, this.count);
  }

  openUnitsManager() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.autoFocus = false
    dialogConfig.width = '70%'
    dialogConfig.height = '70%'
    const dialogRef = this.dialog.open(UnitsManagerComponent, dialogConfig)
  }
  openSignalTypeManager() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.autoFocus = false
    dialogConfig.width = '70%'
    dialogConfig.height = '70%'
    const dialogRef = this.dialog.open(SignalTypeManagerComponent, dialogConfig)
  }



}
