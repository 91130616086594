import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { User } from "../app/IAM/model/user";
@Injectable({
  providedIn: "root"
})
export class UserPermissionsAndRolesService {
  constructor(private HttpClient: HttpClient) {}
  api = environment.endpoints.usersId;
  public myAccountInstance: BehaviorSubject<any> = new BehaviorSubject<any>("");
  myAccountData = this.myAccountInstance.asObservable();
  myData$: Observable<any>;
  permissions;

  setAccountInstance(data) {
    this.myData$ = this.getUserById(data.localAccountId);

    this.myData$.subscribe(results => {
      this.permissions = results.globalPermission;
      console.log("per", results);
    });
  }

  getUserById(id): Observable<User> {
    return this.HttpClient.get<User>(this.api + id);
  }
}
