import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler } from '@angular/core';
import { Observable, of, queueScheduler, scheduled, throwError } from 'rxjs';
import { delay, map, tap, catchError, concatAll } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HyperMediaResponse } from '../../models/hyperMediaResponse';
import { Plants } from '../models/plant';
import { PlantType } from '../models/plant-type';

@Injectable({
  providedIn: 'root'
})
export class PlantService {
  constructor(private http: HttpClient) { }
  private plants: Array<Plants>;

  getplants(
    tenantId: string,
    typeId?: string,
    search?: string,
    start?: number,
    count?: number
  ): Observable<Plants[]> {
    let searchParams: { [key: string]: string } = {
      TenantId: tenantId,
      Search: search,
      TypeId: typeId,
      Start: (start || 0).toString(),
      Count: (count || 50).toString()
    };
    if (typeId != undefined) {
      searchParams.typeId = typeId;
    }
    if (search != undefined) {
      searchParams.search = search;
    }
    if (tenantId != undefined) {
      searchParams.tenantId = tenantId;
    }
    return this.http
      .get<HyperMediaResponse<Plants>>(environment.apiUrl + '/api/Plants', {
        params: searchParams
      })
      .pipe(
        map(plant => plant.resources),
        catchError(this.errorHandler)
      );
  }


  getPlant(plantId: string): Observable<Plants> {
    return this.http.get<Plants>(environment.apiUrl + '/api/Plants/' + plantId);
  }
  getPlantTypes(): Observable<PlantType[]> {
    let searchParams = {
      search: '',
      start: (0).toString(),
      count: (100).toString()
    };
    return this.http
      .get<HyperMediaResponse<PlantType>>(
        environment.apiUrl + '/api/PlantTypes',
        {
          params: searchParams
        }
      )
      .pipe(map(tenants => tenants.resources));
  }

  createPlant(plant: any): Observable<Plants> {
    let jsonObject = {};
    plant.attributes.forEach(attr => {
      jsonObject[attr.key] = attr.value;
    })
    plant.attributes = jsonObject;
    return this.http
      .post<Plants>(environment.apiUrl + '/api/Plants', plant)
      .pipe(catchError(this.errorHandler));
  }


  editePlant(PlantId: string, EditedPlant: any) {
    // let jsonObject = {};
    // EditedPlant.attributes.forEach(attr => {
    //   jsonObject[attr.key] = attr.value;
    // })
    // EditedPlant.attributes = jsonObject;
    return this.http.patch<Plants>(
      environment.apiUrl + '/api/Plants/' + PlantId,
      { name: EditedPlant.name, typeId: EditedPlant.typeId }
    );
  }

  addPlantAttribute(plantId: string, key: string, value: string) {
    return this.http.post<any>(
      environment.apiUrl + '/api/PlantAttributes/' + plantId,
      { key: key, value: value }
    );
  }

  addPlantAttributes(plantId: string, attributes: any) {
    return scheduled(Object.keys(attributes).map(attr => this.addPlantAttribute(plantId, attr, attributes[attr])), queueScheduler).pipe(concatAll());
  }

  editPlantAttributes(plantId: string, attributes: any) {
    let jsonObject = {};
    attributes.added.forEach(attr => {
      jsonObject[attr.key] = attr.value;
    })
    attributes.added = jsonObject;
    return this.http.patch<any>(
      environment.apiUrl + '/api/PlantAttributes/' + plantId,
      attributes
    );
  }

  private errorHandler(error: HttpErrorResponse): Observable<any> {
    return throwError(error);
  }
}
