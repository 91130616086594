import { retry } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy
} from '@angular/core';
import { DeviceType } from '../../models/device-type';
import { Observable, Subscription } from 'rxjs';
import { DeviceService } from '../../services/device.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'iot-device-edit',
  templateUrl: './device-edit.component.html',
  styleUrls: ['./device-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceEditComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  deviceForm: FormGroup;
  parentDeviceId: string;
  deviceTypes$: Observable<DeviceType[]>;
  allDevices$: Observable<any>;
  constructor(
    private toastrService: ToastrService,
    private deviceService: DeviceService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialogRef: MatDialogRef<DeviceEditComponent>
  ) {}

  ngOnInit(): void {
    console.log('data', this.data);
    this.deviceForm = this.formBuilder.group({
      friendlyName: [this.data.friendlyName, Validators.required],
      typeId: [this.data.typeId, Validators.required],
      parentDeviceId: this.data.parentDeviceId
    });
    this.deviceTypes$ = this.deviceService.getDeviceTypes();
    this.allDevices$ = this.deviceService.getDevices(
      this.data.plantId,
      this.data.tenantId
    );
  }

  onSubmit() {
    if (this.deviceForm.valid) {
      if (this.deviceForm.value.parentDeviceId == this.data.parentDeviceId) {
        this.deviceForm.value.parentDeviceId = null;
      }
      this.subscription.add(
        this.deviceService
          .updateDevice(this.data.deviceId, this.deviceForm.value)
          .pipe(retry(3))
          .subscribe(res => {
            this.toastrService.success('Device has been successfully updated');
            this.matDialogRef.close(true);
          })
      );
    } else {
      this.toastrService.warning('Please fill out all fields.');
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
