<div class="example-container mat-elevation-z8">
  <div class="buttons">
    <mat-form-field appearance="standard">
      <mat-label>{{
        "iot.plantManager.plantList.filter" | translate
      }}</mat-label>
      <button type="button" mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
      </button>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="{{
          'iot.plantManager.plantList.filterByName' | translate
        }}"
        #input
      />
    </mat-form-field>

    <div>
      <button mat-button color="accent" (click)="plantTypeManager()">
        <mat-icon>build</mat-icon
        >{{ "iot.plantManager.plantList.plantTypeManager" | translate }}
      </button>
      <button
        *ngIf="allowCreatePlant"
        mat-flat-button
        color="primary"
        class="ml-5"
        (click)="onCreate()"
      >
        <mat-icon>add</mat-icon
        >{{ "iot.plantManager.plantList.CreateNewPlant" | translate }}
      </button>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "iot.plantManager.plantList.Name" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "iot.plantManager.plantList.CreatedAt" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.created | date: "short" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="plantType">
      <th mat-header-cell *matHeaderCellDef>
        {{ "iot.plantManager.plantList.PlantType" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <anms-plant-type-info
          [plantTypeId]="element.typeId"
        ></anms-plant-type-info>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ "iot.plantManager.plantList.Action" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-flat-button
          class="mr-2"
          matTooltip="Plant Role Assignment"
          (click)="plantRoleAssignment(element)"
        >
          <mat-icon>manage_accounts</mat-icon>
        </button>
        <button
          mat-flat-button
          class="mr-2"
          color="accent"
          (click)="onCreate(element)"
        >
          <mat-icon>mode</mat-icon
          >{{ "iot.plantManager.plantList.Edit" | translate }}
        </button>
        <button
          mat-flat-button
          color="accent"
          (click)="plantSelected.emit(element)"
        >
          {{ "iot.plantManager.plantList.Select" | translate }}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
