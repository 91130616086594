import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from "@angular/core";

import { ResourceRoleAssignment } from "../../model/resource-role-assignment";

@Component({
  selector: "iot-user-rolles-manager",
  templateUrl: "./user-rolles-manager.component.html",
  styleUrls: ["./user-rolles-manager.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRollesManagerComponent implements OnInit {
  @Input() userRole: ResourceRoleAssignment;
  constructor() {}

  ngOnInit(): void {}
}
