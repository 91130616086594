import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/internal/Observable";
import { tap, retry } from "rxjs/operators";
import { DeviceTemplateService } from "../../services/device-template.service";

@Component({
  selector: "iot-add-parameter-in-template",
  templateUrl: "./add-parameter-in-template.component.html",
  styleUrls: ["./add-parameter-in-template.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddParameterInTemplateComponent implements OnInit {
  message: string = "Add neue template";

  public template$: Observable<any>;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddParameterInTemplateComponent>,
    private toastrService: ToastrService,
    private devtempService: DeviceTemplateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  parametersForm: any;

  ngOnInit(): void {
    if (this.data.status === "edit") {
      this.parametersForm = this.formBuilder.group({
        key: [{ value: this.data.key, disabled: true }, [Validators.required]],
        default: [this.data.default, [Validators.required]]
      });
      this.message = this.data.message;
    }
    this.template$ = this.devtempService.getDeviceTemplate(this.data);
  }

  onSubmit() {
    if (this.parametersForm.valid) {
      if (this.data.status === "edit") {
        console.log("formValue", this.parametersForm.value);
        this.template$ = this.devtempService.updateTemplateParameter(
          this.data.templateId,
          this.data.parameterId,
          this.parametersForm.value
        );
        this.template$.subscribe(val =>
          console.log("sub.ValueTempvParam", val)
        );
        // this.template$ = this.devtempService.getDeviceTemplate(this.data.templateId);
        // this.dialogRef.close(this.template$)
      } else {
        this.template$ = this.devtempService
          .createTemplateParameter(this.data, this.parametersForm.value)
          .pipe(
            retry(3),
            tap(val =>
              this.toastrService.success(
                "Parameter has been successfully saved!"
              )
            )
          );
        this.template$.subscribe();
        this.parametersForm.reset();
      }
    } else {
      this.toastrService.warning("Please fill the fields");
    }
  }

  closeDialog() {
    this.template$ = this.devtempService.getDeviceTemplate(
      this.data.templateId
    );
    this.dialogRef.close(this.template$);
  }
}
