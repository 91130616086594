import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { PlantType } from '../../models/plant-type';
import { PlantTypeService } from '../../services/plant-type.service';
import { AddPlantTypeComponent } from '../add-plant-type/add-plant-type.component';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'iot-plant-type-manager',
  templateUrl: './plant-type-manager.component.html',
  styleUrls: ['./plant-type-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlantTypeManagerComponent implements OnInit {
  search = ""
  start
  count

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private planttypeService: PlantTypeService
  ) { }
  plantTypes$: Observable<PlantType[]>
  ngOnInit(): void {
    this.plantTypes$ = this.planttypeService.getPlantType(this.search, this.start, this.count)
  }
  getPlantTypes() {
    this.plantTypes$ = this.planttypeService.getPlantType(this.search, this.start, this.count)
  }

  addPlantType() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = "40%";
    dialogConfig.width = "50%";
    const dialogRef = this.dialog.open(AddPlantTypeComponent, dialogConfig)
    const subscribe: Subscription = dialogRef.afterClosed().pipe(tap(() => subscribe.unsubscribe)).subscribe(result => {
      this.getPlantTypes()
      this.changeDetectorRef.detectChanges()
    }
    )
  }
  editTypePlant(id) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = "40%"
    dialogConfig.width = "50%"
    dialogConfig.data = { id: id, status: "edit", message: "Edit Plant Type" }
    const dialogRef = this.dialog.open(AddPlantTypeComponent, dialogConfig)
    const subscribe: Subscription = dialogRef.afterClosed().pipe(tap(() => subscribe.unsubscribe)).subscribe(result => {
      this.getPlantTypes()
      this.changeDetectorRef.detectChanges()
    }
    )
  }

}

