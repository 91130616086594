import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'iot-device-copy-modal',
  templateUrl: './device-copy-modal.component.html',
  styleUrls: ['./device-copy-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceCopyModalComponent implements OnInit {
  form: FormGroup;

  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DeviceCopyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      count: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close(this.form.get('count').value);
  }
}
