<div style="margin-top: 40px;">
  <iot-search-user
    (userSearchResult)="userSearchResult($event)"
  ></iot-search-user>
</div>

<div style="margin-top: 20px;">
  <iot-select-tenant-role
    [tenantId]="tenantId"
    [scope]="scope"
  ></iot-select-tenant-role>
</div>

<mat-card class="border">
  <mat-card-subtitle>userId:{{ userId }}</mat-card-subtitle>
  <mat-card-subtitle>roleId:{{ roleId }}</mat-card-subtitle>
  <mat-card-subtitle>scope:{{ scope }}</mat-card-subtitle>
</mat-card>

<div div fxLayout="row" fxLayoutAlign="end center">
  <button
    class="mt-5"
    mat-flat-button
    color="primary"
    [disabled]="
      !(userId.length > 0) || !(roleId.length > 0) || !(scope.length > 0)
    "
    (click)="sendRoleAssignment()"
    *ngIf="!showSpinnerAfterSend"
  >
    {{ "iot.dashboard.shared.save" | translate }}
  </button>
  <div *ngIf="showSpinnerAfterSend" class="mt-5">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
