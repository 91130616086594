<div class="titleContainer">
    <h1 mat-dialog-title>{{message}}</h1> <button type="button" mat-button color="warn" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <form [formGroup]="plantTypeForm" class="form" (ngSubmit)="OnSubmit()">
        <div class="form-field">
            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input formControlName="key" matInput>
                <mat-error>
                    This field is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>IconURL</mat-label>
                <input formControlName="iconURI" matInput>
                <mat-error>
                    This field is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="submit">
            <button type="submit" mat-stroked-button color="primary">
                <mat-icon>done</mat-icon> Save
            </button>
        </div>
    </form>
</div>