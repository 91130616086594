import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { User } from "../model/user";
@Injectable({
  providedIn: "root"
})
export class GetUserInfoByIDService {
  api = environment.endpoints.usersId;
  constructor(private httpClient: HttpClient) {}

  getUserById(userId): Observable<User> {
    return this.httpClient.get<User>(this.api + userId);
  }
}
