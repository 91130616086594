<div class="container example-box" cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
    <div class="example-handle" cdkDragHandle>
        <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
            </path>
            <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
    </div>
    <form class="form-field" [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill">
            <mat-label>Signal type</mat-label>
            <mat-select formControlName="typeId" (selectionChange)="typeSelected($event)">
                <mat-option *ngFor="let type of signalTypes$ | async" [value]="type.id">
                    {{type.key}}
                </mat-option>
            </mat-select>
            <mat-error> Please select a signal type </mat-error>
        </mat-form-field>


        <mat-form-field>
            <mat-label>Friendly Name</mat-label>
            <input formControlName="friendlyName" matInput>
            <mat-error> please enter a friendly name </mat-error>
        </mat-form-field>


        <mat-form-field appearance="fill">
            <mat-label>Signal unit</mat-label>
            <mat-select formControlName="unitId">
                <mat-option *ngFor="let unit of units$ | async" [value]="unit.id">
                    {{unit.key}}
                </mat-option>
            </mat-select>
            <mat-error> Please select a signal unit </mat-error>
        </mat-form-field>
        <!-- <div *ngIf="data.signal !== undefined"> -->
            <div *ngFor="let item of arrayAttributes; index as i" class="signalAttributes border" >
                <anms-signal-attributes [data]="data" [signalAttributes]="item" [edit] = "edit"></anms-signal-attributes>
                <a mat-flat-button color="warn" (click)="deleteAttributes(i)">Delete</a>
            </div>
        <!-- </div> -->
        <div formArrayName="attributes">
            <div class="attrDiv" *ngFor="let attribute of attributesForms.controls; let i=index"
                [formGroupName]="i">
                <mat-form-field style="width: 45%;">
                    <mat-label>Key of attribute</mat-label>
                    <input matInput formControlName="key">
                </mat-form-field>
                <mat-form-field style="width: 45%;">
                    <mat-label>value</mat-label>
                    <input matInput matInput formControlName="value">
                </mat-form-field>
                <a button mat-mini-fab color="warn" matTooltip="Delete attribute" (click)="deleteAttributesfromarray(i)">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">delete_forever</mat-icon>
                </a>
            </div>
        </div>

    </form>


    <div class="divButtons">
        
        <mat-form-field appearance="fill">
            <mat-label>Add Attributtes</mat-label>
            <mat-select (selectionChange)="addAttributes($event)">
                <mat-option *ngFor="let module of modules" [value]="module" >
                {{module}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-fab color="primary" matTooltip="Save the Signal" type="submit" (click)="onSubmit()">
            Save
        </button>
        <button mat-fab color="warn" matTooltip="Save the Signal" (click)="closeSignal()">
            Close
        </button>
    </div>

</div>