<div>
    <div class="title">
        <h1 mat-dialog-title>Signal Type Manager </h1> 
        <div class="butons">
            <button mat-button color="primary" (click)="addSignalType()">
                <mat-icon>add</mat-icon>
            </button>
            <button mat-button color="warn" mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="signalType$ | async as signalTypes" mat-dialog-content>
        <mat-card class="m-2 mat-card" *ngFor="let item of signalTypes" class="border">
            <mat-card-header>
                <mat-card-title> Type: {{item.key}}</mat-card-title>
                <mat-card-subtitle> created at: {{item.created | date : 'short'}}</mat-card-subtitle>
            </mat-card-header>
            <div>
                <button color="accent" mat-button>
                    <mat-icon>mode</mat-icon>
                </button>
                <button color="warn" mat-button>
                    <mat-icon>delete</mat-icon>
                </button>
            </div>

        </mat-card>

    </div>