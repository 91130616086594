import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DeviceService } from '../../services/device.service';
import { retry, tap, takeUntil } from 'rxjs/operators';
import { DialogService } from '../../../../../core/dialogService/dialog.service';
import { Subject } from 'rxjs';
import { DeviceAttributesAddComponent } from '../device-attributes-add/device-attributes-add.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'iot-device-attributes-edit',
  templateUrl: './device-attributes-edit.component.html',
  styleUrls: ['./device-attributes-edit.component.scss'],
})
export class DeviceAttributesEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>()
  form: FormGroup;

  constructor(
    private toastrService: ToastrService,
    private dialogService: DialogService,
    private deviceService: DeviceService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialogRef: MatDialogRef<DeviceAttributesEditComponent>,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      attributes: this.formBuilder.array([])
    })
    this.data.deviceAttributes.forEach(element => {
      this.addAttributes(element.key, element.value)
    });
  }

  get attributes() {
    return this.form.get('attributes') as FormArray;
  }

  onSubmit() {
    console.log('value', this.form.value.attributes);
    let addedobject = {};
    var removed = [];
    var added = [];
    this.form.value.attributes.forEach(element => {
      console.log('elemt', element);
      if (!Object.keys(this.data.deviceAttributes).includes(element.key)) {
        added.push({ key: element.key, value: element.value });
        addedobject[element.key] = element.value;
      }
    });
    this.data.deviceAttributes.forEach(a => {
      if (!this.form.value.attributes.map(at => at.key).includes(a.key)) {
        removed.push(a.key);
      }
    });

    this.deviceService
      .editDeviceAttributes(this.data.deviceId, {
        added: addedobject,
        removed: removed
      })
      .subscribe(res => console.log('res', res));
  }

  addAttributes(key?, value?) {
    const attribute = this.formBuilder.group({
      key: [key, [Validators.required]],
      value: [value, [Validators.required]]
    });
    this.attributes.push(attribute);
  }

  deleteAttributes(i, key) {
    this.dialogService.openConfirmDialog().afterClosed().subscribe(
      result => {
        if (result) {
          this.deviceService.deleteDeviceAttributes(this.data.deviceId, key)
            .pipe(
              retry(3),
              takeUntil(this.destroy$)
            )
            .subscribe(() => {
              this.toastrService.warning("Object has been successfully deleted!")
              this.matDialogRef.close("true")
            })
          this.attributes.removeAt(i);
        }
      }
    )

  }

  openAddAttributes() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.height = "30%"
    dialogConfig.width = "40%"
    dialogConfig.data = { deviceId: this.data.deviceId }
    const matDialog = this.matDialog.open(DeviceAttributesAddComponent, dialogConfig)
    matDialog.afterClosed().pipe(
      takeUntil(this.destroy$)
    )
      .subscribe(
        result => {
          if (result) {
            this.matDialogRef.close("true")
          }
        }
      )
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
}
