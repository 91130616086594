import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';


@Component({
  selector: 'iot-mat-confirm-dialog',
  templateUrl: './mat-confirm-dialog.component.html',
  styleUrls: ['./mat-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatConfirmDialogComponent implements OnInit {

  constructor(
    private matDialogRef: MatDialogRef<MatConfirmDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  yes() {
    this.matDialogRef.close(true)
  }
  no() {
    this.matDialogRef.close()
  }

}
