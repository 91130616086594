import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DeviceTemplateService } from '../../shared-device/services/device-template.service';
@Component({
  selector: 'anms-device-manager',
  templateUrl: './device-manager.component.html',
  styleUrls: ['./device-manager.component.scss']
})
export class DeviceManagerComponent implements OnInit {
  public plantId$: Observable<string>;
  public tenantId$: Observable<string>;

  constructor(
    private route: ActivatedRoute,
    private deviceTempService: DeviceTemplateService
  ) {}

  ngOnInit(): void {
    this.tenantId$ = this.route.params.pipe(map(params => params.tenantid));
    this.plantId$ = this.route.params.pipe(map(params => params.plantid));
  }
  clickEvent(): void {
    var temp = {
      name: 'Moxa',
      typeId: '4d71cc55-b40d-4e20-beab-055b5ec6a83d',
      attributes: new Map<string, string>()
    };
    temp.attributes.set('a', 'b');
    this.deviceTempService.createTemplate(temp).subscribe();
  }
}
