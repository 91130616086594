import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "iot-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"]
})
export class SpinnerComponent implements OnInit, OnDestroy {
  myTimeout;
  changeMessage = true;
  message: string;
  constructor(
    private toastrService: ToastrService,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.myTimeout = setTimeout(() => this.myGreeting(), 10000);
  }

  myGreeting() {
    this.changeMessage = false;
    this.message = this.translateService.instant(
      "iot.dashboard.shared.waiting"
    );
  }

  ngOnDestroy() {
    clearTimeout(this.myTimeout);
  }
}
