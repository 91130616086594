import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Tenant } from '../../models/tenant';

import { TenantService } from '../../services/tenant.service';
import { TenantManagerComponent } from '../tenant-manager/tenant-manager.component';

@Component({
  selector: 'anms-tenant-selector',
  templateUrl: './tenant-selector.component.html',
  styleUrls: ['./tenant-selector.component.scss']
})
export class TenantSelectorComponent implements OnInit {
  @Input() tenantId: string;
  @Input() selectedTenant: Tenant;
  @Output() tenantSelected: EventEmitter<Tenant> = new EventEmitter<Tenant>();

  public tenants$: Observable<Tenant[]>;
  myControl = new FormControl();
  filteredtenants$: Observable<Tenant[]>;

  constructor(private tnntservice: TenantService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.tenants$ = this.tnntservice.getTenants();
    this.tenants$.subscribe();
    if (this.tenantId !== undefined && this.tenantId.length > 0) {
      this.tenants$.subscribe(
        val => (this.selectedTenant = val.find(t => t.id === this.tenantId))
      );
      if (this.selectedTenant !== null) {
        this.tenantSelected.emit(this.selectedTenant);
      }
    }
    // this.filteredtenants$ = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map((value) => (typeof value === 'string' ? value : value.name)),
    //   map((name) => (name ? this._filter(name) : this.tenants.slice()))
    // );
  }

  // private _filter(value: string): Tenant[] {
  //   const filterValue = value.toLowerCase();

  //   return this.tenants.filter((option) =>
  //     option.name.toLowerCase().includes(filterValue)
  //   );
  // }

  selectionChanged(a: any) {
    this.tenantId = a.id;
    this.tenants$.subscribe(val => {
      this.selectedTenant = val.find(t => t.id === this.tenantId);
      this.tenantSelected.emit(this.selectedTenant);
    });
  }

  openTenantManager(): void {
    const dialogRef = this.dialog.open(TenantManagerComponent, {
      width: '95%',
      height: '95%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('The dialog was closed', result);
        this.tenantSelected = result.tenant;
      }
    });
  }

  displayFn(tnnt: Tenant): string {
    return tnnt ? tnnt.name : '';
  }
}
