<div class="title">
    <h1 mat-dialog-title>Erro: {{statusText}}</h1>
    <button mat-dialog-close mat-button cdkFocusInitial=false color="warn">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <h3>Code: {{statusCode}}</h3>
    <h3>message: {{message}}</h3>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
        <button mat-button color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon> Reload
        </button>
</mat-dialog-actions>