import { Observable } from 'rxjs';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { DeviceType } from '../../models/device-type';
import { SignalService } from '../../services/signal.service';
import { DeviceService } from '../../services/device.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'iot-device-template-edit',
  templateUrl: './device-template-edit.component.html',
  styleUrls: ['./device-template-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceTemplateEditComponent implements OnInit {
  deviceTypes$: Observable<DeviceType[]>;
  deviceType$: Observable<DeviceType>;
  templateForm: FormGroup;
  myObject;

  modules = ['Modbus', 'Other'];
  modbusattributes = [
    'Modbus.UnitId',
    'Modbus.Port',
    'Modbus.DelayTime',
    'Modbus.MaxParallelRequests'
  ];

  constructor(
    private formBuilder: FormBuilder,
    private signalService: SignalService,
    private deviceService: DeviceService,
    private toastrService:ToastrService,
    private dialogRef: MatDialogRef<DeviceTemplateEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.deviceTypes$ = this.deviceService.getDeviceTypes();

    //key und value in object hinzugefügt
    this.myObject = this.data.templateAttributes;
    var ArryOfAttributes = Object.keys(this.myObject).map(key => ({
      key: key,
      value: this.myObject[key]
    }));

    this.templateForm = this.formBuilder.group({
      name: [this.data.templateName, Validators.required],
      typeId: [this.data.templateType, Validators.required],
      attributes: this.formBuilder.array([])
    });

    ArryOfAttributes.forEach(key => {
      this.addAttribute(key.key, key.value);
    });
  }

  get attributes() {
    return this.templateForm.get('attributes') as FormArray;
  }

  addAttribute(key?, value?) {
    const attribute = this.formBuilder.group({
      key: [key, [Validators.required]],
      value: [value, [Validators.required]]
    });
    this.attributes.push(attribute);
  }

  deleteAttributes(i) {
    this.attributes.removeAt(i);
  }

  addAttributes($event: any) {
    let array = [];
    switch ($event.value) {
      case 'Modbus':
        array = this.modbusattributes;
        break;
      default:
        this.addAttribute();
        break;
    }

    array.forEach(item => {
      this.addAttribute(item);
    });
  }

  OnSubmit() {
    //remove key and value worter von object
    let readyAttributes = this.templateForm.value.attributes;
    let jsonObject = {};
    readyAttributes.forEach(attr => {
      jsonObject[attr.key] = attr.value;
    });
    readyAttributes = jsonObject;
    this.templateForm.value.attributes = readyAttributes;
    if (this.templateForm.valid) {
      this.dialogRef.close(this.templateForm.value);
    } else {
      this.toastrService.warning('Please fill the fields');
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
