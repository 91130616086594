import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { userSearchResult } from '../models/tenant';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  getUserByEmail(searchTearm: any) {
    return this.httpClient.get<userSearchResult>(
      environment.endpoints.usersEmail + searchTearm
    );
  }
}
