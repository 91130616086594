import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Plants } from '../../shared-plant/models/plant';
import { Tenant } from '../../shared-tenant/models/tenant';

@Component({
  selector: 'anms-plant-manager',
  templateUrl: './plant-manager.component.html',
  styleUrls: ['./plant-manager.component.scss']
})
export class PlantManagerComponent implements OnInit {
  public selectedTenant: Tenant;
  public tenantId: string;
  create: boolean;

  private baseUrl: string;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.baseUrl = this.router.url;
    this.route.params.subscribe(params => {
      this.tenantId = params.id;
      this.baseUrl = this.baseUrl.replace('/' + this.tenantId, '');
    });
  }

  plantSelected(plant: Plants): void {
    this.router.navigate([
      '/plantmanager/devicemanager',
      this.selectedTenant.id,
      plant.id
    ]);
  }

  tenantSelected(tenant: Tenant): void {
    if (tenant) {
      this.selectedTenant = tenant;
      window.history.replaceState({}, '', this.baseUrl + '/' + tenant.id);
    }
  }
}
