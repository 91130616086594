import { Injectable, Injector, ErrorHandler } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from "@angular/common/http";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Observable, Subscription, throwError } from "rxjs";
import {
  catchError,
  concatAll,
  concatMap,
  map,
  switchMap,
  tap
} from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { ShowErrorComponent } from "../showErrorComponent/show-error/show-error.component";
import { MsalService } from "@azure/msal-angular";
import { UsersService } from "../../app/plantManager/shared-tenant/services/users.service";
import { AccessTokenService } from "../../app/dashboard/services/access-token.service";

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable({
  providedIn: "root"
})
export class HttpErrorInterceptor implements HttpInterceptor {
  public allowedUrls: string[];
  silentRequest;
  token = "";

  constructor(
    private injector: Injector,
    public dialog: MatDialog,
    private authService: MsalService,
    private accessTokenService: AccessTokenService
  ) {
    this.allowedUrls = Object.values(environment.endpoints);
    this.silentRequest = {
      scopes: environment.Azure_AD_B2C.scopes
    };
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.instance.getAllAccounts().length > 0) {
      this.authService.instance.setActiveAccount(
        this.authService.instance.getAllAccounts()[0]
      );
      return this.authService.acquireTokenSilent(this.silentRequest).pipe(
        map(result => result.accessToken),
        tap(accessToken => {
          this.accessTokenService.data.next(accessToken);
        }),
        switchMap(token => this.tokenizRequests(request, next, token))
      );
    } else {
      return this.accessTokenService.data.pipe(
        switchMap(token => this.tokenizRequests(request, next, token))
      );
    }
  }

  tokenizRequests(request: HttpRequest<any>, next: HttpHandler, token) {
    if (this.allowedUrls.findIndex(url => request.url.startsWith(url)) !== -1) {
      let tokenizedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ` + token
        }
      });
      return next.handle(tokenizedRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            // else if(error.status === 404){

            // }
            this.showErrorComponent(error);
            return throwError("");
          }
        })
      );
    } else {
      return next.handle(request).pipe(
        tap({
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) {
              const appErrorHandler = this.injector.get(ErrorHandler);
              appErrorHandler.handleError(err);
            }
          }
        })
      );
    }
  }

  showErrorComponent(error) {
    if ((error.status = 404)) {
      if (error.url.startsWith(environment.endpoints.usersEmail)) {
      } else {
        // const config = new MatDialogConfig();
        // config.height = '40%';
        // config.width = '60%';
        // config.data = error;
        // const dialog = this.dialog.open(ShowErrorComponent, config);
      }
    } else {
      const config = new MatDialogConfig();
      config.height = "40%";
      config.width = "60%";
      config.data = error;
      config.panelClass = "erro-dialog";
      const dialog = this.dialog.open(ShowErrorComponent, config);
    }
  }

  // if (true) {
  //   //  if (this.oidcSecurityService.getIdToken(this.configId) != null) {

  //   // const allowedUrls = Object.keys(environment.endpoints).map(key => environment.endpoints[key]);
  //   // allowedUrls.indexOf(request.url)

  //   // check if the call goes to an authenticated endpoint

  //   // nur dann Bearer Token, wenn
  //   // Url in environment steht &
  //   if (
  //     this.allowedUrls.findIndex(url => request.url.startsWith(url)) !== -1
  //   ) {

  //     console.log('Bearer: ', this.accessToken);
  //     let tokenizedRequest = request.clone({
  //       setHeaders: {
  //         Authorization: `Bearer ` + this.accessToken
  //       }
  //     });

  //     return next.handle(tokenizedRequest).pipe(
  //       // retry(3),
  //       catchError((error: HttpErrorResponse) => {
  //         if (error instanceof HttpErrorResponse) {
  //           // if(error.status === 400){

  //           // }
  //           // else if(error.status === 404){

  //           // }
  //           this.showErrorComponent(error);
  //           return throwError('Error');
  //         }
  //       })
  //     );
  //   } else {
  //     return next.handle(request).pipe(
  //       // retry(3),
  //       tap({
  //         error: (err: any) => {
  //           if (err instanceof HttpErrorResponse) {
  //             const appErrorHandler = this.injector.get(ErrorHandler);
  //             appErrorHandler.handleError(err);
  //           }
  //         }
  //       })
  //     );
  //   }
  // }
}
