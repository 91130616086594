<div class="container border mt-3">
  <ng-template class="mt-3 " #loading>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </ng-template>

  <mat-form-field *ngIf="tenants$ | async as filteredtenants; else loading">
    <input type="text" placeholder="Select a tenant" aria-label="Number" matInput [formControl]="myControl"
      [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)=selectionChanged($event.option.value)
      [displayWith]="displayFn">
      <mat-option *ngFor="let tenant of filteredtenants" [value]="tenant">
        {{tenant.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <button mat-button matTooltip="Add new tenant" (click)="openTenantManager()">
    <mat-icon>mode</mat-icon> Tenant Manager
  </button>

</div>