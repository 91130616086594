import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatChipsModule } from "@angular/material/chips";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCardModule } from "@angular/material/card";
import { MatListModule, MatListItem } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDividerModule } from "@angular/material/divider";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTreeModule } from "@angular/material/tree";
import { MatSliderModule } from "@angular/material/slider";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatExpansionModule } from "@angular/material/expansion";
import { DynamicModule } from "ng-dynamic-component";
import {
  FontAwesomeModule,
  FaIconLibrary
} from "@fortawesome/angular-fontawesome";
import {
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faCaretUp,
  faCaretDown,
  faExclamationTriangle,
  faFilter,
  faTasks,
  faCheck,
  faSquare,
  faLanguage,
  faPaintBrush,
  faLightbulb,
  faWindowMaximize,
  faStream,
  faBook
} from "@fortawesome/free-solid-svg-icons";
import { faMediumM, faGithub } from "@fortawesome/free-brands-svg-icons";

import { BigInputComponent } from "./big-input/big-input/big-input.component";
import { BigInputActionComponent } from "./big-input/big-input-action/big-input-action.component";
import { RtlSupportDirective } from "./rtl-support/rtl-support.directive";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ShowErrorComponent } from "../core/showErrorComponent/show-error/show-error.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatStepperModule } from "@angular/material/stepper";
import { FooterComponent } from "./footer/footer.component";
import { SearchUserComponent } from "../app/plantManager/shared-tenant/components/search-user/search-user.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { WarningComponent } from "./sambols/warning/warning.component";
import { AlarmComponent } from "./sambols/alarm/alarm.component";
import { AlarmInfoComponent } from "./sambols/alarm-info/alarm-info.component";
import { CriticalAlarmComponent } from "./sambols/critical-alarm/critical-alarm.component";
import { NormalComponent } from "./sambols/normal/normal.component";
import { StandbyComponent } from "./sambols/standby/standby.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,

    MatButtonModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCardModule,
    MatCheckboxModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatTabsModule,
    MatToolbarModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatDividerModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatDialogModule,
    MatCardModule,
    MatSelectModule,
    MatSidenavModule,
    MatTreeModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatSliderModule,
    FontAwesomeModule,
    MatGridListModule,
    OverlayModule,
    MatBottomSheetModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatExpansionModule,
    DragDropModule,
    MatAutocompleteModule,
    MatStepperModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    DynamicModule
  ],
  declarations: [
    BigInputComponent,
    BigInputActionComponent,
    RtlSupportDirective,
    ShowErrorComponent,
    SpinnerComponent,
    FooterComponent,
    SearchUserComponent,
    WarningComponent,
    AlarmComponent,
    AlarmInfoComponent,
    CriticalAlarmComponent,
    NormalComponent,
    StandbyComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    TranslateModule,

    // material
    MatButtonModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCardModule,
    MatCheckboxModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatTabsModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    MatDividerModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSelectModule,
    MatTreeModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatSliderModule,
    FontAwesomeModule,
    MatGridListModule,
    MatBottomSheetModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatStepperModule,
    OverlayModule,

    BigInputComponent,
    BigInputActionComponent,
    RtlSupportDirective,
    SpinnerComponent,
    DragDropModule,
    MatAutocompleteModule,

    FooterComponent,
    SearchUserComponent,
    FlexLayoutModule,
    DynamicModule,
    WarningComponent,
    AlarmComponent,
    AlarmInfoComponent,
    CriticalAlarmComponent,
    NormalComponent,
    StandbyComponent
  ]
})
export class SharedModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(
      faGithub,
      faMediumM,
      faPlus,
      faEdit,
      faTrash,
      faTimes,
      faCaretUp,
      faCaretDown,
      faExclamationTriangle,
      faFilter,
      faTasks,
      faCheck,
      faSquare,
      faLanguage,
      faPaintBrush,
      faLightbulb,
      faWindowMaximize,
      faStream,
      faBook
    );
  }
}
