<div class="container">
    <div class="title">
        <h2 mat-dialog-title>
            Edit Attributes in Device
        </h2>
        <button color="warn" mat-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form">
        <div formArrayName="attributes">
            <div *ngFor="let attribut of attributes.controls; let i=index" [formGroupName]="i">

                <mat-form-field appearance="outline">
                    <mat-label>Key</mat-label>
                    <input formControlName="key" matInput type="text">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Value</mat-label>
                    <input formControlName="value" matInput type="text">
                </mat-form-field>


                <button type="button" color="warn" mat-button matTooltip="Delete attribute"
                    (click)="deleteAttributes(i,attribut.value.key)">
                    <mat-icon style="font-size:30px;"  > delete_forever</mat-icon>
                </button>
            </div>
        </div>
    </form>



    <!-- <div formArrayName="attributes">
        <div *ngFor="let attribute of attributes.controls; let i=index" [formGroupName]="i">
            <mat-form-field appearance="outline" style="width: 45%;" class=" pr-1 ">
                <mat-label>Key of attribute</mat-label>
                <input matInput formControlName="key">
            </mat-form-field>

            <mat-form-field appearance="outline" style="width:45%;">
                <mat-label>value</mat-label>
                <input matInput matInput formControlName="value">
            </mat-form-field>


            <a button mat-mini-fab color="warn" matTooltip="Delete attribute" (click)="deleteAttributes(i)">
                <mat-icon aria-hidden="false" aria-label="Example home icon">delete_forever</mat-icon>
            </a>

        </div>
    </div> -->

    <div class="mb-3" mat-dialog-actions align="center">
        <button mat-button (click)="openAddAttributes()" color="primary">
            <mat-icon>add</mat-icon>Add Attributes
        </button>
        <button mat-flat-button (click)="onSubmit()" color="primary">
            <mat-icon>done</mat-icon>Save
        </button>
    </div>
</div>