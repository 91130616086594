import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DeviceTemplateListComponent } from "./components/device-template-list/device-template-list.component";
import { DeviceTemplateListViewComponent } from "./components/device-template-list-view/device-template-list-view.component";
import { DeviceTemplateInfoModalComponent } from "./components/device-template-info-modal/device-template-info-modal.component";
import { DeviceTemplateConfigurationDeviceComponent } from "./components/device-template-configuration-device/device-template-configuration-device.component";
import { DeviceListViewComponent } from "./components/device-list-view/device-list-view.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTreeModule } from "@angular/material/tree";
import { MatExpansionModule } from "@angular/material/expansion";
import { CdkTreeModule } from "@angular/cdk/tree";
import { MatListModule } from "@angular/material/list";
import { DeviceTemplateInfoComponent } from "./components/device-template-info/device-template-info.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SharedModule } from "../../../shared/shared.module";

import { DeviceTemplateCreationModalComponent } from "./components/device-template-creation-modal/device-template-creation-modal.component";
import { SignalAddComponent } from "./components/signal-add/signal-add.component";
import { SignalAttributesComponent } from "./components/signal-attributes/signal-attributes.component";
import { SignalEditComponent } from "./components/signal-edit/signal-edit.component";
import { ShowTypeAndUnitComponent } from "./components/show-type-and-unit/show-type-and-unit.component";
import { ShowTemplateTypeComponent } from "./components/show-template-type/show-template-type.component";
import { AddParameterInTemplateComponent } from "./components/add-parameter-in-template/add-parameter-in-template.component";
import { DeviceTemplateEditComponent } from "./components/device-template-edit/device-template-edit.component";
import { UnitsManagerComponent } from "./components/units-manager/units-manager.component";
import { UnitsAddComponent } from "./components/units-add/units-add.component";
import { SignalTypeManagerComponent } from "./components/signal-type-manager/signal-type-manager.component";
import { DeviceManagerComponent } from "./components/device-manager/device-manager.component";
import { ShowSignalsInDeviceComponent } from "./components/show-signals-in-device/show-signals-in-device.component";
import { DeviceEditComponent } from "./components/device-edit/device-edit.component";
import { DeviceAttributesEditComponent } from "./components/device-attributes-edit/device-attributes-edit.component";
import { DeviceAttributesShowComponent } from "./components/device-attributes-show/device-attributes-show.component";
import { DeviceSignalsAddEditComponent } from "./components/device-signals-add-edit/device-signals-add-edit.component";
import { DeviceSignalsEditComponent } from "./components/device-signals-edit/device-signals-edit.component";
import { DeviceSignalAttributesAddComponent } from "./components/device-signal-attributes-add/device-signal-attributes-add.component";
import { DeviceAttributesAddComponent } from "./components/device-attributes-add/device-attributes-add.component";
import { DeviceCopyModalComponent } from "./components/device-copy-modal/device-copy-modal.component";

@NgModule({
  declarations: [
    DeviceTemplateListComponent,
    DeviceTemplateListViewComponent,
    DeviceTemplateInfoModalComponent,
    DeviceTemplateConfigurationDeviceComponent,
    DeviceListViewComponent,
    DeviceTemplateInfoComponent,
    DeviceTemplateCreationModalComponent,
    SignalAddComponent,
    SignalAttributesComponent,
    SignalEditComponent,
    ShowTypeAndUnitComponent,
    ShowTemplateTypeComponent,
    AddParameterInTemplateComponent,
    DeviceTemplateEditComponent,
    UnitsManagerComponent,
    UnitsAddComponent,
    SignalTypeManagerComponent,
    DeviceManagerComponent,
    ShowSignalsInDeviceComponent,
    DeviceEditComponent,
    DeviceAttributesEditComponent,
    DeviceAttributesShowComponent,
    DeviceSignalsAddEditComponent,
    DeviceSignalsEditComponent,
    DeviceSignalAttributesAddComponent,
    DeviceAttributesAddComponent,
    DeviceCopyModalComponent
  ],
  imports: [
    SharedModule,
    CdkTreeModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatInputModule,
    MatAutocompleteModule,
    CommonModule,
    MatTreeModule,
    MatExpansionModule,
    MatButtonModule,
    MatListModule,
    DragDropModule
  ],
  exports: [DeviceListViewComponent]
})
export class SharedDeviceModule {}
