// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const packageJson = require("../../../../package.json");
const apiUrl =
  "https://configapi-dev-eu.happytree-dc3c9147.westeurope.azurecontainerapps.io";
const clientId = "dee612dc-d7d8-4cd1-8e3a-8f9c3e7157d5";

export const environment = {
  appName: "Sunbelt Hybrid Monitoring DEV",
  apiUrl: apiUrl,

  smalogo:
    "https://hymonwebstore.blob.core.windows.net/plant/sma-logo/Logo_SMA.png",
  envName: "DEV",
  production: false,
  test: false,
  i18nPrefix: "",
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies["@angular/core"],
    ngrx: packageJson.dependencies["@ngrx/store"],
    material: packageJson.dependencies["@angular/material"],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies["@ngx-translate/core"],
    fontAwesome: packageJson.dependencies["@fortawesome/fontawesome-free"],
    angularCli: packageJson.devDependencies["@angular/cli"],
    typescript: packageJson.devDependencies["typescript"],
    cypress: packageJson.devDependencies["cypress"]
  },
  endpoints: {
    liveDataUrl:
      "https://liveapi-dev-eu.happytree-dc3c9147.westeurope.azurecontainerapps.io",
    attributeGroups: apiUrl + "/api/AttributeGroups",
    contractFeatureType: apiUrl + "/api/ContractFeatureType",
    dashboards: apiUrl + "/api/Dashboards",
    dashboardWidget: apiUrl + "/api/DashboardWidgets",
    dashboardWidgetSignals: apiUrl + "/api/DashboardWidgetSignals",
    dashboardWidgetAttributes: apiUrl + "/api/DashboardWidgetAttributes",
    dashboardWidgetSignalAttributes:
      apiUrl + "/api/DashboardWidgetSignalAttributes",
    deviceAttributes: apiUrl + "/api/DeviceAttributes",
    deviceFlags: apiUrl + "/api/DeviceFlags",
    devices: apiUrl + "/api/Devices",
    deviceTemplateChilds: apiUrl + "/api/DeviceTemplateChilds",
    deviceTemplates: apiUrl + "/api/DeviceTemplates",
    deviceTemplateSignals: apiUrl + "/api/DeviceTemplateSignals",
    DeviceTemplateSignalAttributes:
      apiUrl + "/api/DeviceTemplateSignalAttributes",
    deviceTypes: apiUrl + "/api/DeviceTypes",
    deviceTemplateParameters: apiUrl + "/api/DeviceTemplateParameters",
    plantAttributes: apiUrl + "/api/PlantAttributes",
    plantFlags: apiUrl + "/api/PlantFlags",
    plants: apiUrl + "/api/Plants",
    plantTypes: apiUrl + "/api/PlantTypes",
    signalAttributes: apiUrl + "/api/SignalAttributes",
    signals: apiUrl + "/api/Signals",
    signalTypes: apiUrl + "/api/SignalTypes",
    tenantRoles: apiUrl + "/api/TenantRoles/",
    tenants: apiUrl + "/api/Tenants",
    timezones: apiUrl + "/api/Timezones",
    units: apiUrl + "/api/Units",
    unitTransformations: apiUrl + "/api/UnitTransformations",
    usersId: apiUrl + "/api/Users/",
    usersEmail: apiUrl + "/api/Users/email/",
    userRoleAssignment: apiUrl + "/api/UserRoleAssignments",
    resourceRoleAssignments: apiUrl + "/api/ResourceRoleAssignments"
  },
  appInsights: {
    instrumentationKey: "dd94b7a4-96bd-467d-aa39-139b1c2fbed4"
  },
  Azure_AD_B2C: {
    ClientId: clientId,
    scopes: ["openid", clientId],
    b2cPolicies: {
      names: {
        signUpSignIn: "B2C_1_HYMON_DEV",
        editProfile: "B2C_1_HYMON_PROFILE"
      },
      authorities: {
        signUpSignIn: {
          authority:
            "https://smab2c.b2clogin.com/smab2c.onmicrosoft.com/B2C_1_HYMON_DEV"
        },
        editProfile: {
          authority:
            "https://smab2c.b2clogin.com/smab2c.onmicrosoft.com/B2C_1_HYMON_PROFILE"
        }
      },
      authorityDomain: "smab2c.b2clogin.com"
    }
  }
};
