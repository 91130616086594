import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { HyperMediaResponse } from "../../models/hyperMediaResponse";
import { Signal } from "../../../../shared-signals/models/signal";
import { Injectable } from "@angular/core";
import { Observable, queueScheduler, scheduled } from "rxjs";
import { concatAll, map } from "rxjs/operators";
import { SignalType } from "../models/signal-type";
import { Unit } from "../models/unit";
import { DeviceTemplateFE } from "../models/device-template-fe";
import { DeviceTemplate } from "../models/device-template";
import { SignalAttributes } from "../models/signalAttribute";
@Injectable({
  providedIn: "root"
})
export class SignalService {
  constructor(private http: HttpClient) {}

  getSignals(tenantId, deviceId, plantId): Observable<any> {
    let myObject = {
      tenantId: tenantId,
      deviceId: deviceId,
      plantId: plantId,
      start: "0",
      count: "100"
    };
    return this.http
      .get<any>(environment.endpoints.signals, { params: myObject })
      .pipe(map(result => result.resources));
  }
  getSignalById(signalId): Observable<any> {
    return this.http.get<any>(environment.endpoints.signals + "/" + signalId);
  }

  createSignal(devId: string, signal: any) {
    return this.http.post<Signal>(environment.apiUrl + "/api/Signals", {
      deviceId: devId,
      friendlyName: signal.friendlyName,
      index: 0,
      typeId: signal.typeId,
      key: signal.key,
      unitId: signal.unitId
    });
  }

  createNewSignal(signal: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + "/api/Signals", signal);
  }
  updateSignal(signalId: string, signal): Observable<any> {
    return this.http.patch<any>(
      environment.endpoints.signals + "/" + signalId,
      signal
    );
  }

  deleteSignal(signalId: string): Observable<any> {
    return this.http.delete<any>(
      environment.endpoints.signals + "/" + signalId
    );
  }

  createUnit(unit: any) {
    return this.http.post<Unit>(environment.apiUrl + "/api/Units", unit);
  }

  createSignalType(stype: any) {
    return this.http.post<SignalType>(
      environment.apiUrl + "/api/SignalTypes",
      stype
    );
  }

  deleteTemplateSignal(templateid: string, signalid: string) {
    return this.http.delete<DeviceTemplateFE>(
      environment.apiUrl +
        "/api/DeviceTemplateSignals/" +
        templateid +
        "/" +
        signalid
    );
  }

  createTemplateSignal(id: string, signal: any) {
    // console.log('signalId', id);
    // console.log('signal', signal);
    let jsonObject = {};
    signal.attributes.forEach(attr => {
      jsonObject[attr.key] = attr.value;
    });
    signal.attributes = jsonObject;
    return this.http.post<DeviceTemplate>(
      environment.apiUrl + "/api/DeviceTemplateSignals/" + id,
      signal
    );
  }

  editTemplateSignal(id: string, signalId: string, signal: any) {
    // console.log('myEditsignal', signal.attributes);
    // let jsonObject = {};
    // signal.attributes.forEach(attr => {
    //   jsonObject[attr.key] = attr.value;
    // });
    // signal.attributes = jsonObject;
    //let localResult: { tpl: DeviceTemplate; toDelete: string[]; toCreate: string[]; toUpdate: string[]; };
    return this.http.patch<DeviceTemplate>(
      environment.apiUrl + "/api/DeviceTemplateSignals/" + id + "/" + signalId,
      signal
    );
    // .pipe(map(val => console.log('valFromMap', val)));
  }

  createTemplateSignalAttribute(
    templateId: string,
    signalId: string,
    key: string,
    value: string
  ) {
    return this.http.post<DeviceTemplate>(
      environment.apiUrl +
        "/api/DeviceTemplateSignalAttributes/" +
        templateId +
        "/" +
        signalId,
      { key: key, value: value }
    );
  }

  createTemplateSignalAttributes(
    templateId: string,
    signalId: string,
    attributes: any
  ) {
    return scheduled(
      Object.keys(attributes).map(key =>
        this.createTemplateSignalAttribute(
          templateId,
          signalId,
          key,
          attributes[key]
        )
      ),
      queueScheduler
    ).pipe(concatAll());
  }

  editTemplateSignalattributes(
    id: string,
    signalId: string,
    signalKey: string,
    signal: any
  ) {
    return this.http.patch<DeviceTemplate>(
      environment.apiUrl +
        "/api/DeviceTemplateSignalAttributes/" +
        id +
        "/" +
        signalId +
        "/" +
        signalKey,
      { value: signal }
    );
  }

  removeTemplateSignalattributes(
    id: string,
    signalId: string,
    signalKey: string
    // signal: any
  ) {
    return this.http.delete<DeviceTemplate>(
      environment.apiUrl +
        "/api/DeviceTemplateSignalAttributes/" +
        id +
        "/" +
        signalId +
        "/" +
        signalKey
      // signal
    );
  }

  createSignalAttribute(signalId: string, key: string, value: string) {
    let jsonObject = {};
    jsonObject["key"] = key;
    jsonObject["value"] = value;
    return this.http.post<HyperMediaResponse<any>>(
      environment.apiUrl + "/api/SignalAttributes" + "/" + signalId,
      jsonObject
    );
  }

  createSignalAttributes(signalId: string, attributes: any) {
    return scheduled(
      Object.keys(attributes).map(key =>
        this.createSignalAttribute(signalId, key, attributes[key])
      ),
      queueScheduler
    ).pipe(concatAll());
  }

  updateSignalAttribute(signalId: string, key: string, value: string) {
    let jsonObject = {};
    jsonObject["value"] = value;
    return this.http.patch<HyperMediaResponse<any>>(
      environment.apiUrl + "/api/SignalAttributes" + "/" + signalId + "/" + key,
      jsonObject
    );
  }
  updateSignalAttributes(signalId: string, attributes: any) {
    return scheduled(
      Object.keys(attributes).map(key =>
        this.updateSignalAttribute(signalId, key, attributes[key])
      ),
      queueScheduler
    ).pipe(concatAll());
  }

  deleteSignalAttributes(signalid, key): Observable<any> {
    return this.http
      .delete<any>(
        environment.endpoints.signalAttributes + "/" + signalid + "/" + key
      )
      .pipe(map(res => res.resources));
  }
  // editTemplateSignal(id: string, signalId: string, signal: any) {
  //   let jsonObject = {};
  //   signal.attributes.forEach(attr => {
  //     jsonObject[attr.key] = attr.value;
  //   });
  //   signal.attributes = jsonObject;
  //   //let localResult: { tpl: DeviceTemplate; toDelete: string[]; toCreate: string[]; toUpdate: string[]; };
  //   return this.http.patch<DeviceTemplate>(
  //     environment.apiUrl + '/api/DeviceTemplateSignals/' + id + '/' + signalId,
  //     signal
  //   );
  //   // .pipe(
  //   //   map(result => ({tpl: result, attributes: result.signals.find(sig => sig.id === signalId)?.attributes})),
  //   //   tap(result => console.log("result1",result)),
  //   //   map(result => ({
  //   //     tpl: result.tpl,
  //   //     toDelete: Object.keys(result.attributes).filter(key => !Object.keys(jsonObject).includes(key)),
  //   //     toCreate: Object.keys(jsonObject).filter(key => !Object.keys(result.attributes).includes(key)),
  //   //     toUpdate: Object.keys(jsonObject).filter(key => result.attributes[key] !== jsonObject[key])
  //   //   })),
  //   //   tap(result => console.log("result2",result)),
  //   //   tap(result => localResult = result),
  //   //   mergeMap(result => result.toDelete.map(key => this.http.delete<DeviceTemplate>(environment.apiUrl + '/api/DeviceTemplateSignalAttributes/'+id+'/'+signalId+'/'+key))),
  //   //   // switchMap(result => result),
  //   //   tap(result => console.log("result3",result)),
  //   //   mergeMap(result => localResult.toCreate.map(key => this.http.post<DeviceTemplate>(environment.apiUrl + '/api/DeviceTemplateSignalAttributes/'+id+'/'+signalId, { key: key, value: jsonObject[key] }))),
  //   //   // switchMap(result => result),
  //   //   tap(result => console.log(result)),
  //   //   mergeMap(result => localResult.toUpdate.map(key => this.http.patch<DeviceTemplate>(environment.apiUrl + '/api/DeviceTemplateSignalAttributes/'+id+'/'+signalId+'/'+key, { value: jsonObject[key] }))),
  //   //   // switchMap(result => result),
  //   //   tap(result => console.log("result4",result)),
  //   //   map(result => result ?? localResult.tpl),
  //   //   tap(result => console.log(result))
  //   // );
  // }

  getSignalTypes(): Observable<SignalType[]> {
    let searchParams: { [key: string]: string } = {
      start: "0",
      count: "50"
    };
    return this.http
      .get<HyperMediaResponse<SignalType>>(
        environment.apiUrl + "/api/SignalTypes",
        { params: searchParams }
      )
      .pipe(map(d => d.resources));
  }

  getSignalType(id: string): Observable<SignalType> {
    let searchParams: { [key: string]: string } = {
      start: "0",
      count: "50"
    };
    return this.http.get<SignalType>(
      environment.apiUrl + "/api/SignalTypes/" + id,
      { params: searchParams }
    );
  }

  getUnits(): Observable<Unit[]> {
    let searchParams: { [key: string]: string } = {
      start: "0",
      count: "50"
    };
    return this.http
      .get<HyperMediaResponse<Unit>>(environment.apiUrl + "/api/Units", {
        params: searchParams
      })
      .pipe(map(d => d.resources));
  }

  getUnit(id: string): Observable<Unit> {
    return this.http.get<Unit>(environment.apiUrl + "/api/Units/" + id);
  }
}
