import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnDestroy
} from "@angular/core";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { DeviceService } from "../../services/device.service";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { DeviceTemplateService } from "../../services/device-template.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DeviceTemplateConfigurationDeviceComponent } from "../device-template-configuration-device/device-template-configuration-device.component";
import { DeviceFE } from "../../models/device-fe";
import { SignalService } from "../../services/signal.service";

import { Subscription } from "rxjs";
import { DeviceManagerComponent } from "../device-manager/device-manager.component";
import { DeviceCopyModalComponent } from "../device-copy-modal/device-copy-modal.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "anms-device-list-view",
  templateUrl: "./device-list-view.component.html",
  styleUrls: ["./device-list-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceListViewComponent implements OnInit, OnDestroy {
  @Input() public plantId: string;
  @Input() public tenantId: string;

  public treeControl = new NestedTreeControl<DeviceFE>(
    node => node.childDevices
  );
  public dataSource = new MatTreeNestedDataSource<DeviceFE>();
  private subscription: Subscription = new Subscription();
  constructor(
    private deviceService: DeviceService,
    private deviceTemplateService: DeviceTemplateService,
    private signalService: SignalService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private toastrService: ToastrService
  ) {}

  drop(event: CdkDragDrop<string[]>) {
    const dialogRef = this.dialog.open(
      DeviceTemplateConfigurationDeviceComponent,
      {
        width: "80%",
        data: { template: event.item.data, devices: this.dataSource.data },
        disableClose: true
      }
    );
    this.subscription.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          result.newdevice.plantId = this.plantId;
          result.newdevice.tenantId = this.tenantId;

          this.subscription.add(
            this.deviceService
              .createDevice({
                friendlyName: result.newdevice.friendlyName,
                typeId: result.newdevice.typeId,
                tenantId: result.newdevice.tenantId,
                plantId: result.newdevice.plantId,
                parentDeviceId: result.newdevice.parentDeviceId,
                flags: new Map<string, Boolean>(),
                attributes: new Map<string, string>()
              }) //.pipe(delay(10000))
              .subscribe(dev => {
                this.subscription.add(
                  this.deviceService.getDevice(dev.id).subscribe()
                );
                result.newdevice.signals.forEach(element => {
                  this.subscription.add(
                    this.signalService
                      .getSignalType(element.typeId)
                      .subscribe(type =>
                        this.signalService
                          .createSignal(dev.id, {
                            typeId: element.typeId,
                            friendlyName: element.friendlyName,
                            unitId: element.unitId,
                            key: type.key
                          })
                          .subscribe(signal =>
                            this.subscription.add(
                              this.signalService
                                .createSignalAttributes(
                                  signal.id,
                                  element.attributes
                                )
                                .subscribe(val => {})
                            )
                          )
                      )
                  );
                });
                this.subscription.add(
                  this.deviceService
                    .addAttributes(
                      dev.id,
                      new Map([
                        ...result.newdevice.attributes,
                        ...new Map(
                          result.newdevice.parameters.map(key => [
                            key.key,
                            key.default
                          ])
                        )
                      ])
                    )
                    .subscribe(val => {
                      this.subscription.add(
                        this.deviceService
                          .getDevices(this.plantId, this.tenantId)
                          .subscribe(val => {
                            this.dataSource.data = null;
                            this.dataSource.data = val.filter(
                              v => v.parentDeviceId == null
                            );
                          })
                      );
                    })
                );

                this.subscription.add(
                  this.deviceService
                    .getDevices(this.plantId, this.tenantId)
                    .subscribe(val => {
                      this.dataSource.data = null;
                      this.dataSource.data = val.filter(
                        v => v.parentDeviceId == null
                      );
                    })
                );
              })
          );
        }
      })
    );
  }

  hasChild = (_: number, node: DeviceFE) =>
    !!node.childDevices && node.childDevices.length > 0;

  ngOnInit(): void {
    this.subscription.add(
      this.deviceService
        .getDevices(this.plantId, this.tenantId)
        .subscribe(
          val =>
            (this.dataSource.data = val.filter(v => v.parentDeviceId == null))
        )
    );
  }

  getDevices() {
    this.subscription.add(
      this.deviceService
        .getDevices(this.plantId, this.tenantId)
        .subscribe(
          val =>
            (this.dataSource.data = val.filter(v => v.parentDeviceId == null))
        )
    );
  }

  openDeviceManager(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;
    dialogConfig.height = "85%";
    dialogConfig.width = "70%";
    dialogConfig.disableClose = false;
    const matDialogRef = this.dialog.open(DeviceManagerComponent, dialogConfig);
    this.subscription.add(
      matDialogRef.afterClosed().subscribe(result => {
        this.getDevices();
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  copyRack(data) {
    const matDialogConfig = new MatDialogConfig();

    if (
      Object.keys(data.attributes).includes("DeviceNumber") &&
      (Object.keys(data.attributes).includes("Modbus.DeviceOffset") ||
        Object.keys(data.attributes).includes("ModbusServer.DeviceOffset"))
    ) {
      const dialog = this.matDialog.open(
        DeviceCopyModalComponent,
        matDialogConfig
      );

      dialog.afterClosed().subscribe(result => {
        var racks = [];

        for (let index = 0; index < result; index++) {
          racks.push({
            friendlyName: data.friendlyName,
            typeId: data.typeId,
            tenantId: data.tenantId,
            plantId: data.plantId,
            parentDeviceId: data.parentDeviceId,
            flags: data.flags,
            attributes: {
              ...data.attributes,
              ...{
                DeviceNumber: (index + 2).toString()
              }
            },
            childDevices:
              data.childDevices != undefined
                ? this.copyChild(data.childDevices)
                : []
          });
        }
        this.subscription.add(
          this.deviceService.createDevices(racks).subscribe(dev => {
            this.subscription.add(
              this.signalService
                .getSignals(dev.tenantId, data.id, dev.plantId)
                .subscribe(signals => {
                  signals.forEach(element => {
                    this.subscription.add(
                      this.signalService
                        .createSignal(dev.id, {
                          typeId: element.typeId,
                          friendlyName: element.friendlyName,
                          unitId: element.unitId,
                          key: element.key
                        })
                        .subscribe(signal =>
                          this.subscription.add(
                            this.signalService
                              .createSignalAttributes(
                                signal.id,
                                element.attributes
                              )
                              .subscribe(val => {})
                          )
                        )
                    );
                  });
                })
            );
          })
        );
      });
    } else {
      this.toastrService.error(
        "Device lacks the informations such as DeviceNumber and DeviceOffset"
      );
    }

    // var numbertoadd = 1;

    // var devicestoadd = [];

    // //this.deviceService.getDevices(data.plantId, data.tenantId);

    // for (let index = 0; index < numbertoadd; index++) {
    //   devicestoadd.push({
    //     friendlyName: data.friendlyName,
    //     typeId: data.typeId,
    //     tenantId: data.tenantId,
    //     plantId: data.plantId,
    //     parentDeviceId: data.parentDeviceId,
    //     flags: data.flags,
    //     attributes: {
    //       ...data.attributes,
    //       ...{
    //         : (
    //           parseInt(data.attributes['DeviceNumber']) +
    //           index +
    //           1
    //         ).toString()
    //       }
    //     },
    //     childDevices:
    //       data.childDevices != undefined
    //         ? this.copyChild(data.childDevices)
    //         : []
    //   });
    // }

    // this.subscription.add(
    //   this.deviceService
    //     .createDevices(devicestoadd) //.pipe(delay(10000))
    //     .subscribe(dev => {
    //       console.log(dev);
    //       result.newdevice.signals.forEach(element => {
    //         this.subscription.add(
    //           this.signalService.getSignalType(element.typeId).subscribe(type =>
    //             this.signalService
    //               .createSignal(dev.id, {
    //                 typeId: element.typeId,
    //                 friendlyName: element.friendlyName,
    //                 unitId: element.unitId,
    //                 key: type.key
    //               })
    //               .subscribe(signal =>
    //                 this.subscription.add(
    //                   this.signalService
    //                     .createSignalAttributes(signal.id, element.attributes)
    //                     .subscribe(val => {})
    //                 )
    //               )
    //           )
    //         );
    //       });
    //       this.subscription.add(
    //         this.deviceService
    //           .addAttributes(
    //             dev.id,
    //             new Map([
    //               ...result.newdevice.attributes,
    //               ...new Map(
    //                 result.newdevice.parameters.map(key => [
    //                   key.key,
    //                   key.default
    //                 ])
    //               )
    //             ])
    //           )
    //           .subscribe(val => {
    //             this.subscription.add(
    //               this.deviceService
    //                 .getDevices(this.plantId, this.tenantId)
    //                 .subscribe(val => {
    //                   this.dataSource.data = null;
    //                   this.dataSource.data = val.filter(
    //                     v => v.parentDeviceId == null
    //                   );
    //                 })
    //             );
    //           })
    //       );

    //       this.subscription.add(
    //         this.deviceService
    //           .getDevices(this.plantId, this.tenantId)
    //           .subscribe(val => {
    //             this.dataSource.data = null;
    //             this.dataSource.data = val.filter(
    //               v => v.parentDeviceId == null
    //             );
    //           })
    //       );
    //     })
    // );

    // console.log(devicestoadd);
  }

  copyChild(devices): any {
    return devices.map(device => {
      return {
        friendlyName: device.friendlyName,
        typeId: device.typeId,
        tenantId: device.tenantId,
        plantId: device.plantId,
        parentDeviceId: device.parentDeviceId,
        flags: device.flags,
        attributes: device.attributes,
        childDevices: this.copyChild(device.childDevices)
      };
    });
  }

  removeDevice(data) {}
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
