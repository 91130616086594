import { Component, OnInit, ChangeDetectionStrategy, Injector, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'iot-show-error',
  templateUrl: './show-error.component.html',
  styleUrls: ['./show-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowErrorComponent implements OnInit {
  statusCode = this.data.status
  statusText = this.data.statusText
  message = this.data.message

  constructor(
    public dialogRef: MatDialogRef<ShowErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {

  }
  refresh() {
    location.reload();
  }

}
