import { PlantTypeManagerComponent } from "./../plant-type-manager/plant-type-manager.component";
import { PlantTypeService } from "./../../services/plant-type.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PlantAddComponent } from "../plant-add/plant-add.component";
import { Plants } from "../../models/plant";
import { PlantService } from "../../services/plant.service";
import { IAMComponent } from "../../../../IAM/components/iam/iam.component";
import { UserPermissionsAndRolesService } from "../../../../../shared/user-permissions-and-roles.service";
@Component({
  selector: "anms-plant-list-view",
  templateUrl: "./plant-list-view.component.html",
  styleUrls: ["./plant-list-view.component.scss"]
})
export class PlantListViewComponent implements OnInit, AfterViewInit {
  showDeleteMessage: boolean = false;
  searchText: string = "";
  [x: string]: any;
  allowCreatePlant: boolean = false;
  allowRead: boolean = false;
  allowDelete: boolean = false;
  allowUserRead: boolean = false;
  displayedColumns: string[] = ["name", "createdAt", "plantType", "actions"];

  constructor(
    public plantservice: PlantService,
    public plantTypeservice: PlantTypeService,
    public dialog: MatDialog,
    public matDialog: MatDialog,
    public userPermissionsAndRolesService: UserPermissionsAndRolesService
  ) {}

  @Input() tenantId;
  @Input() plantArray = [];
  @Input() Plants$ = [];
  @Output() public refreshRequested = new EventEmitter<any>();
  @Output() public plantSelected = new EventEmitter<Plants>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.Plants$);
    this.allowCreate = this.userPermissionsAndRolesService.permissions;
    console.log("allow", this.userPermissionsAndRolesService.permissions);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onCreate(plant?: Plants) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = { plant: plant, tenantId: this.tenantId };
    const dialog = this.dialog.open(PlantAddComponent, dialogConfig);
    dialog.afterClosed().subscribe(result => {
      this.refreshRequested.emit();
    });
  }

  selectedPlant(plant: Plants): void {
    this.plantSelected.emit(plant);
  }

  plantTypeManager() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "70%";
    dialogConfig.width = "70%";
    const dialog = this.dialog.open(PlantTypeManagerComponent, dialogConfig);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  plantRoleAssignment(element) {
    const config = new MatDialogConfig();
    config.height = "80%";
    config.width = "90%";
    config.data = {
      tenantId: element.tenantId,
      scope: element.resourceId,
      title: element.name
    };

    const matDialog = this.matDialog.open(IAMComponent, config);
    matDialog.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}
