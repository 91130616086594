import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { TenantRolesService } from "../../../plantManager/shared-tenant/services/tenant-roles.service";
import { UserRoleAssignments } from "../../model/user-role-assignments";

import { UserRoleAssignmentService } from "../../services/user-role-assignment.service";

@Component({
  selector: "iot-select-tenant-role",
  templateUrl: "./select-tenant-role.component.html",
  styleUrls: ["./select-tenant-role.component.scss"]
})
export class SelectTenantRoleComponent implements OnInit, OnChanges {
  destroy$: Subject<boolean> = new Subject();
  @Input() tenantId = "";
  @Input() scope = "";
  @Output() userRoleEmitter = new EventEmitter<UserRoleAssignments>();
  tenantRoles$;
  roleId = "";
  allowedScopes = [];
  showSpinner = false;
  selectedAllowedScope = [];

  constructor(
    private tenantRolesService: TenantRolesService,
    private userRoleAssignmentService: UserRoleAssignmentService
  ) {}

  ngOnInit(): void {}

  selectedRoles(role) {
    this.userRoleAssignmentService.updatedroleId(role.id);

    if (!this.scope) {
      this.allowedScopes = role.allowedScopes;
    }
  }
  allowedScopesChange($event) {
    this.userRoleAssignmentService.updateScope($event.value);
  }

  ngOnChanges() {
    if (this.tenantId) {
      this.showSpinner = true;
      this.tenantRoles$ = this.tenantRolesService
        .getTenantRoles(this.tenantId)
        .pipe(
          tap(tape => {
            this.showSpinner = false;
          })
        );
    }
  }
}
