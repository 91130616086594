<div [class]="'theme-wrapper ' + (theme$ | async)">
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="push">
      <div class="branding">
        <img [src]="logo" />
        <span>{{ "iot.title.short" | translate }}</span>
      </div>
      <mat-nav-list>
        <a
          mat-list-item
          *ngFor="let item of navigationSideMenu"
          (click)="sidenav.close()"
          [routerLink]="[item.link]"
          routerLinkActive="active"
        >
          {{ item.label | translate }}
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="mat-drawer-content">
      <div class="wrapper">
        <div
          class="toolbar"
          [style.position]="(stickyHeader$ | async) ? 'fixed' : 'inherit'"
          [class.mat-elevation-z4]="stickyHeader$ | async"
        >
          <mat-toolbar color="primary">
            <button mat-icon-button class="d-md-none" (click)="sidenav.open()">
              <fa-icon icon="bars"></fa-icon>
            </button>
            <span
              routerLink=""
              class=" branding spacer center d-inline d-sm-none"
            >
              <img [src]="logo"
            /></span>
            <span
              routerLink=""
              style="cursor: pointer;"
              class="branding spacer center d-none d-sm-inline d-md-none"
              ><img [src]="logo" /> {{ "iot.title.short" | translate }}</span
            >
            <span class="branding spacer d-none d-md-inline"
              ><img routerLink="" style="cursor: pointer;" [src]="logo" />
              {{ "iot.title.long" | translate }}</span
            >
            <span class="d-none d-md-inline">
              <button
                mat-button
                class=" nav-button"
                *ngFor="let item of navigation"
                [routerLink]="[item.link]"
                routerLinkActive="active"
              >
                {{ item.label | translate }}
              </button>
            </span>
            <button mat-icon-button [matMenuTriggerFor]="toolbarUserMenu">
              <fa-icon icon="user-circle"></fa-icon>
            </button>
            <mat-menu #toolbarUserMenu="matMenu">
              <button *ngIf="isAuthenticated" mat-menu-item (click)="logout()">
                <mat-icon>
                  <fa-icon icon="power-off"></fa-icon>
                </mat-icon>
                <span>{{ "iot.menu.logout" | translate }}</span>
              </button>
            </mat-menu>
            <button
              *ngIf="showOnlyInDevMode"
              mat-icon-button
              routerLink="settings"
              class="d-none d-sm-inline"
            >
              <fa-icon icon="cog"></fa-icon>
            </button>
            <span *ngIf="language$ | async as language">
              <mat-select
                [ngModel]="language"
                (selectionChange)="onLanguageSelect($event)"
              >
                <mat-option *ngFor="let l of languages" [value]="l">
                  {{ l.toUpperCase() }}
                </mat-option>
              </mat-select>
            </span>
          </mat-toolbar>
        </div>
        <div class="content" [@routeAnimations]="getAnimationData(outlet)">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <iot-footer></iot-footer>
</div>

<!-- <ng-template #login>
     <button mat-button (click)="onLoginClick()">Login</button>
     </ng-template> -->
<!-- <<ng-template #loading>
     <h1>loading...</h1>
     </ng-template> -->
<!-- <div *ngIf="!isAuthenticated">
     <button mat-flat-button color="primary" (click)="onLoginClick()">login</button>
     </div> -->
<!-- <ng-template #loadingAuth>
     <div [class]="'theme-wrapper ' + (theme$ | async)">
       <mat-spinner ></mat-spinner>
     </div>
     </ng-template> -->
<!-- <ng-template  #noAuth>
     <mat-card class="login-card-bg">
       <div>
         <h1>User Login</h1>
       </div>
       <div class="positionButtom">
         <button mat-flat-button color="accent" class="login-button button2" (click)="onLoginClick()">
           login
         </button>
       </div>
       <br />
     </mat-card>
     </ng-template> -->
