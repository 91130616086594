import { delay } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { DeviceType } from '../../models/device-type';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'iot-show-template-type',
  templateUrl: './show-template-type.component.html',
  styleUrls: ['./show-template-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowTemplateTypeComponent implements OnInit {
  @Input() templateTypeId: string
  public getDeviceTypeById$: Observable<DeviceType>
  constructor(private deviceService: DeviceService) { }
  ngOnInit(): void {
    this.getDeviceTypeById$ = this.deviceService.getDeviceType(this.templateTypeId)
  }

}
