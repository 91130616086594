<mat-card>
    <div class="container">
        <mat-card-header>
            <mat-card-title>Name: {{signal.friendlyName}} </mat-card-title>
            <mat-card-subtitle>SignalType: {{signal.key}}</mat-card-subtitle>
        </mat-card-header>
    </div>
    <mat-card-content>
        <div style="font-size: large;" *ngFor="let item of ArrayOfAttributes">
            <span>key: {{item.key }}</span> <button mat-button disabled></button>
            <span>value: {{item.value }}</span>
        </div>
    </mat-card-content>
    <mat-card-footer>
        created at: {{signal.created | date :'short' }}
    </mat-card-footer>
    <mat-card-actions>

    </mat-card-actions>
</mat-card>