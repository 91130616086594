<div>
    <div class="title">
        <h4 matDialogTitle>
            Edit device
        </h4>
        <button mat-dialog-close mat-button color="warn">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div mat-dialog-content>
        <form [formGroup]="deviceForm" (ngSubmit)="onSubmit()">
            <div class="form-field">
                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="friendlyName">
                    <mat-error> Please fill out this field.</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="deviceTypes$ |async as deviceTypes">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="typeId">
                        <mat-option *ngFor="let item of deviceTypes" [value]="item.id">
                            <img [src]="item.iconURL" alt="Icon" class="image"> {{item.key}}
                        </mat-option>
                    </mat-select>
                    <mat-error> Please select a plant type </mat-error>
                </mat-form-field>

                <!-- <mat-form-field appearance="outline">
                    <mat-label>Parent</mat-label>
                    <input matInput formControlName="parentDeviceId">
                </mat-form-field> -->

                <mat-form-field *ngIf="allDevices$ | async as alldevices" appearance="outline">
                    <mat-label>Parent</mat-label>
                    <mat-select formControlName="parentDeviceId" >
                        <mat-option [value]=null>None</mat-option>
                      <mat-option *ngFor="let item of alldevices" [value]="item.id">
                        {{item.friendlyName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>




            </div>
        </form>

    </div>
    <div mat-dialog-actions>
        <button class="button-close" mat-flat-button color="primary" (click)="onSubmit()">
            <mat-icon>done</mat-icon> Save
        </button>
    </div>
</div>