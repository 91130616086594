import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private matDialog: MatDialog) { }

  openConfirmDialog() {
    const config = new MatDialogConfig()
    config.height = "auto"
    config.width = "30%"
    config.disableClose = true
    config.backdropClass ="backdropBackground"

    return this.matDialog.open(MatConfirmDialogComponent, config)
  }
}
