import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedTenantModule } from '../shared-tenant/shared-tenant.module';
import { SharedDeviceModule } from '../shared-device/shared-device.module';
import { SharedPlantModule } from '../shared-plant/shared-plant.module';
import { SharedModule } from '../../../shared/shared.module';
import { PlantManagerComponent } from './plant-manager/plant-manager.component';
import { DeviceManagerComponent } from './device-manager/device-manager.component';

@NgModule({
  declarations: [PlantManagerComponent, DeviceManagerComponent],
  imports: [
    CommonModule,
    SharedModule,

    SharedPlantModule,
    SharedTenantModule,
    SharedDeviceModule
  ],
  exports: [PlantManagerComponent, DeviceManagerComponent]
})
export class PlantModule {}
