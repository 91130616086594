import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";

import { HomeComponent } from "./app/home/home.component";

const routes: Routes = [
  // { path: '', redirectTo: '/dashboard', pathMatch: 'full'},

  {
    path: "plantmanager",
    loadChildren: () =>
      import("./app/plantManager/plant-manager.module").then(
        m => m.PlantManagerModule
      ),
    data: { title: "iot.menu.plantManager" },
    canActivate: [MsalGuard]
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./app/dashboard/dashboard.module").then(m => m.DashboardModule),
    data: { title: "iot.menu.dashboard" },
    canActivate: [MsalGuard]
  },
  {
    path: "iam",
    loadChildren: () => import("./app/IAM/iam.module").then(m => m.IAMModule),
    data: { title: "iot.menu..title.iam" },
    canActivate: [MsalGuard]
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./features/settings/settings.module").then(m => m.SettingsModule),
    canLoad: [MsalGuard]
  },
  {
    // Needed for hash routing
    path: "error",
    component: HomeComponent
  },
  {
    // Needed for hash routing
    path: "state",
    component: HomeComponent
  },
  {
    // Needed for hash routing
    path: "code",
    component: HomeComponent
  },
  { path: "", redirectTo: "/dashboard", pathMatch: "full" }
  // {
  //   path: 'plant',
  //   loadChildren: () =>
  //     import('./app/plant/plant.module').then(m => m.PlantModule),

  // },
  // {
  //   path: 'device',
  //   loadChildren: () =>
  //     import('./app/device/device.module').then(m => m.DeviceModule),
  // },
  // {
  //   path: 'admin',
  //   loadChildren: () =>
  //     import('./app/admin/admin.module').then(m => m.AdminModule),
  // },

  // {
  //   path: 'examples',
  //   loadChildren: () =>
  //     import('./features/examples/examples.module').then(m => m.ExamplesModule),
  // },
];

const isIframe = window !== window.parent && !window.opener;
@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes, {
      // initialNavigation: !isIframe ? "enabled" : "disabled",
      useHash: false,
      scrollPositionRestoration: "enabled",
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
