import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceFE } from '../../models/device-fe';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'anms-device-template-configuration-device',
  templateUrl: './device-template-configuration-device.component.html',
  styleUrls: ['./device-template-configuration-device.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceTemplateConfigurationDeviceComponent implements OnInit {
  public parentdevice: DeviceFE;
  public devices: DeviceFE[];
  public alldevices: DeviceFE[] = new Array<DeviceFE>();
  constructor(
    private deviceService: DeviceService,
    public dialogRef: MatDialogRef<DeviceTemplateConfigurationDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.devices = data.devices;
    this.devices.forEach(bb => this.adddevices(this.alldevices, bb));
    var a = {
      id: '',
      version: 0,
      friendlyName: 'main',
      typeId: '',
      created: '',
      tenantId: '',
      plantId: '',
      isRootDevice: true,
      hasChildDevices: false,
      parentDeviceId: null,
      childDevices: Array<DeviceFE>(),
      flags: new Map<string, Boolean>(),
      attributes: new Map<string, string>()
    }; //{friendlyName : 'main', id: '',type: '', childDevices:[], attributes: new Map<string, string>(), parameters: new Map<string, string>(), signals: [] };
    this.alldevices.push(a);
  }

  ngOnInit(): void {}

  selectionChanged(a: DeviceFE) {
    this.parentdevice = a;
  }

  clickEvent() {
    if (this.parentdevice.friendlyName == 'main') {
      this.dialogRef.close({
        newdevice: {
          id: '',
          version: 0,
          friendlyName: this.data.template.name,
          typeId: this.data.template.typeId,
          created: new Date().toString(),
          tenantId: '',
          plantId: '',
          isRootDevice: true,
          hasChildDevices: false,
          parentDeviceId: null,
          childDevices: Array<DeviceFE>(),
          flags: new Map<string, Boolean>(),
          attributes: this.data.template.attributes,
          signals: this.data.template.signals,
          parameters: this.data.template.parameters
        }
      });
    } else {
      this.dialogRef.close({
        newdevice: {
          id: '',
          version: 0,
          friendlyName: this.data.template.name,
          typeId: this.data.template.typeId,
          created: '',
          tenantId: '',
          plantId: '',
          isRootDevice: false,
          hasChildDevices: false,
          parentDeviceId: this.parentdevice.id,
          childDevices: Array<DeviceFE>(),
          flags: new Map<string, Boolean>(),
          attributes: this.data.template.attributes,
          signals: this.data.template.signals,
          parameters: this.data.template.parameters
        }
      });
    }
  }

  private adddevices(a: DeviceFE[], b: DeviceFE) {
    a.push(b);
    b.childDevices.forEach(c => this.adddevices(a, c));
  }
}
