<div class="titleContainer">
    <h1 mat-dialog-title>Plant Type Manager</h1>
    <div>
        <button (click)="addPlantType()" mat-button color="primary" align="end">
            <mat-icon>add</mat-icon>
        </button>
        <button class="button" mat-button color="warn" [mat-dialog-close]="" cdkFocusInitial>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="plantTypes$ | async as plantTypes; else loading" mat-dialog-content>
    <mat-card *ngFor="let item of plantTypes" class=" m-1 Card-container border">
        <mat-card-header>
            <img mat-card-sm-image [src]="item.iconURI">
            <mat-card-title>{{item.key}}</mat-card-title>
            <mat-card-subtitle>{{item.created | date: "short"}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
            <button mat-stroked-button color="accent" (click)="editTypePlant(item.id)">
                <mat-icon>mode</mat-icon>
            </button>
        </mat-card-actions>
    </mat-card>
</div>
<ng-template #loading>
    <div class="mat-Spinner">
        <mat-progress-spinner color="primary" class="spinner-position" mode="indeterminate"></mat-progress-spinner>
    </div>
</ng-template>