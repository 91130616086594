<form>
    <div style="display:flex; flex-direction:  row;">
        <div style="width: 50%;">
            <mat-form-field style="margin-left: 0px;">
                <mat-label>Email</mat-label>
                <input type="email" matInput [formControl]="userEmailFormControl" [errorStateMatcher]="matcher" placeholder="exa.@example.com">
                <mat-error *ngIf="userEmailFormControl.hasError('email') && !userEmailFormControl.hasError('required')">
                    Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="userEmailFormControl.hasError('required')">
                    Email is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div style="margin-left: 10px;">
            <button [disabled]="!userEmailFormControl.valid" mat-stroked-button (click)="searchUser()"> <mat-icon>search </mat-icon>search</button>
        </div>
    </div>
</form>

<mat-card *ngIf="searchResults$ | async as userResult else">
    <mat-card-header>
        <mat-card-title>Name: {{userResult.givenName}} {{userResult.surname}} </mat-card-title>
        <mat-card-subtitle>Emails: {{userResult.eMails}}</mat-card-subtitle>
        <mat-card-subtitle *ngFor="let role of userResult.roles">
            <span>Roles: {{role.roleKey}}</span>
            <br>
        </mat-card-subtitle>
        <mat-card-subtitle>UserId: {{userResult.id}}</mat-card-subtitle>
        <!-- <mat-card-subtitle>ResourceId: {{userResult.resourceId}}</mat-card-subtitle> -->
    </mat-card-header>
</mat-card>
<mat-card *ngIf="showNotfinde">
    <mat-card-header>

        <mat-card-title style="margin-bottom: 10px;"> User not found
            <mat-icon style="margin-left: 5px;">sentiment_very_dissatisfied</mat-icon>
        </mat-card-title>
        <mat-card-subtitle> Please try again</mat-card-subtitle>

    </mat-card-header>
</mat-card>
<div *ngIf="showSpinner && !showNotfinde ">
    <mat-spinner></mat-spinner>
</div>