import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HyperMediaResponse } from '../../models/hyperMediaResponse';
import { PlantType } from '../models/plant-type';
@Injectable({
  providedIn: 'root'
})
export class PlantTypeService {
  constructor(private http: HttpClient) { }

  getPlantType(
    search?: string,
    start?: number,
    count?: number
  ): Observable<PlantType[]> {
    let searchParams: { [key: string]: string } = {
      //search: '',
      start: (start || 0).toString(),
      count: (count || 100).toString()
    };
    return this.http
      .get<HyperMediaResponse<PlantType>>(
        environment.apiUrl + '/api/PlantTypes',
        {
          params: searchParams
        }
      )
      .pipe(map((PlantType) => PlantType.resources));
  }

  getPlantTypeById(plantTypeId: string): Observable<PlantType> {
    return this.http.get<PlantType>(
      environment.apiUrl + '/api/PlantTypes/' + plantTypeId
    );
  }
  createPlantType(data: PlantType): Observable<PlantType> {
    return this.http.post<PlantType>(environment.endpoints.plantTypes, data)
  }
  updatePlantType(PlantTypeId, PlantTypaData): Observable<PlantType> {
    return this.http.patch(environment.endpoints.plantTypes + '/' + PlantTypeId, PlantTypaData)
  }
}
